<!-- 修改密码页面-->
<template>
	<div class="cmd5-changePwd cmd5-login changePwd-page">
		<!--登录页内容区-->
		<div class="changePwd-content login-content">
			<div class="desc-content">
				<div class="logo-img">
					<img
						src="../assets/img/logo.png"
						alt
						style="width: 322px; height: 57px"
					/>
				</div>
			</div>
			<div class="back-box">
				<el-link
					@click="$router.push('/')"
					class="right-backindex"
					:underline="false"
					><img src="../assets/img/login-icon/jiantou.png" alt="" />
					{{ $t('lang.changePsw.gohomg') }}</el-link
				>
				<div class="login-tiptext">
					<div class="left-welcome">{{ $t('lang.changePsw.changePsw') }}</div>
				</div>
				<!--表单区域-->
				<div class="login-form">
					<!--密码-->
					<div
						class="form-item"
						:class="errTipObj.password.status ? 'err-input-border' : ''"
					>
						<div class="left-icon">
							<img
								src="../assets/img/login-icon/mima1.png"
								:alt="$t('lang.login.email')"
							/>
							<span>{{ $t('lang.changePsw.password') }}</span>
						</div>
						<div class="right-input">
							<el-input
								type="password"
								:placeholder="$t('lang.register.pwdPlaceholder')"
								show-password
								v-model.trim="postObj.password"
								@keyup.enter="GoLogin"
								@focus="
									() => {
										errTipObj.password.status = false;
									}
								"
							></el-input>
						</div>
						<div class="err-tip" v-show="errTipObj.password.status">
							{{ errTipObj.password.errTiptext }}
						</div>
					</div>
					<!--确认密码-->
					<div
						class="form-item"
						:class="errTipObj.againPassword.status ? 'err-input-border' : ''"
					>
						<div class="left-icon">
							<img
								src="../assets/img/login-icon/mima1.png"
								:alt="$t('lang.login.email')"
							/>
							<span>{{ $t('lang.changePsw.confirmPassword') }}</span>
						</div>
						<div class="right-input">
							<el-input
								type="password"
								:placeholder="$t('lang.register.pwdPlaceholder')"
								show-password
								v-model.trim="postObj.againPassword"
								@keyup.enter="GoLogin"
								@focus="
									() => {
										errTipObj.againPassword.status = false;
									}
								"
							></el-input>
						</div>
						<div class="err-tip" v-show="errTipObj.againPassword.status">
							{{ errTipObj.againPassword.errTiptext }}
						</div>
					</div>
					<!--验证码-->
					<div class="verifycode-item">
						<div class="code-input">
							<div class="left-icon">
								<img
									src="../assets/img/login-icon/yanzhengma.png"
									:alt="$t('lang.forgotPwd.captcha')"
								/>
								<span>{{ $t('lang.register.captcha') }}</span>
							</div>
							<div class="right-input">
								<el-input
									:placeholder="$t('lang.register.captcha')"
									v-model.trim="postObj.verifyCode"
								></el-input>
							</div>
						</div>
						<div class="code-img">
							<img
								ref="captcha"
								:src="src"
								alt="验证码"
								@click="srcRandom = Math.random()"
							/>
						</div>
					</div>
					<!--确认修改密码按钮-->
					<div class="login-btn">
						<el-button
							type="primary"
							class="cmd5-loginbtn"
							@click="confirmEdit"
							:loading="confirmEditLoading"
							>{{ $t('lang.changePsw.confirmEdit') }}</el-button
						>
					</div>
				</div>
			</div>
		</div>
		<!-- 页脚组件 -->
		<Footer />
	</div>
</template>

<script>
import Footer from '../components/Footer';
import { passwdModify } from '@/api/pwd';
export default {
	name: 'changePwd',
	components: { Footer },
	computed: {
		src() {
			return `/api/v1/captcha?type=passwdModify&'${this.srcRandom}`;
		},
	},
	data() {
		return {
			srcRandom: Math.random(),
			confirmEditLoading: false,
			postObj: {
				password: '',
				againPassword: '',
				verifyCode: '', //验证码
			},
			// 错误状态的提示
			errTipObj: {
				password: {
					status: false,
					errTiptext: this.$t('lang.forgotPwd.errPwd'),
				},
				againPassword: {
					status: false,
					errTiptext: this.$t('lang.forgotPwd.msg6'),
				},
			},
		};
	},
	methods: {
		//点击确认修改密码
		confirmEdit() {
			if (this.verifyPostData()) {
				/*this.postObj = {
         password: '',
         verifyCode: '', //验证码
       },*/
				this.confirmEditLoading = true;
				let postParams = {
					password: this.postObj.password,
					verifyCode: this.postObj.verifyCode,
				};

				// 请求数据
				passwdModify(postParams)
					.then((res) => {
						// console.log('修改密码成功', res);
						if (res && res.code == 200) {
							this.$message({
								message: this.$t('lang.changePsw.msg'),
								type: 'success',
								duration: 2000,
								onClose: () => {
									// 跳转到登录页
									this.clearDataGoLogin();
								},
							});
						}
						this.confirmEditLoading = false;
					})
					.catch(() => {
						this.confirmEditLoading = false;
					})
					.finally(() => {
						this.$refs.captcha.click(); //更换验证码
					});
			}
		},
		// 清除 登录数据，去登录。
		clearDataGoLogin() {
			//弹框提示, 清空数据
			this.$store.commit('clearToken');
			this.$store.commit('clearUserInfo');

			// 跳转到登录页
			this.$router.push({
				path: `/login`,
			});
		},
		// 验证提交 修改密码
		verifyPostData() {
			// 密码
			if (!this.postObj.password) {
				this.errTipObj.password.status = true;
				return false;
			}
			// 重复密码
			if (!this.postObj.againPassword) {
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t(
					'lang.forgotPwd.msg5'
				);
				return false;
			}
			// 密码和重复密码不一样
			if (this.postObj.password !== this.postObj.againPassword) {
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t(
					'lang.forgotPwd.msg6'
				);
				return false;
			}

			// 验证码
			if (!this.postObj.verifyCode) {
				this.$message({
					message: this.$t('lang.forgotPwd.captchaPlaceholder'),
					type: 'error',
				});
				return false;
			}

			this.errTipObj.againPassword.status = false;
			this.errTipObj.password.status = false;
			return true;
		},
	},
	mounted() {},
};
</script>

<style lang="scss">
.cmd5-changePwd {
	background-image: url(../assets/img/cmd-index-bg.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-attachment: fixed;
	position: absolute;
	height: 100%;
	width: 100%;
	.err-tip {
		font-size: 12px;
		font-weight: 400;
		color: #ff0000;
		position: absolute;
		left: 0;
		top: 55px;
		transform: translateY(-50%);
		width: 100%;
		line-height: 1.65;
	}
	.err-input-border {
		border: 1px solid #ff0000;
	}
	// 是否同意协议
	.isTerms-service {
		width: 360px;
		margin: 0 auto 40px;
		display: flex;
		align-items: center;
		.el-checkbox__label {
			font-size: 12px;
		}
		.protocol-link {
			font-size: 12px;
			color: #44a9fe;
		}
	}
	.back-box {
		background: #ffffff;
		padding-bottom: 44px;
		border-radius: 6px;
	}
	.changePwd-content {
		width: 450px;
		margin: 0 auto;
		padding-top: 128px;
		min-height: calc(100vh - 105px);
		.logo-img {
			text-align: center;
			margin-bottom: 47px;
			a {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.title {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.login-tiptext {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 0;
			margin-bottom: 40px;
			border-bottom: 2px solid #eee;
			height: 45px;
			.left-welcome {
				font-size: 24px;
				font-weight: bold;
				color: #333333;
				width: 100%;
				text-align: center;
				margin-bottom: 12px;
			}
		}
		.right-backindex {
			color: #44a9fe;
			position: relative;
			top: 24px;
			left: 331px;
			img {
				width: 18px;
				height: 12px;
			}
		}

		.login-form {
			margin-top: 50px;
			.form-item {
				width: 360px;
				height: 46px;
				background: #ffffff;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: 6px;
				margin: 0 auto 25px;
				position: relative;
				display: flex;
				// justify-content: space-between;
				align-items: center;
				.left-icon {
					width: 144px;
					height: 100%;
					display: flex;
					// justify-content: space-between;
					align-items: center;
					padding: 0px 0 0 16px;

					span {
						font-size: 14px;
						font-weight: bold;
						color: #333333;
						line-height: 46px;
						display: inline-block;
						margin-left: 10px;
					}
					img {
						width: 18px;
						height: auto;
						object-fit: cover;
					}
				}
				.right-input {
					width: calc(100% - 84px);
					input {
						border: 0;
						outline: 0;
					}
					input:focus {
						box-shadow: none;
					}
				}
			}
		}
		.verifycode-item {
			margin: 0 auto 25px;
			width: 360px;
			height: 46px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			.code-input {
				width: 238px;
				height: 46px;
				background: #ffffff;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: 6px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.left-icon {
					width: 144px;
					height: 100%;
					display: flex;
					// justify-content: space-between;
					align-items: center;
					padding: 0px 0 0 16px;

					span {
						font-size: 14px;
						font-weight: bold;
						color: #333333;
						line-height: 46px;
						display: inline-block;
						margin-left: 10px;
					}
					img {
						width: 18px;
						height: auto;
						object-fit: cover;
					}
				}
				input {
					border: 0;
					outline: 0;
					padding: 0.375rem 0.75rem 0.375rem 0;
				}
				input:focus {
					box-shadow: none;
				}
			}
			.code-img {
				width: 113px;
				height: 46px;
				background: #ffffff;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: 6px;
				overflow: hidden;
				padding: 5px;
				img {
					width: 103px;
					height: 35px;
					cursor: pointer;
				}
			}
		}
		.login-btn {
			width: 360px;
			height: 46px;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			margin: auto;
			.cmd5-loginbtn {
				height: 100%;
				font-size: 18px;
				width: 100%;
				border-radius: 6px;
			}
		}
		.tip-register {
			margin: 20px auto 20px;
			width: 360px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.register-link {
				font-size: 14px;
				font-weight: 400;
				color: #333333;
			}
			.register-link:hover {
				text-decoration: none;
				color: #44a9fe;
			}
			.checkbox {
				-moz-user-select: none; /* Firefox私有属性 */
				-webkit-user-select: none; /* WebKit内核私有属性 */
				-ms-user-select: none; /* IE私有属性(IE10及以后) */
				-khtml-user-select: none; /* KHTML内核私有属性 */
				-o-user-select: none; /* Opera私有属性 */
				user-select: none; /* CSS3属性 */
				cursor: pointer;
			}
		}
	}
}
</style>
