<template>
  <div id="md5-app">
    <!-- <md5Header /> -->
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <wx-login-dialog />
    <login-register-forgotpwd-dialog />
    <!-- <md5Footer /> -->
  </div>
</template>

<script>
// import md5Header from "./components/Header";
// import md5Footer from "./components/Footer";
import wxLoginDialog from "@/components/wechatLoginDialog"; //微信登录扫二维码 弹出框
import loginRegisterForgotpwdDialog from "@/components/login-register-forgotpwd-dialog"; //登录 注册 找回密码 模块的弹出框
import { mapState } from 'vuex';
export default {
  name: 'App',
  components: {
    wxLoginDialog,
    loginRegisterForgotpwdDialog,
  },
  computed:{
    ...mapState(['$lang']),
  },
  created(){    
    localStorage.getItem('language');
    console.log("app.vue==>localStorage.getItem('language')",localStorage.getItem('language'));
    this.$i18n.locale = this.$lang;
  },
  
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-avtive {
  //transition: all 0.5s;
  animation-name: fadeInUp;
  animation-duration: 0.5s; 
  animation-fill-mode: both;
  animation-timing-function: ease-in;
}
.fade-enter,
.fade-leave-to {
  //opacity: 0;
}

@keyframes fadeInUp {
  0% {  
    opacity: 0;
    transform: translate3d(0, 3px, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
</style>
