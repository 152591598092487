<!--登录 注册 找回密码的弹出框操作-->
<template>
	<div class="login-register-forgotpwd-dialog">
		<el-dialog
			:visible.sync="$store.state.$dialogVisible"
			:close-on-click-modal="false"
			@close="closeloginDialog"
			destroy-on-close
		>
			<!-- 标题 -->

			<span slot="title" class="action-title">{{ $formTitle }}</span>
			<!-- 登录 注册 找回密码 的切换 -->

			<div class="action-form-content">
				<transition name="action" mode="out-in">
					<login-form-item v-if="$componentType == 'login'" key="login" />
					<register-form-item
						v-else-if="$componentType == 'register'"
						key="register"
					/>
					<forgotpwd-form-item
						v-else-if="$componentType == 'forgotpwd'"
						key="forgotpwd"
					/>
					<div key="weixin" v-else></div>
				</transition>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import loginFormItem from './login-item';
import registerFormItem from './register-item';
import forgotpwdFormItem from './forgotpwd-item';
import { mapState } from 'vuex';
export default {
	name: 'Login',
	components: {
		loginFormItem,
		registerFormItem,
		forgotpwdFormItem,
	},
	data() {
		return {
			dialogVisible: false,
			componentType: 'login',
			title: '',
		};
	},
	methods: {
		closeloginDialog() {
			this.$store.commit('setDialogVisible', false);
			this.$store.commit('setComponentType', 'login');
		},
	},
	created() {},
	computed: {
		...mapState(['$formTitle', '$componentType', 'wxDialog']),
	},
};
</script>

<style lang="scss" scoped>
.login-register-forgotpwd-dialog {
	/deep/ .el-dialog {
		max-width: 460px;
		overflow: hidden;
		max-height: 591px;
		/* max-height: 530px; */
		border-radius: 4px;
		@media screen and (max-width: 768px) {
			width: 96%;
		}
	}
	/deep/ .el-dialog__header {
		border-bottom: 1px solid #ddd;
		padding: 20px;
		text-align: center;
		.action-title {
			font-size: 20px;
			line-height: 18px;
			font-weight: bold;
			color: #6a6e7b;
		}
		.el-dialog__close {
			background: #f8f8f8;
			border-radius: 100%;
			font-size: 12px;
			padding: 6px;
		}
	}
	//动画过渡切换 淡入淡出
	.action-enter-active,
	.action-leave-active {
		animation-duration: 0.3s;
		animation-fill-mode: both;
	}
	.action-enter-active {
		animation-name: fadeIn;
	}
	.action-leave-active {
		animation-name: fadeOut;
	}
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}
}
</style>
