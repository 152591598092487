import request from '@/utils/request'

export function getrecord(query) {
  //获取积分--- 获取记录，使用记录
  return request({
    url: '/point_record',
    method: 'get',
    params: query
  })
}
export function mycenter(query) {
  return request({
    url: '/trans/user',
    method: 'post',
    data: query
  })
}

//充值积分
export function md5Pay(query) {
  return request({
    url: '/trans/pay',
    method: 'post',
    data: query
  })
}


//充值数据模版
export function rechargeTemplateData(query) {
  return request({
    url: '/pay_package',
    method: 'get',
    data: query
  })
}


// 轮训查询订单是否完成
export function md5PayOrderquery(query) {
  return request({
    url: '/trans/orderquery',
    method: 'post',
    data: query
  })
}



