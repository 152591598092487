<template>
	<div class="md5-batch">
		<!-- 页头导航 -->
		<md5-header />
		<div class="md5-contain batch-page">
			<div class="search-form">
				<!-- 左侧 -->
				<div class="left-content">
					<div class="batch-selectType">
						<div class="tip-text">
							{{ $t('lang.decryption.selectEncryptionType') }}
						</div>
						<div class="line"></div>
						<el-input
							v-model.trim="currentTypeVal"
							:placeholder="$t('lang.decryption.currentTypeVal')"
							class="select-type-input"
							:readonly="true"
							@focus.stop="isShowSelctTypePopover = true"
						>
							<i
								class="iconfont icon-xiala"
								style="
									font-size: 30px;
									margin-top: 10px;
									margin-right: 10px;
									line-height: 46px;
									cursor: pointer;
								"
								slot="suffix"
								@click="isShowSelctTypePopover = true"
							></i>
						</el-input>
						<!-- 类型的选中Popover  -->
						<div
							class="my-popover"
							v-show="isShowSelctTypePopover"
							ref="SelctTypePopover"
						>
							<el-scrollbar style="height: 100%">
								<ul class="el-select-dropdown__list">
									<li
										v-for="(item, index) in selectOptions"
										:class="[
											'el-select-dropdown__item',
											index === selctTypeIndex || item.value == currentTypeVal
												? 'selected'
												: '',
										]"
										:key="index"
										@click="selctTypeFunc(index, item.value, item.text)"
									>
										<span>{{ item.text }}</span>
									</li>
								</ul>
							</el-scrollbar>
						</div>
					</div>
				</div>
				<!-- 提交 右侧-->
				<div class="right-content">
					<div class="codeimg-input-box">
						<!-- 验证码 -->
						<div class="code-img">
							<img
								:src="src"
								:alt="$t('lang.decryption.captcha')"
								ref="captcha"
								@click="srcRandom = Math.random()"
							/>
						</div>
						<el-input
							:placeholder="$t('lang.decryption.captchaPlaceholder')"
							v-model.trim="verifyCode"
							class="input-verifCode"
						></el-input>
					</div>
					<el-button
						type="primary"
						icon="el-icon-search"
						class="mybtn-search"
						@click="bacthquery"
						:loading="BatchResultLoading"
						>{{ $t('lang.decryption.submitDecryption') }}</el-button
					>
				</div>
			</div>
			<!-- 提示语 -->
			<div class="tiptext-box">
				<p class="red">{{ $t('lang.decryption.tiptextBox') }}</p>
			</div>
			<!-- 原文和批量解密 -->
			<div class="result-batch-box">
				<!-- 左侧原文 -->
				<div class="left-sourcetext">
					<div class="head-tip">
						<p>{{ $t('lang.decryption.sourcetextHead') }}</p>
					</div>
					<codemirror v-model="enCode" :options="enOptions" />
				</div>
				<!-- 右侧解密结果 -->
				<div class="right-result">
					<div class="head-tip">
						<p>{{ $t('lang.decryption.decryptionResult') }}</p>
						<div
							class="copy-icon"
							@click="copyReslutTable"
							v-show="tableData.length > 0"
						>
							<i class="iconfont icon-fuzhi"></i>
							{{ $t('lang.decryption.copy') }}
						</div>
					</div>
					<div class="reslut-data-list" v-loading="BatchResultLoading">
						<!-- <el-scrollbar style="height:100%"> -->
						<!-- <table class="reslut-tabledata" v-loading="false" v-show="tableData.length">
                <tbody>
                  <tr v-for="(item,index) in tableData" :key="index">
                    <td class="index">{{index+1}}</td>
                    <td class="code">{{item.key}}</td>
                    <td>-></td>
                    <td :class="['status', item.value == '解密失败' ? 'red' :'' ]">{{item.value}}</td>
                  </tr>
                </tbody>
            </table>-->

						<el-table
							class="reslut-tabledata"
							:show-header="false"
							stripe
							:data="tableData"
							style="width: 100%"
							height="553px"
							ref="reslutTable"
						>
							<template slot="empty">
								<span></span>
							</template>

							<el-table-column width="80px" align="center">
								<template slot-scope="scope">
									<span>{{ scope.$index + 1 }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="key" width="380px"></el-table-column>
							<el-table-column prop="name" width="80px" align="center">
								<template>
									<span class="center">-></span>
								</template>
							</el-table-column>
							<el-table-column prop="value">
								<template slot-scope="scope">
									<span
										:class="
											scope.row.value == `{{$t('lang.decryption.failure')}} `
												? 'red'
												: ''
										"
										>{{ scope.row.value }}</span
									>
								</template>
							</el-table-column>
						</el-table>
						<!-- 无数据时候提示 -->
						<div class="no-batch-data" v-show="tableData.length == 0">
							<img
								src="@/assets/img/no-data.png"
								:alt="$t('lang.decryption.noData')"
							/>
							<p>{{ $t('lang.myCenter.noData') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 页脚 -->
		<md5-footer />
	</div>
</template>

<script>
import md5Header from './../../components/Header';
import md5Footer from './../../components/Footer';
import { massQuery, md5DecryptionResult } from '@/api/md5';

// require component
import { codemirror } from 'vue-codemirror';
// require styles
import 'codemirror/lib/codemirror.css';

export default {
	name: 'batch',
	components: {
		md5Header,
		md5Footer,
		codemirror,
	},
	computed: {
		src() {
			return `/api/v1/captcha?type=decryption&'${this.srcRandom}`;
		},
	},
	data() {
		return {
			srcRandom: Math.random(),
			isShowSelctTypePopover: false,
			selctTypeIndex: 0,
			enCode: '',
			user: {},
			enOptions: {
				// 主题
				theme: 'idea',
				// 显示函数
				line: true,
				lineNumbers: true,
				// 软换行
				lineWrapping: true,
				// tab宽度
				tabSize: 4,
			},
			ciphertext: '',
			currentTypeVal: this.$t('lang.automatic'),
			verifyCode: '', //验证码
			selectOptions: [
				//类型
				{
					text: this.$t('lang.automatic'),
					value: this.$t('lang.automatic'),
				},
				{
					text: 'md5_16',
					value: '10',
				},
				{
					text: 'md5_32',
					value: '20',
				},
				{
					text: 'md5(md5($pass))',
					value: '30',
				},
				{
					text: 'md5(md5(md5($pass))) ',
					value: '40',
				},
				{
					text: 'md5(unicode) ',
					value: '50',
				},
				{
					text: 'md5(base64) ',
					value: '60',
				},
				{
					text: 'mysql',
					value: '70',
				},
				{
					text: 'mysql5',
					value: '80',
				},
				{
					text: 'ntlm',
					value: '90',
				},
				{
					text: 'sha1',
					value: '100',
				},
				{
					text: 'sha1(sha1($psss))',
					value: '110',
				},
				{
					text: 'sha1(md5($psss))',
					value: '120',
				},
				{
					text: 'md5(sha1($psss))',
					value: '130',
				},
				{
					text: 'sha256',
					value: '140',
				},
				{
					text: 'sha256(md5($pass))',
					value: '150',
				},
				{
					text: 'sha384',
					value: '160',
				},
				{
					text: 'sha512',
					value: '170',
				},
			],
			tableData: [],
			//定时器
			IntervalFunc: null,
			getBatchSuccess: false, //批量获取的状态
			BatchbatchId: '', //批量查询保存的id
			BatchResultLoading: false, //获取批量结果的loading
		};
	},
	methods: {
		// 复制表格结果
		copyReslutTable() {
			//  this.copyData()
			if (this.tableData.length > 0) {
				let copyStr = '';
				this.tableData.forEach((item, index) => {
					copyStr += `${index + 1}  ${item.key}  ->  ${item.value} \r\n`;
				});
				console.log(copyStr);
				this.copyData(copyStr);
			} else {
				this.$message.error('无数据，无法正常复制数据!');
			}
		},
		// 选中加密类型
		selctTypeFunc(_index, _value, _text) {
			this.selctTypeIndex = _index;
			this.currentTypeVal = _text;
			this.isShowSelctTypePopover = false;
		},
		decryptionResult() {
			this.BatchResultLoading = true;
			clearInterval(this.IntervalFunc); //清除计时器
			this.IntervalFunc = setInterval(() => {
				md5DecryptionResult({
					batchId: this.BatchbatchId,
				})
					.then((res) => {
						if (res.status) {
							clearInterval(this.IntervalFunc); //清除计时器
							this.tableData = res.list;
							this.BatchResultLoading = false;
						}
					})
					.catch(() => {
						this.BatchResultLoading = false;
					});
			}, 2000);
		},
		bacthquery() {
			if (!this.user) {
				this.$router.push({
					path: '/login',
				});
			}

			if (!this.verifyCode) {
				this.$message.error({
					title: this.$t('lang.decryption.msgTitle'),
					message: this.$t('lang.decryption.verifyCodeMsg'),
				});
				return false;
			}

			if (!this.enCode) {
				this.$message.error({
					title: this.$t('lang.decryption.msgTitle'),
					message: this.$t('lang.decryption.enCodeMsg'),
				});
				return false;
			}

			// 批量查询
			massQuery({
				data: this.enCode,
				captcha: this.verifyCode,
			})
				.then((res) => {
					if (res && res.code != 400) {
						this.BatchbatchId = res.batchId;
						this.decryptionResult(); //开始批量查询 轮询启动
					}
				})
				.finally(() => {
					this.$refs.captcha.click(); //更换验证码
				});
		},
	},
	created() {},
	mounted() {
		//点击其他区域关闭下拉菜单
		document.addEventListener('click', (e) => {
			let batch_selectType_el = document.querySelector('.batch-selectType');
			if (!batch_selectType_el) {
				//判断是否存在 .batch-selectType 元素
				return false;
			}

			if (!batch_selectType_el.contains(e.target)) {
				this.isShowSelctTypePopover = false;
			}
		});
	},
	// 页面销毁
	destroyed() {
		clearInterval(this.IntervalFunc); //清除计时器
		this.IntervalFunc = '';

		//解绑document 点击事件
		document.removeEventListener('click', (e) => {
			if (!document.querySelector('.batch-selectType').contains(e.target)) {
				this.isShowSelctTypePopover = false;
			}
		});
	},
};
</script>

<style lang="scss">
.md5-batch {
	position: absolute;
	height: 100%;
	width: 100%;
	background: url(../../assets/img/cmd-index-bg.png) no-repeat center/cover;

	.batch-page {
		padding-top: 60px;
		min-height: calc(100vh - 157px);

		// 搜索表单
		.search-form {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;
			.left-content {
				width: 818px;
				margin-right: 10px;
				margin-bottom: 10px;
				box-shadow: 0px 0px 8px 0px rgba(102, 183, 251, 0.15);
			}

			.select-type {
				width: 137px;
			}

			// 新添加的类型
			.batch-selectType {
				background-color: #fff;
				border-radius: 6px;
				height: 56px;
				width: 100%;
				user-select: none;
				display: flex;
				align-items: center;
				position: relative;

				.tip-text {
					font-size: 14px;
					font-weight: 400;
					color: #333333;
					padding: 0 20px;
					line-height: 56px;
				}

				.line {
					width: 2px;
					height: 20px;
					background: #cccccc;
				}

				.select-type-input {
					flex: 1;

					input {
						height: 56px;
						line-height: 56px;
						border: 0;
						cursor: pointer;
					}
				}

				.my-popover {
					width: 100%;
					height: 475px;
					background: #ffffff;
					border: 1px solid #44a9fe;
					box-shadow: 0px 3px 13px 1px rgba(4, 0, 0, 0.2);
					border-radius: 6px;
					position: absolute;
					top: 55px;
					left: 0;
					right: 0;
					z-index: 2000;

					.el-scrollbar__wrap {
						overflow-x: hidden;
					}

					.el-select-dropdown__item.selected {
						color: #409eff;
						font-weight: 700;
					}
				}
			}

			.codeimg-input-box {
				width: 233px;
				height: 56px;
				display: flex;
				align-items: center;
				margin-right: 10px;
				.code-img {
					width: 138px;
					height: 56px;
					border-radius: 6px 0 0 6px;
					overflow: hidden;
					background: #fff;
					img {
						width: 80px;
						height: 34px;
						cursor: pointer;
						margin-top: 12px;
						padding-left: 11px;
					}
				}

				.input-verifCode {
					width: 270px;
					display: flex;
					&:before {
						content: '';
						border-left: 1px solid #cccccc;
						height: 25px;
						margin-top: 10px;
					}

					.el-input-group__prepend {
						background-color: #fff;
						padding: 0;
						border: 0;
					}

					input {
						height: 56px;
						border-radius: 6px;
						border-radius: 0 6px 6px 0;
						border: 0;
					}
				}
			}

			.right-content {
				width: 368px;
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				height: 56px;
				box-shadow: 0px 0px 8px 0px rgba(102, 183, 251, 0.15);
			}
			.input-verifCode:before {
				display: none;
			}

			.mybtn-search {
				background-color: #44a9fe !important;
				border-radius: 6px !important;
				color: #ffffff !important;
				border-color: #44a9fe !important;
				height: 100%;
				width: 137px;
				.el-icon-search {
					font-size: 18px;
				}
				&:focus,
				&:hover {
					background: #66b1ff !important;
					border-color: #66b1ff !important;
					color: #fff !important;
				}
			}
		}

		// 提示语
		.tiptext-box {
			display: none;
			padding: 15px 20px;
			background: #ffffff;
			border-radius: 6px;
			margin-bottom: 10px;

			p {
				font-size: 12px;
				font-weight: 400;
				color: #ff0000;
				line-height: 1.5;
			}
		}

		// 原文和批量解密
		.result-batch-box {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.head-tip {
				// height: 49px;
				padding: 14px 20px;
				border-radius: 6px 6px 0 0;
				background: #ffffff;
				border-bottom: 1px solid rgba(242, 242, 242, 1);
				position: relative;
				p {
					font-size: 14px;
					font-weight: 400;
					color: #666666;
				}
			}
			.CodeMirror-gutters {
				border: none;
				width: 49px;
				background: rgba(245, 247, 250, 1);
			}
			.CodeMirror-linenumber {
				color: #000;
				font-family: '';
			}
			.CodeMirror-line {
				margin-top: 11px;
				margin-bottom: 11px;
				padding: 0 14px;
			}
			// 打字引导光标，不能实现对对齐
			.CodeMirror-cursors {
				visibility: hidden;
				display: none;
				height: 16px;
				position: relative;
			}
			.left-sourcetext {
				width: 430px;
				background: #ffffff;
				border: 1px solid #44a9fe;
				border-radius: 6px;
				margin-bottom: 10px;
				box-shadow: 0px 0px 10px 0px rgba(68, 169, 254, 0.15);
				.CodeMirror {
					height: 550px;
					border-radius: 0 0 6px 6px;
					// 设置滚动条
					.el-table__body-wrapper {
						&::-webkit-scrollbar {
							/*高宽分别对应横竖滚动条的尺寸*/
							width: 4px;
						}

						&::-webkit-scrollbar-thumb {
							/*滚动条里面小方块*/
							background: #dddddd;
							border-radius: 2px;
						}
					}

					.CodeMirror-vscrollbar {
						// background: #dddddd;
						// border-radius: 2px;
					}
				}
			}
			// 复制的链接
			.copy-icon {
				top: 14px;
				right: 22px;
				// display: none;
				color: rgba(56, 56, 56, 1);
				.icon-fuzhi {
					margin-right: 6px;
				}
			}
			.right-result {
				background: #ffffff;
				border-radius: 6px;
				width: 760px;
				margin-bottom: 10px;
				position: relative;
				box-shadow: 0px 0px 10px 0px rgba(68, 169, 254, 0.15);
				.reslut-data-list {
					// 设置滚动条
					.el-table__body-wrapper {
						&::-webkit-scrollbar {
							/*高宽分别对应横竖滚动条的尺寸*/
							width: 4px;
						}

						&::-webkit-scrollbar-thumb {
							/*滚动条里面小方块*/
							background: #dddddd;
							border-radius: 2px;
						}
					}

					//无数据
					.no-batch-data {
						width: 370px;
						height: 220px;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						img {
							width: 100%;
							height: 200px;
							object-fit: cover;
						}
						p {
							text-align: center;
							font-weight: 500;
							color: rgba(128, 128, 128, 1);
						}
					}

					// 表格
					.reslut-tabledata {
						.el-table__row {
							td {
								background: #f8f8f8;
							}
						}

						.el-table__row--striped {
							td {
								background: #ffffff;
							}
						}
						.red {
							color: #000 !important;
						}
						.el-table__empty-block {
							// display: none;
						}

						tr {
							line-height: 32px;

							&:nth-child(odd) {
								//奇数行 斑马
								background: #f8f8f8;
							}
						}
						.el-table_1_column_2 {
							white-space: 100px;
						}
						td {
							font-size: 14px;
							font-weight: 400;
							color: #333333;
						}

						.index {
							min-width: 35px;
							text-align: center;
						}

						.code {
							word-wrap: break-word;
							white-space: normal;
							word-break: break-all;
							// text-align: center;
						}

						.status {
							text-align: left;
						}
					}
				}
			}
		}
	}
	.el-input__suffix {
		padding-top: 6px;
	}
}
</style>
