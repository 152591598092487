<template>
	<div>
		<div class="login-dialog-box">
			<div class="login-form">
				<!--账号-->
				<div
					:class="[
						'form-item',
						errTipObj.username.status ? 'err-input-border' : '',
					]"
				>
					<div class="left-icon">
						<img src="../../assets/img/login-icon/zhanghao.png" />
						<span>{{ $t('lang.login.email') }}</span>
					</div>
					<div class="right-input">
						<el-input
							:placeholder="$t('lang.login.emailPlaceholder')"
							v-model.trim="postObj.username"
							@keyup.enter="GoLogin"
							@focus="
								() => {
									errTipObj.username.status = false;
								}
							"
						></el-input>
					</div>
					<div class="err-tip" v-show="errTipObj.username.status">
						{{ errTipObj.username.errTiptext }}
					</div>
				</div>
				<!--密码-->
				<div
					:class="[
						'form-item',
						errTipObj.password.status ? 'err-input-border' : '',
					]"
				>
					<div class="left-icon">
						<img src="../../assets/img/login-icon/mima1.png" />
						<span>{{ $t('lang.login.password') }}</span>
					</div>
					<div class="right-input">
						<el-input
							type="password"
							:placeholder="$t('lang.login.pwdPlaceholder')"
							v-model.trim="postObj.password"
							@keyup.enter="GoLogin"
							show-password
							@focus="
								() => {
									errTipObj.password.status = false;
								}
							"
						></el-input>
					</div>
					<div class="err-tip" v-show="errTipObj.password.status">
						{{ errTipObj.password.errTiptext }}
					</div>
				</div>
				<!--验证码-->
				<div class="verifycode-item">
					<div class="code-input">
						<div class="left-icon">
							<img src="../../assets/img/login-icon/yanzhengma.png" />
							<span>{{ $t('lang.login.captcha') }}</span>
						</div>
						<div class="right-input">
							<el-input
								:placeholder="$t('lang.login.captchaPlaceholder')"
								v-model.trim="postObj.verifyCode"
								@keyup.enter="GoLogin"
							></el-input>
						</div>
					</div>
					<div class="code-img">
						<img
							ref="captcha"
							:src="src"
							:alt="$t('lang.login.captcha')"
							@click="srcRandom = Math.random()"
						/>
					</div>
				</div>
				<!--是否同意协议 -->
				<div class="isTerms-service">
					<el-checkbox v-model="isConsentProtocol">{{
						$t('lang.login.consentProtocol')
					}}</el-checkbox>
					<el-link class="protocol-link" @click="openDialog(1)"
						>《{{ $t('lang.login.protocol') }}》</el-link
					>{{ $t('lang.login.and') }}

					<el-link class="protocol-link" @click="openDialog(2)"
						>《{{ $t('lang.privacyPolicy.privacyPolicy') }}》</el-link
					>
				</div>

				<!--登录按钮-->
				<div class="login-btn">
					<el-button
						type="primary"
						class="cmd5-loginbtn"
						:loading="loginLoading"
						@click="GoLogin"
						>{{ $t('lang.login.login') }}</el-button
					>
				</div>
				<!--提示去注册-->
				<div class="tip-register">
					<el-link @click="goRegister" class="register-link" :underline="false"
						><span>{{ $t('lang.login.register') }}</span>
						<span class="to-register">{{
							$t('lang.login.goregister')
						}}</span></el-link
					>
					<el-link
						@click="goForgotpwd"
						class="register-link"
						:underline="false"
						>{{ $t('lang.login.goForgotpwd') }}</el-link
					>
				</div>
				<!-- 其他登录 -->

				<el-divider
					><span>{{ $t('lang.login.otherLogin') }}</span></el-divider
				>
				<div class="third-party-login">
					<div class="qqlogin-box" @click="toQQLogin">
						<div class="img-qq-wx">
							<img
								src="@/assets/img/login-icon/qq-logo.png"
								:alt="$t('lang.login.qqLogin')"
							/>
						</div>
						<span>{{ $t('lang.login.qqLogin') }}</span>
					</div>
					<div class="wxlogin-box" @click="toWXLogin">
						<div class="img-qq-wx">
							<img
								src="@/assets/img/login-icon/wx-logo.png"
								:alt="$t('lang.login.weChatLogin')"
							/>
						</div>
						<span>{{ $t('lang.login.weChatLogin') }}</span>
					</div>
				</div>
			</div>
			<!-- 协议弹框 dialog -->
			<div class="agreement-dialog">
				<el-dialog
					:visible="agreementDialogVisible"
					append-to-body
					class="login-inner-dialog"
					@close="confirmAgreement"
				>
					<div slot="title" class="title action-title">
						<span>{{ agreementData.title }}</span>
					</div>
					<!-- 主体部分 -->
					<div
						style="line-height: 24px; word-break: break-all"
						v-html="agreementData.content"
					></div>
					<div slot="footer" class="dialog-footer">
						<el-button
							class="confirm-btn"
							@click="confirmAgreement"
							type="primary"
							>{{ $t('lang.agreementData.confirm') }}</el-button
						>
					</div>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
import { login } from '@/api/login';
import { mycenter } from '@/api/header';
const Base64 = require('js-base64').Base64;
export default {
	name: 'login-dialog',
	components: {},
	data() {
		return {
			name: this.$t('lang.login.title'),
			srcRandom: Math.random(),
			isConsentProtocol: false, //是否同意协议
			agreementVisible: '', // 协议弹框
			agreementDialogVisible: false,
			agreementData: {},
			loginLoading: false,
			errTipStatus: false,
			postObj: {
				username: '',
				password: '',
				verifyCode: '', //验证码
				rememberPWD: false,
			},
			// 错误状态的提示
			errTipObj: {
				username: {
					status: false,
					errTiptext: '',
				},
				password: {
					status: false,
					errTiptext: '',
				},
			},
		};
	},
	computed: {
		src() {
			return `/api/v1/captcha?type=login&'${this.srcRandom}`;
		},
	},
	created() {
		// 在页面加载时从cookie获取登录信息
		let username = this.getCookie('username');
		let password = Base64.decode(this.getCookie('password'));
		// 如果存在赋值给表单，并且将记住密码勾选
		if (username) {
			this.postObj.username = username;
			this.postObj.password = password;
			this.postObj.rememberPWD = true;
		}
	},
	methods: {
		// 用户协议的弹窗
		openDialog(_id) {
			this.agreementVisible = _id;
			if (_id == 1) {
				this.agreementDialogVisible = true;
				this.agreementData.title = this.$t('lang.login.protocol');
				this.agreementData.content = this.$t('lang.agreementData.content');
			} else if (_id == 2) {
				this.agreementDialogVisible = true;
				this.agreementData.title = this.$t('lang.privacyPolicy.title');
				this.agreementData.content = this.$t('lang.privacyPolicy.content');
			}
		},
		//确定
		confirmAgreement() {
			this.agreementDialogVisible = false;
		},
		goRegister() {
			// this.$emit('onLoginType', ['register', this.$t('lang.login.loginType')]);
			this.$store.commit('setComponentType', 'register');
			this.$store.commit('setCormTitle', '注册');
			this.$store.commit('setCormTitle', this.$t('lang.register.register'));
		},
		goForgotpwd() {
			this.$store.commit('setComponentType', 'forgotpwd');
			this.$store.commit('setCormTitle', '忘记密码');
			this.$store.commit('setCormTitle', this.$t('lang.forgotPwd.forgotpwd'));
			// this.$emit('onLoginType', [
			// 	'forgotpwd',
			// 	this.$t('lang.login.loginType2'),
			// ]);
		},
		/////////
		//点击登录
		async GoLogin() {
			const IsSubmit = this.checkForm();
			if (!IsSubmit) {
				//条件不成立不能提交
				return;
			}
			const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			if (!emailreg.test(this.postObj.username)) {
				this.errTipObj.username.status = true;
				this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
				return false;
			}
			this.loginLoading = true;
			try {
				const res = await login(this.postObj);

				//如果报错就退出了。
				if (res.code == 400) {
					this.$refs.captcha.click(); //更换验证码
					this.loginLoading = false;
					return;
				}

				// 登录成功!
				if (res) {
					// Vuex保存 token
					this.$store.commit('setToken', res.token);
					//登录成功 请求获取一下用户的信息,赋值到页头显示登录
					const userInfo = await mycenter();
					this.loginLoading = false;
					if (userInfo) {
						this.userInfoObj = userInfo;
						// this.userInfoObj.photo = require("../assets/img/user.png");

						this.$store.commit('setUserInfo', userInfo);
						this.$store.commit('setDialogVisible', false);
						this.$store.commit('setComponentType', 'login');
						this.$message({
							message: this.$t('lang.login.message'),
							type: 'success',
							duration: 1200,
							onClose: () => {
								//this.md5hasLogin = true; //成功登录了
								// this.$router.push({
								//   path: `/`
								// });
								this.$emit('onLoginClose', false);
							},
						});
					}
				}
			} catch (err) {
				console.log(err, '报错了');
				this.loginLoading = false;
			}
			this.$refs.captcha.click(); //更换验证码
		},
		//点击QQ登录
		toQQLogin() {
			//以下为按钮点击事件的逻辑。注意这里要重新打开窗口
			//否则后面跳转到QQ登录，授权页面时会直接缩小当前浏览器的窗口，而不是打开新窗口
			/* window.open(
         "/api/v1/qq_login", "TencentLogin", `width=800,height=600,menubar=0,scrollbars=1,resizable=1,status=1,titlebar=0,toolbar=0,location=1`
       );*/
			window.location.href = '/api/v1/qq_login';
		},
		//微信登录
		toWXLogin() {
			this.$EventBus.$emit('toWXLogin', true);
			this.$store.commit('setDialogVisible', false);
			this.$store.commit('setComponentType', 'login');
			this.$store.commit('setCormTitle', this.$t('lang.login.login'));
		},
		// 储存表单信息
		setUserInfo: function () {
			// 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
			// 如果没有勾选，储存的信息为空
			if (this.postObj.rememberPWD) {
				this.setCookie('username', this.postObj.username);
				// base64加密密码
				let passWord = Base64.encode(this.postObj.password);
				this.setCookie('password', passWord);
			} else {
				this.setCookie('username', '');
				this.setCookie('password', '');
			}
		},
		// 获取cookie
		getCookie: function (key) {
			if (document.cookie.length > 0) {
				var start = document.cookie.indexOf(key + '=');
				if (start !== -1) {
					start = start + key.length + 1;
					var end = document.cookie.indexOf(';', start);
					if (end === -1) end = document.cookie.length;
					return unescape(document.cookie.substring(start, end));
				}
			}
			return '';
		},
		// 保存cookie
		setCookie: function (cName, value, expiredays) {
			var exdate = new Date();
			exdate.setDate(exdate.getDate() + expiredays);
			document.cookie =
				cName +
				'=' +
				decodeURIComponent(value) +
				(expiredays == null ? '' : ';expires=' + exdate.toGMTString());
		},

		// 检查提交数据，限制提交。
		checkForm() {
			if (!this.postObj.username) {
				//this.$message.error('请输入用户名!');
				this.errTipObj.username.status = true;
				this.errTipObj.username.errTiptext = this.$t('lang.login.errTiptext1');
				return false;
			}
			if (!this.postObj.password) {
				//this.$message.error('请输入密码!');
				this.errTipObj.password.status = true;
				this.errTipObj.password.errTiptext = this.$t('lang.login.errTiptext2');
				return false;
			}

			if (!this.postObj.verifyCode) {
				// this.$notify.error({
				//   title: '提示',
				//   message: '请填写验证码'
				// })
				this.$message.error(this.$t('lang.login.errTiptext3'));
				return false;
			}
			if (!this.isConsentProtocol) {
				// this.$notify.error({
				//   title: '提示',
				//   message: '请勾选协议选项'
				// })
				this.$message.error(this.$t('lang.login.errTiptext4'));
				return false;
			}
			return true;
		},
	},
};
</script>

<style lang="scss" scoped>
.login-dialog-box {
	.privacyPolicy-link {
		font-size: 12px;
		color: #44a9fe;
	}
	.err-tip {
		font-size: 12px;
		font-weight: 400;
		color: #ff0000;
		position: absolute;
		// left: 0;
		top: 130%;
		transform: translateY(-50%);
		text-align: center;
	}
	.err-input-border {
		border: 1px solid #ff0000;
	}
	.login-form {
		// margin-top: 50px;
		.form-item {
			width: 400px;
			height: 46px;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			margin: 0 auto 25px;
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left-icon {
				width: 144px;
				height: 100%;
				display: flex;
				align-items: center;
				padding: 0px 0 0 16px;

				img {
					width: 18px;
					height: auto;
					object-fit: cover;
				}
				span {
					font-size: 14px;
					font-weight: bold;
					color: #333333;
					line-height: 46px;
					margin-left: 10px;
				}
			}
			.right-input {
				width: calc(100% - 84px);
				input {
					border: 0;
					outline: 0;
				}
				input:focus {
					box-shadow: none;
				}
			}
		}
	}
	.isTerms-service {
		margin: 0 auto 25px;
		display: flex;
		align-items: center;
		text-align: center;
		margin-left: 12px;
		.el-checkbox__label {
			font-size: 12px;
		}
		.protocol-link {
			font-size: 12px;
			color: #44a9fe;
		}
	}
	.verifycode-item {
		margin: 0 auto 21px;
		width: 400px;
		height: 46px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		.code-input {
			width: 270px;
			height: 46px;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left-icon {
				width: 144px;
				height: 100%;
				display: flex;

				align-items: center;
				padding: 0 0 0 16px;
				i {
					font-size: 18px;
					color: #333333;
				}
				span {
					font-size: 14px;
					font-weight: bold;
					color: #333333;
					line-height: 46px;
					margin-left: 6px;
				}
			}
			input {
				border: 0;
				outline: 0;
				padding: 0.375rem 0.75rem 0.375rem 0;
			}
			input:focus {
				box-shadow: none;
			}
		}
		.code-img {
			width: 113px;
			height: 46px;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			overflow: hidden;
			padding: 5px;
			img {
				width: 103px;
				height: 35px;
				cursor: pointer;
			}
		}
	}
	.login-btn {
		width: 400px;
		height: 54px;
		box-shadow: 0px 5px 4px 0px rgba(68, 169, 254, 0.2);
		border-radius: 6px;
		margin: auto;
		.cmd5-loginbtn {
			height: 100%;
			font-size: 18px;
			width: 100%;
			border-radius: 6px;
		}
	}
	.tip-register {
		margin: 20px auto 35px;
		width: 360px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.register-link {
			font-size: 14px;
			font-weight: 400;
			color: rgba(166, 166, 166, 1);
		}
		.register-link:hover {
			text-decoration: none;
		}
		.to-register {
			color: #44a9fe;
		}
		.checkbox {
			-moz-user-select: none; /* Firefox私有属性 */
			-webkit-user-select: none; /* WebKit内核私有属性 */
			-ms-user-select: none; /* IE私有属性(IE10及以后) */
			-khtml-user-select: none; /* KHTML内核私有属性 */
			-o-user-select: none; /* Opera私有属性 */
			user-select: none; /* CSS3属性 */
			cursor: pointer;
		}
	}
	// 其他登录的分割线
	.el-divider--horizontal {
		margin: 41px 0 34px 0;
		background-color: #eeeeee;
	}
	.el-divider__text {
		color: rgba(128, 128, 128, 1);
	}
	.third-party-login {
		width: 420px;
		// height: 60px;
		display: flex;
		justify-content: center;
		.qqlogin-box,
		.wxlogin-box {
			cursor: pointer;
			margin: 0 14px;
			display: flex;
			flex-direction: column;
			align-items: center;
			&:hover {
				color: #37bef0;
				span {
					color: #37bef0;
				}
			}
			span {
				font-size: 14px;
				font-weight: 400;
				color: rgba(128, 128, 128, 1);
				margin-left: 3px;
				margin-top: 4px;
			}
			.img-qq-wx {
				width: 40px;
				height: 40px;
				background: rgba(242, 242, 242, 1);
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 20px;
				}
			}
		}
	}
}
</style>
