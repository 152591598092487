<template>
	<div class="forgotpwd-dialog-box">
		<!-- 表单区域 -->
		<div class="login-form">
			<!-- 没验证邮箱的情况下 -->
			<div class="input-mailbox" v-if="!verifyMailStatus">
				<!--账号-->
				<div
					class="form-item"
					:class="[errTipObj.username.status ? 'err-input-border' : '']"
				>
					<div class="left-icon">
						<img
							src="../../assets/img/login-icon/zhanghao.png"
							:alt="$t('lang.forgotPwd.email')"
						/>
						<span>{{ $t('lang.forgotPwd.email') }}</span>
					</div>
					<div class="right-input">
						<el-input
							:placeholder="$t('lang.forgotPwd.emailPlaceholder')"
							v-model.trim="postObj.username"
							@focus="
								() => {
									errTipObj.username.status = false;
								}
							"
						></el-input>
					</div>
					<div class="err-tip" v-show="errTipObj.username.status">
						{{ errTipObj.username.errTiptext }}
					</div>
				</div>
				<!--密码-->
				<div
					class="form-item"
					:class="[errTipObj.password.status ? 'err-input-border' : '']"
				>
					<div class="left-icon">
						<img
							src="../../assets/img/login-icon/mima1.png"
							:alt="$t('lang.forgotPwd.password')"
						/>
						<span>{{ $t('lang.forgotPwd.password') }}</span>
					</div>
					<div class="right-input">
						<el-input
							type="password"
							:placeholder="$t('lang.forgotPwd.msg4')"
							v-model.trim="postObj.password"
							show-password
							@focus="
								() => {
									errTipObj.password.status = false;
								}
							"
						></el-input>
					</div>
					<div class="err-tip" v-show="errTipObj.password.status">
						{{ errTipObj.password.errTiptext }}
					</div>
				</div>
				<!-- 确认密码 -->
				<div
					class="form-item"
					:class="[errTipObj.againPassword.status ? 'err-input-border' : '']"
				>
					<div class="left-icon">
						<img
							src="../../assets/img/login-icon/mima1.png"
							:alt="$t('lang.forgotPwd.password')"
						/>
						<span>{{ $t('lang.forgotPwd.againPassword') }}</span>
					</div>
					<div class="right-input">
						<el-input
							type="password"
							:placeholder="$t('lang.forgotPwd.msg5')"
							v-model.trim="checkPassword"
							show-password
							@focus="
								() => {
									errTipObj.againPassword.status = false;
								}
							"
						></el-input>
					</div>
					<div class="err-tip" v-show="errTipObj.againPassword.status">
						{{ errTipObj.againPassword.errTiptext }}
					</div>
				</div>
				<!--验证码-->
				<div class="verifycode-item">
					<div
						class="code-input"
						:class="[errTipObj.verifyCode.status ? 'err-input-border' : '']"
					>
						<div class="left-icon">
							<img
								src="../../assets/img/login-icon/yanzhengma.png"
								:alt="$t('lang.forgotPwd.captcha')"
							/>
							<span>{{ $t('lang.forgotPwd.captcha') }}</span>
						</div>
						<div class="right-input">
							<el-input
								:placeholder="$t('lang.forgotPwd.captchaPlaceholder')"
								v-model.trim="postObj.verifyCode"
								@focus="
									() => {
										errTipObj.verifyCode.status = false;
									}
								"
							></el-input>
						</div>
					</div>
					<div class="code-img">
						<span class="get-text" @click="sendCode" v-show="!pastShow">{{
							$t('lang.forgotPwd.getCaptcha')
						}}</span>
						<span class="send-text" v-show="pastShow"
							>{{ $t('lang.forgotPwd.sent') }}({{ timerCount }}s)</span
						>
						<!-- <span class="send-text">再次发送验证码</span> -->
					</div>
				</div>

				<!--下一步-->
				<div class="login-btn">
					<el-button
						type="primary"
						class="cmd5-loginbtn"
						:loading="nextStepLoading"
						@click="nextStep"
						>{{ $t('lang.forgotPwd.nextStep') }}</el-button
					>
				</div>
				<div class="tip-register">
					<el-link @click="goLogin" class="register-link" :underline="false">{{
						$t('lang.forgotPwd.goLogin')
					}}</el-link>
				</div>
			</div>
			<!-- 通过邮箱验证 -->
			<div class="pass-mailbox" v-else>
				<div class="title">{{ $t('lang.forgotPwd.title') }}</div>
				<p class="sent-text">{{ $t('lang.forgotPwd.sentText') }}</p>
				<div class="emails-tip">
					<!-- <span class="email">274454439@qq.com</span>
          <span class="gomail">请去查看邮件</span>-->
				</div>
				<div class="login-btn">
					<el-button type="primary" class="cmd5-loginbtn" @click="goLogin">{{
						$t('lang.forgotPwd.goLogin')
					}}</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { forgotPwd, genCode } from '../../api/pwd';
export default {
	name: 'forgotpwd-dialog',
	data() {
		return {
			nextStepLoading: false,
			verifyMailStatus: false, // 验证邮箱通过状态
			checkPassword: '',
			postObj: {
				username: '',
				password: '',
				verifyCode: '',
			},
			activeblueState: false,
			// 错误状态的提示
			errTipObj: {
				username: {
					status: false,
					errTiptext: '',
				},
				password: {
					status: false,
					errTiptext: '',
				},
				againPassword: {
					status: false,
					errTiptext: '',
				},
				verifyCode: {
					status: false,
				},
			},
		};
	},

	methods: {
		goLogin() {
			this.$store.commit('setComponentType', 'login');
			this.$store.commit('setCormTitle', this.$t('lang.login.login'));
		},
		//点击找回密码
		nextStep() {
			const IsSubmit = this.checkForm(); //状态
			if (IsSubmit) {
				if (!this.postObj.verifyCode) {
					this.$message.error(this.$t('lang.forgotPwd.captchaPlaceholder'));
					return false;
				}
				this.nextStepLoading = true;
				forgotPwd(this.postObj)
					.then((res) => {
						if (res && res.code == 200) {
							this.verifyMailStatus = true;
						} else {
							this.pastShow = false;
							clearInterval(this.timer);
							this.timer = null;
						}
						this.nextStepLoading = false;
					})
					.catch(() => {
						this.nextStepLoading = false;
						// 报错重置计时器
						this.pastShow = false;
						clearInterval(this.timer);
						this.timer = null;
					});
			}
		},
		sendCode() {
			const IsSubmit = this.checkForm(); //状态
			const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			if (!emailreg.test(this.postObj.username)) {
				this.errTipObj.username.status = true;
				this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
				return false;
			}
			if (IsSubmit) {
				genCode({
					email: this.postObj.username,
				})
					.then((res) => {
						console.log(res);
						if (res && res.code == 200) {
							// 倒计时60s
							const TIME_COUNT = 60;
							if (!this.timer) {
								this.timerCount = TIME_COUNT;
								//计时器
								this.pastShow = true;
								this.timer = setInterval(() => {
									if (this.timerCount > 0 && this.timerCount <= TIME_COUNT) {
										this.timerCount--;
									} else {
										this.pastShow = false;
										clearInterval(this.timer);
										this.timer = null;
									}
								}, 1000);
							}

							// 提示邮箱验证码发送成功
							this.$message.success({
								title: this.$t('lang.prompt'),
								message: this.$t('lang.forgotPwd.msg'),
								showClose: false,
								duration: 2000,
							});
						}
					})
					.catch((err) => {
						console.log(err);
						this.pastShow = false;
						clearInterval(this.timer);
						this.timer = null;
					});
			}
		},
		// 检查提交数据
		checkForm() {
			if (!this.postObj.username) {
				this.errTipObj.username.status = true;
				this.errTipObj.username.errTiptext = this.$t('lang.forgotPwd.errEmail');
				return false;
			}
			const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			if (!emailreg.test(this.postObj.username)) {
				this.errTipObj.username.status = true;
				this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
				return false;
			}
			if (!this.postObj.password) {
				//this.$message.error('请输入密码!');
				this.errTipObj.password.status = true;
				this.errTipObj.password.errTiptext = this.$t('lang.forgotPwd.msg4');

				return false;
			}
			if (!this.checkPassword) {
				//this.$message.error('请输入确认密码!');
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t(
					'lang.forgotPwd.msg5'
				);
				return false;
			}
			if (this.postObj.password !== this.checkPassword) {
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t(
					'lang.forgotPwd.msg6'
				);
				//this.$message.error('密码和确认密码不一致');
				return false;
			}

			return true;
		},
	},
};
</script>

<style lang="scss" scoped>
.forgotpwd-dialog-box {
	.err-tip {
		font-size: 12px;
		font-weight: 400;
		color: #ff0000;
		position: absolute;
		// left: 0;
		top: 130%;
		transform: translateY(-50%);
		width: 100%;
	}
	.err-input-border {
		border: 1px solid #ff0000;
	}
	//
	.active-blue {
		border: 1px solid #44a9fe !important;
	}

	.pass-mailbox {
		.title {
			font-size: 24px !important;
			font-weight: bold !important;
			color: #333333 !important;
			text-align: center;
			margin-bottom: 20px;
		}

		.sent-text {
			text-align: center;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
		}

		.emails-tip {
			text-align: center;
			margin-bottom: 30px;

			.email {
				font-size: 18px;
				font-weight: bold;
				color: #333333;
			}
		}
	}
	//
	.login-form {
		.form-item {
			width: 360px;
			height: 46px;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

			border-radius: 6px;
			margin: 0 auto 25px;
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.err-tip {
				font-size: 12px;
				font-weight: 400;
				color: #ff0000;
				position: absolute;
				top: 130%;
				transform: translateY(-50%);
				text-align: left;
			}

			.left-icon {
				width: 144px;
				height: 100%;
				display: flex;
				align-items: center;
				padding: 0px 0 0 16px;

				span {
					font-size: 14px;
					font-weight: bold;
					color: #333333;
					line-height: 46px;
					margin-left: 10px;
				}
				img {
					width: 18px;
					height: auto;
					object-fit: cover;
				}
			}

			.right-input {
				width: calc(100% - 84px);

				input {
					border: 0;
					outline: 0;
				}

				input:focus {
					box-shadow: none;
				}
			}
		}
	}

	.verifycode-item {
		margin: 0 auto 32px;
		width: 360px;
		height: 46px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		.code-input {
			width: 238px;
			height: 46px;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left-icon {
				width: 144px;
				height: 100%;
				display: flex;
				align-items: center;
				padding: 0px 0 0 16px;
				span {
					font-size: 14px;
					font-weight: bold;
					color: #333333;
					line-height: 46px;
					margin-left: 5px;
				}
				img {
					margin-right: 4px;
				}
			}

			input {
				border: 0;
				outline: 0;
				padding: 0.375rem 0.75rem 0.375rem 0;
			}

			input:focus {
				box-shadow: none;
			}
		}

		.code-img {
			width: 113px;
			height: 46px;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			line-height: 46px;
			text-align: center;
			overflow: hidden;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}

			.get-text {
				font-size: 14px;
				font-weight: bold;
			}

			.send-text {
				font-size: 14px;
				font-weight: bold;
				color: #ccc;
			}
		}
	}

	.login-btn {
		width: 360px;
		height: 46px;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
		border-radius: 6px;
		margin: auto;

		.cmd5-loginbtn {
			height: 100%;
			font-size: 18px;
			width: 100%;
			border-radius: 6px;
		}
	}

	.tip-register {
		margin: 20px auto 20px;
		width: 360px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.register-link {
			font-size: 14px;
			font-weight: 400;
			color: #44a9fe;
		}

		.checkbox {
			-moz-user-select: none;
			/* Firefox私有属性 */
			-webkit-user-select: none;
			/* WebKit内核私有属性 */
			-ms-user-select: none;
			/* IE私有属性(IE10及以后) */
			-khtml-user-select: none;
			/* KHTML内核私有属性 */
			-o-user-select: none;
			/* Opera私有属性 */
			user-select: none;
			/* CSS3属性 */
			cursor: pointer;
		}
	}
}
</style>
