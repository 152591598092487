<template>
	<div>
		<div class="register-dialog-box">
			<!--表单区域-->
			<div class="login-form">
				<!--邮箱-->
				<div
					class="form-item"
					:class="errTipObj.username.status ? 'err-input-border' : ''"
				>
					<div class="left-icon">
						<img
							src="../../assets/img/login-icon/zhanghao.png"
							:alt="$t('lang.forgotPwd.email')"
						/>
						<span>{{ $t('lang.register.email') }}</span>
					</div>
					<div class="right-input">
						<el-input
							:placeholder="$t('lang.register.emailPlaceholder')"
							v-model.trim="postObj.username"
							@focus="
								() => {
									errTipObj.username.status = false;
								}
							"
							@keyup.enter="GoRegister"
						></el-input>
					</div>
					<div class="err-tip" v-show="errTipObj.username.status">
						{{ errTipObj.username.errTiptext }}
					</div>
				</div>
				<!--密码-->
				<div
					class="form-item"
					:class="errTipObj.password.status ? 'err-input-border' : ''"
				>
					<div class="left-icon">
						<img
							src="../../assets/img/login-icon/mima1.png"
							:alt="$t('lang.forgotPwd.password')"
						/>
						<span>{{ $t('lang.register.password') }}</span>
					</div>
					<div class="right-input">
						<el-input
							type="password"
							:placeholder="$t('lang.register.pwdPlaceholder')"
							v-model.trim="postObj.password"
							@keyup.enter="GoRegister"
							show-password
							@focus="
								() => {
									errTipObj.password.status = false;
								}
							"
						></el-input>
					</div>
					<div class="err-tip" v-show="errTipObj.password.status">
						{{ errTipObj.password.errTiptext }}
					</div>
				</div>
				<!-- 确认密码 -->
				<div
					class="form-item"
					:class="errTipObj.againPassword.status ? 'err-input-border' : ''"
				>
					<div class="left-icon">
						<img
							src="../../assets/img/login-icon/mima1.png"
							:alt="$t('lang.forgotPwd.password')"
						/>
						<span>{{ $t('lang.register.againPassword') }}</span>
					</div>
					<div class="right-input">
						<el-input
							type="password"
							:placeholder="$t('lang.register.pwdPlaceholder')"
							v-model.trim="checkPassword"
							@keyup.enter="GoRegister"
							show-password
							@focus="
								() => {
									errTipObj.againPassword.status = false;
								}
							"
						></el-input>
					</div>
					<div class="err-tip" v-show="errTipObj.againPassword.status">
						{{ errTipObj.againPassword.errTiptext }}
					</div>
				</div>
				<!--验证码-->
				<div class="verifycode-item">
					<div
						class="code-input"
						:class="errTipObj.verifyCode.status ? 'err-input-border' : ''"
					>
						<div class="left-icon">
							<img
								src="../../assets/img/login-icon/yanzhengma.png"
								:alt="$t('lang.forgotPwd.captcha')"
							/>
							<span>{{ $t('lang.register.captcha') }}</span>
						</div>
						<div class="right-input">
							<el-input
								:placeholder="$t('lang.register.captchaPlaceholder')"
								v-model.trim="postObj.verifyCode"
								@focus="
									() => {
										errTipObj.verifyCode.status = false;
									}
								"
								@keyup.enter="GoRegister"
							></el-input>
						</div>
					</div>
					<div class="code-img">
						<span class="get-text" @click="sendCode" v-show="!pastShow">{{
							$t('lang.register.getCaptcha')
						}}</span>
						<span class="send-text" v-show="pastShow"
							>{{ $t('lang.register.sent') }}({{ timerCount }}s)</span
						>
						<!-- <span class="send-text">再次发送验证码</span> -->
					</div>
				</div>
				<!--登录按钮-->
				<div class="login-btn">
					<el-button
						type="primary"
						class="cmd5-registerbtn"
						@click="GoRegister"
						>{{ $t('lang.register.register') }}</el-button
					>
				</div>
				<!--提示去注册-->
				<div class="tip-register">
					<el-link @click="goLogin" class="register-link" :underline="false">{{
						$t('lang.register.goLogin')
					}}</el-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { register, genCode } from '../../api/register';
export default {
	name: 'register-dialog',
	data() {
		return {
			name: this.$t('lang,login.loginType1'),
			verifyCode: '', //验证码
			postObj: {
				username: '',
				password: '',
				verifyCode: '', //验证码
				shareId: '', //分享的id
			},

			errTipObj: {
				username: {
					status: false,
					errTiptext: '',
				},
				password: {
					status: false,
					errTiptext: '',
				},
				againPassword: {
					status: false,
					errTiptext: '',
				},
				verifyCode: {
					status: false,
				},
			},
			checkPassword: '',
		};
	},
	methods: {
		goLogin() {
			this.$store.commit('setComponentType', 'login');
			this.$store.commit('setCormTitle', this.$t('lang.login.login'));
			// this.$emit('onRegisterType', ['login', this.$t('lang.loginType')]);
		},
		//点击注册
		GoRegister() {
			const IsSubmit = this.checkForm(); //状态
			if (!IsSubmit) {
				return false;
			}
			if (!this.postObj.verifyCode) {
				this.errTipObj.verifyCode.status = true;
				this.$message.error(this.$t('lang.register.captchaPlaceholder'));
				return false;
			}
			const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			if (!emailreg.test(this.postObj.username)) {
				this.errTipObj.username.status = true;
				this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
				return false;
			}
			// 执行查询url地址 是否包含有 分享id
			this.postObj.shareId = this.GetQueryString('shareId');
			if (IsSubmit) {
				register(this.postObj).then((res) => {
					if (res && res.code == 400) {
						this.pastShow = false;
						clearInterval(this.timer);
						return false;
					}
					//成功注册
					if (res) {
						this.$message.success({
							title: this.$t('lang.prompt'),
							message: this.$t('lang.register.msg1'),
							duration: 2000,
							onClose: () => {
								this.goLogin();
								// this.$router.push({
								//   path: `/login`
								// });
							},
						});
					} else {
						//失败的时候关闭倒计时60s
						this.pastShow = false;
						clearInterval(this.timer);
					}
				});
			}
		},
		doRegister() {
			register(this.postObj).then((res) => {
				if (res.status) {
					this.$notify.success({
						title: '提示',
						message: res.data.message + ', 正在前往登录',
						showClose: false,
						duration: 2000,
						onClose: () => {
							//   this.$router.push({
							//     path: `/login`
							//   });
						},
					});
				} else {
					//注册失败操作
					this.errTipObj.username.status = true;
					this.errTipObj.username.errTiptext = res.msg;
				}
			});
		},
		// 获取邮箱的验证码 const TIME_COUNT = 60;
		sendCode() {
			const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			if (!emailreg.test(this.postObj.username)) {
				this.errTipObj.username.status = true;
				this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
				return false;
			}
			if (!this.checkForm()) return;
			// 倒计时60s
			const TIME_COUNT = 60;
			if (!this.timer) {
				this.timerCount = TIME_COUNT;
				//计时器
				this.pastShow = true;
				this.timer = setInterval(() => {
					if (this.timerCount > 0 && this.timerCount <= TIME_COUNT) {
						this.timerCount--;
					} else {
						this.pastShow = false;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			}

			// 发送验证码,数据对接。
			genCode({
				email: this.postObj.username,
			})
				.then((res) => {
					if (res && res.code == 200) {
						this.$message.success({
							title: this.$t('lang.prompt'),
							message: this.$t('lang.register.msg2'),
							showClose: false,
							duration: 2000,
						});
					} else {
						this.pastShow = false;
						clearInterval(this.timer);
					}
				})
				.catch(() => {
					this.pastShow = false;
					clearInterval(this.timer);
				});
		},
		// 检查提交数据
		checkForm() {
			if (!this.postObj.username) {
				//判断邮箱
				this.errTipObj.username.status = true;
				this.errTipObj.username.errTiptext = this.$t('lang.register.msg3');
				return false;
			}
			if (!this.postObj.password) {
				//判断密码
				this.errTipObj.password.status = true;
				this.errTipObj.password.errTiptext = this.$t('lang.register.msg4');
				return false;
			}
			if (!this.checkPassword) {
				//判断确认密码
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t('lang.register.msg5');
				return false;
			}
			if (this.postObj.password !== this.checkPassword) {
				//判断 确认密码和密码 一致
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t('lang.register.msg6');
				return false;
			}
			return true;
		},
	},
};
</script>

<style lang="scss" scoped>
.register-dialog-box {
	.err-tip {
		font-size: 12px;
		font-weight: 400;
		color: #ff0000;
		position: absolute;
		// right: 216px;

		top: 130%;
		transform: translateY(-50%);
		text-align: center;
	}
	.err-input-border {
		border: 1px solid #ff0000;
	}
	.login-form {
		.form-item {
			width: 360px;
			height: 46px;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			margin: 0 auto 25px;
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left-icon {
				width: 144px;
				height: 100%;
				display: flex;
				align-items: center;
				padding: 0px 0 0 16px;

				span {
					font-size: 14px;
					font-weight: bold;
					color: #333333;
					line-height: 46px;
					display: inline-block;
					margin-left: 10px;
				}
				img {
					width: 18px;
					height: auto;
					object-fit: cover;
				}
			}

			.right-input {
				width: calc(100% - 84px);

				input {
					border: 0;
					outline: 0;
				}

				input:focus {
					box-shadow: none;
				}
			}
		}
	}

	.verifycode-item {
		margin: 0 auto 35px;
		width: 360px;
		height: 46px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		.code-input {
			width: 238px;
			height: 46px;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left-icon {
				width: 146px;
				height: 100%;
				display: flex;
				align-items: center;
				padding: 0px 0 0 16px;

				span {
					font-size: 14px;
					font-weight: bold;
					color: #333333;
					line-height: 46px;
					display: inline-block;
					margin-left: 10px;
				}
				img {
					width: 18px;
					height: auto;
					object-fit: cover;
				}
			}
			input {
				border: 0;
				outline: 0;
				padding: 0.375rem 0.75rem 0.375rem 0;
			}

			input:focus {
				box-shadow: none;
			}
		}

		.code-img {
			width: 113px;
			height: 46px;
			background: #ffffff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			overflow: hidden;
			line-height: 46px;
			text-align: center;
			cursor: pointer;

			.get-text {
				font-size: 14px;
				font-weight: bold;
			}

			.send-text {
				font-size: 14px;
				font-weight: bold;
				color: #ccc;
			}
		}
	}

	.login-btn {
		width: 360px;
		height: 46px;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
		border-radius: 6px;
		margin: auto;

		.cmd5-registerbtn {
			height: 100%;
			font-size: 18px;
			width: 100%;
			border-radius: 6px;
		}
	}

	.tip-register {
		margin: 25px auto 25px;
		width: 360px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.register-link {
			font-size: 14px;
			font-weight: 400;
			color: #333333;
		}

		.register-link:hover {
			text-decoration: none;
			color: #44a9fe;
		}

		.checkbox {
			-moz-user-select: none;
			/* Firefox私有属性 */
			-webkit-user-select: none;
			/* WebKit内核私有属性 */
			-ms-user-select: none;
			/* IE私有属性(IE10及以后) */
			-khtml-user-select: none;
			/* KHTML内核私有属性 */
			-o-user-select: none;
			/* Opera私有属性 */
			user-select: none;
			/* CSS3属性 */
			cursor: pointer;
		}
	}
}
</style>
