import request from '@/utils/request'
export function register(query) {
  return request({
    url: '/goRegister',
    method: 'post',
    data: query
  })
}
export function genCode(query) {
  return request({
    url: '/genCode',
    method: 'post',
    data: query
  })
}
export function verifyCode(query) {
  return request({
    url: '/verifyCode',
    method: 'post',
    data: query
  })
}