// 对 axios 进行二次封装
import axios from 'axios';
import router from '../router/router';
import { Message, } from 'element-ui';
import $store from "@/store/index.js";//使用Vuex
//MessageBox

// 添加一个新的 axios 的实例
const http = axios.create({
  baseURL: '/api/v1', // 统一设置请求地址前缀
  //timeout: 30000 // 请求超时时间
})

// 请求拦截，统一在请求时带上 token
http.interceptors.request.use(function (config) {
  //获取 token
  const token = $store.state.token;
  const language = $store.state.$lang;//当前的语言包类型
  if (token) {
    // 在请求头上带上 token，固定写法
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  config.headers['language'] = language;
  return config;
}, function (error) {
  //console.log('请求拦截错误：', error)
  Message({
    message: error,
    type: 'error',
    duration: 5 * 1000
  })
  return Promise.reject(error)
});

// 响应拦截，处理错误， 如 token 不合法
http.interceptors.response.use(function (res) {
  let result = null;
  const code = res.data.code;

  if (res.data.token) {
    $store.commit('setToken', res.data.token);// 将服务器返回的最新token更新到本地中
  }
  //保存提示语
  $store.commit('setServerMessage', res.data.message);
  //保存状态码
  $store.commit('setServerCode', res.data.code);

  // 响应200 成功
  if (code == 200) {
    result = res.data.data;
    // 判断首页查询，是否需要登录的提示。
    if (res.data.data && res.data.data.need_login) {
      result['message'] = res.data.message;
    }
    // 防止后端 把data = "", 影响后续判断
    if (res.data.data == '' || res.data.data == null) {
      //result = [];
      result = {
        code,
        data: true,
      }
    }
  } else { //失败的情况,提示。

    // 提示报错信息
    Message({
      message: res.data.message,
      type: 'error',
      duration: 3000,
    });

    switch (code) {
      case 401: //未登录 请登录
        $store.commit('clearToken');//清除token
        $store.commit('clearUserInfo');//清除token
        break;
      case 400: //md5查询出现报错的情况

        break;
    }
    if (code == 400) return { code: 400, message: res.data.message }
    // return Promise.reject(res.data);
  }

  return result;
}, (error) => {


  if (error && error.response) {
    let data = error.response.data;
    switch (error.response.status) {
      case 401:
        return router.replace({
          path: '/login',
          query: { redirect: router.currentRoute.fullPath }
        })
      default:
        if (data && data.code && data.code != 200) { // 服务器端返回的响应报错
          Message({
            message: data.message,
            type: 'error',
            duration: 5 * 1000
          })
        } else {
          // 默认报错响应
          Message({
            message: error.response.statusText,
            type: 'error',
            duration: 5 * 1000
          })
        }
    }
  }

  return Promise.reject(error);
});

export default http
