/**
 * @description md5项目 路由配置
 * @author wander
 * @date 2021-11-12  星期五
 *
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import index from '@/views/index'; //  首页
import desc from '@/views/desc'; //  解密范围描述页
import api from '@/views/decryption/api'; //  api 页面
import batch from '@/views/decryption/batch'; //  批量解密
import login from '@/views/login'; //  登录页
import register from '@/views/register'; //  注册页
import forgotpwd from '@/views/forgotpwd'; //  找回密码
import myCenter from '@/views/mycenter'; //  个人中心
import changePwd from '@/views/changePwd'; //  修改密码
import Page404 from '@/views/errorPage/404'; //404
import Page401 from '@/views/errorPage/401'; //401 权限
import toolsList from '@/views/tools/index'; //工具大全
import ascll from '@/views/tools/ascll';
import plaintextUpload from '@/views/plaintextUpload'; //明文上传
import privacyPolicy from "@/views/privacyPolicy"; //隐私条款页面

// 解决守卫跳转 路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
  },
  {
    path: '/desc',
    name: 'desc',
    component: desc,
  },
  {
    path: '/decryption/api',
    name: 'api',
    component: api,
  },
  {
    path: '/decryption/batch',
    name: 'batch',
    component: batch,
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    component: register,
  },
  // 修改密码
  {
    path: '/changePwd',
    name: 'changePwd',
    component: changePwd,
  },
  // 找回密码
  {
    path: '/forgotpwd',
    name: 'forgotpwd',
    component: forgotpwd,
  },
  // 个人中心
  {
    path: '/myCenter',
    name: 'myCenter',
    component: myCenter,
    meta: {
      auth: true,
    },
  },
  //隐私条款页
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: privacyPolicy,
  },
  // 工具大全
  {
    path: '/tool',
    name: 'tool',
    component: toolsList,
  },
  {
    path: '/tool/ascll',
    name: 'ascll',
    component: ascll,
  },
  {
    path: '/tool/aes_des',
    name: 'aes_des',
    component: () => import('@/views/tools/aes-des'),
  },
  {
    path: '/tool/toggleCase',
    name: 'toggle_case',
    component: () => import('@/views/tools/toggle-case'),
  },
  {
    path: '/tool/hash',
    name: 'hash',
    component: () => import('@/views/tools/hash'),
  },
  {
    path: '/tool/code_audit',
    name: 'hash',
    component: () => import('@/views/tools/code-audit'),
  },
  {
    path: '/tool/morse',
    name: 'morse',
    component: () => import('@/views/tools/morse'),
  },
  {
    path: '/tool/websocket_tool',
    name: 'websocket',
    component: () => import('@/views/tools/websocket'),
  },
  // {
  //   path: '/tools/ipQuery',
  //   name:'ip_query',
  //   component: () => import('@/views/tools/ip-query'),
  // },

  //明文上传
  {
    path: '/plaintextUpload',
    name: 'plaintextUpload',
    component: plaintextUpload,
  },
  //java class逆向反编译
  {
    path: '/tool/java_class_decompilation',
    name: 'java_class_decompilation',
    component: () => import('@/views/tools/java-class-decompilation'),
  },
  //	base64编码
  {
    path: '/tool/base64',
    name: 'base64',
    component: () => import('@/views/tools/base64'),
  },
  // 401权限
  {
    path: '/401',
    name: '401',
    component: Page401,
  },
  // 404
  {
    path: '*',
    name: '404',
    component: Page404,
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
