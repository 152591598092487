<!--微信登录扫一扫 模态框 -->
<template>
	<div class="wx-login-content">
		<el-dialog
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			@close="closeWXloginDialog"
		>
			<span slot="title" class="login-title">{{
				dialogTitle || wechatScan
			}}</span>
			<!-- 微信二维码 -->
			<div
				class="qrcode-box"
				v-loading="getdataLoading"
				v-show="!is_scan && !is_reg"
			>
				<div class="qrcode-img" ref="qrCodeUrl" @click="getWxQrcodeImg"></div>
			</div>
			<!-- 首次登录设置 邮箱绑定 -->
			<transition name="firstSetEmail">
				<div class="first-set-email" v-show="is_scan && !is_reg">
					<h3>{{ $t('lang.wxLogin.firstSetEmail') }}</h3>
					<ul>
						<li>
							<div class="left-icon">
								<i class="iconfont icon-youxiang1"></i>
								<span>{{ $t('lang.wxLogin.email') }}</span>
							</div>
							<div class="right-input">
								<el-input
									:placeholder="$t('lang.wxLogin.emailPlaceholder')"
									v-model.trim="postObj.username"
									clearable
								></el-input>
							</div>
						</li>
						<li>
							<div class="code-input">
								<div class="left-icon">
									<i class="iconfont icon-yanzhengma"></i>
									<span>{{ $t('lang.wxLogin.captcha') }}</span>
								</div>
								<div class="right-input">
									<el-input
										:placeholder="$t('lang.wxLogin.captchaPlaceholder')"
										v-model.trim="postObj.verifyCode"
										clearable
									></el-input>
								</div>
							</div>
							<div class="code-img">
								<span class="get-text" @click="sendCode" v-show="!pastShow">{{
									$t('lang.wxLogin.getCaptcha')
								}}</span>
								<span class="send-text" v-show="pastShow"
									>{{ $t('lang.wxLogin.sent') }}({{ timerCount }}s)</span
								>
							</div>
						</li>
					</ul>
					<!--登录按钮-->
					<el-button type="primary" class="md5-login-btn" @click="toLogin">{{
						$t('lang.wxLogin.login')
					}}</el-button>
				</div>
			</transition>
		</el-dialog>
	</div>
</template>

<script>
import { getWxQrcode, getQueryWXLoginResult } from '@/api/login';
import { register, genCode } from '@/api/register';

import QRPayCode from 'qrcodejs2';
export default {
	name: 'wx-login-qrcode',
	data() {
		return {
			wechatScan: this.$t('lang.wxLogin.wechatScan'),
			dialogVisible: false,
			getdataLoading: false,
			postObj: {
				username: '',
				verifyCode: '',
				unique: '',
			},
			pastShow: false,
			timer: null,
			timerCount: 0,
			timerGetWXreult: null,
			is_scan: false,
			is_reg: false,
		};
	},
	props: {
		dialogTitle: String,
	},
	watch: {
		//监听 开关
		dialogVisible(_value) {
			if (_value) {
				this.is_scan = false;
				this.is_reg = false;
			} else {
				//窗口关闭，关掉轮询查询获取登录结果
				clearInterval(this.timerGetWXreult);
				clearInterval(this.timer);
				this.timerGetWXreult = null;
				this.timer = null;
			}
		},
	},
	methods: {
		//关闭登录
		closeWXloginDialog() {
			this.clearTimerDataInit(); //初始化
		},
		//提交首次登录(实际上 是注册)
		/***
		 * @description 首次扫码登录，提交登录
		 *
		 */
		toLogin() {
			//判断是否填写完毕
			if (!this.postObj.username.trim()) {
				return this.$message.error(this.$t('lang.wxLogin.errEmail'));
			}
			if (!this.postObj.verifyCode.trim()) {
				return this.$message.error(this.$t('lang.wxLogin.errCode'));
			}

			//注册操作
			this.getdataLoading = true;
			register(this.postObj)
				.then((res) => {
					this.getdataLoading = false;
					if (res && res.is_reg) {
						//保存token 和 用户信息
						this.$store.commit('setToken', res.token);
						this.$store.commit('setUserInfo', res.userInfo);
						this.dialogVisible = false; //关闭
						this.$message.success(this.$t('lang.wxLogin.successLogin'));
					}
				})
				.catch(() => {});
		},
		/**
		 * @description 发送验证码
		 */
		sendCode() {
			if (!this.postObj.username.trim()) {
				return this.$message.error(this.$t('lang.wxLogin.errEmail'));
			}
			// 启动倒计时60s
			const TIME_COUNT = 60;
			if (!this.timer) {
				this.timerCount = TIME_COUNT;
				//计时器
				this.pastShow = true;
				this.timer = setInterval(() => {
					if (this.timerCount > 0 && this.timerCount <= TIME_COUNT) {
						this.timerCount--;
					} else {
						this.pastShow = false;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			}

			//发送验证码,数据对接。
			genCode({
				email: this.postObj.username,
			})
				.then((res) => {
					if (res && res.code == 200) {
						this.$message.success({
							title: this.$t('lang.prompt'),
							message: this.$t('lang.wxLogin.msg2'),
							showClose: false,
							duration: 2000,
						});
					} else {
						this.pastShow = false;
						clearInterval(this.timer);
					}
				})
				.catch(() => {
					this.pastShow = false;
					clearInterval(this.timer);
				});
		},
		/**
		 * @description  打开微信登录弹框
		 * @param {boolean} _state 微信弹出框的状态 开关
		 */
		OpenWxLoginDialog(_state) {
			this.dialogVisible = _state;
			this.getWxQrcodeImg();
		},
		//获取二维码图片，继续扫一扫
		getWxQrcodeImg() {
			this.getdataLoading = true;
			getWxQrcode()
				.then((res) => {
					this.getdataLoading = false;
					if (res && res.qrcode) {
						//创建之前要初始化.
						this.$refs.qrCodeUrl.innerHTML = '';
						new QRPayCode(this.$refs.qrCodeUrl, {
							text: res.qrcode, //二维码图片路径
							width: 160,
							height: 160,
							colorDark: '#000000',
							colorLight: '#ffffff',
							correctLevel: QRPayCode.CorrectLevel.H,
						});
						this.postObj.unique = res.unique;
						this.clearTimerDataInit(); //初始化计时器和数据
						this.timer_autoQueryWXLogin(); //自动轮询微信登录扫描结果
					}
				})
				.catch(() => {
					this.getdataLoading = false;
				});
		},
		//轮询获取结果
		timer_autoQueryWXLogin() {
			this.timerGetWXreult = null;
			this.timerGetWXreult = setInterval(() => {
				this.getQueryLoginResult();
			}, 1000);
		},
		/**
		 * @description 获取登录扫描结果
		 * @param {string} unique 获取登录结果的凭证key
		 */
		getQueryLoginResult() {
			getQueryWXLoginResult({ unique: this.postObj.unique })
				.then((res) => {
					if (res.is_scan) {
						//查询到结果 返回成功立刻停掉 轮询
						clearInterval(this.timerGetWXreult);
						this.timerGetWXreult = null;
						this.is_reg = res.is_reg;
						this.is_scan = res.is_scan;
						//不是首次登录 注册的。
						if (res.is_reg) {
							// this.$store.commit('setComponentType', 'login');
							this.$store.commit('setToken', res.token);
							this.$store.commit('setUserInfo', res.userInfo);
							this.dialogVisible = false;
							this.$message.success(this.$t('lang.wxLogin.successLogin'));
						}
					}
				})
				.catch(() => {
					clearInterval(this.timerGetWXreult);
					this.timerGetWXreult = null;
				});
		},
		//清除计时器，初始化
		clearTimerDataInit() {
			this.is_scan = false;
			this.is_reg = false;
			clearInterval(this.timerGetWXreult);
			clearInterval(this.timer);
			this.timerGetWXreult = null;
			this.timer = null;
			this.pastShow = false;
			this.timerCount = 0;
		},
	},
	mounted() {
		this.$EventBus.$on('toWXLogin', this.OpenWxLoginDialog);
	},
	created() {},
	destroyed() {
		//移除监听事件总线
		this.$EventBus.$off('toWXLogin', false);
		clearInterval(this.timer);
		clearInterval(this.timerGetWXreult);
	},
};
</script>

<style lang="scss" scoped>
@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translateZ(0);
	}
}
//动效过渡
.firstSetEmail-enter-active,
.firstSetEmail-leave-active {
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-timing-function: ease;
}
.firstSetEmail-enter-active {
	animation-name: fadeInRight;
}

@mixin formitem() {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 46px;
	background: #ffffff;
	box-shadow: 0px 4px 10px 0px #23181533;
	border-radius: 6px;
	margin: 0 auto 25px;
	position: relative;
}

.wx-login-content {
	/deep/ .el-dialog {
		max-width: 378px;
		overflow: hidden;
		border-radius: 4px;
		@media screen and (max-width: 768px) {
			width: 90%;
		}
	}
	/deep/ .el-dialog__body {
		padding: 50px 30px;

		.first-set-email {
			h3 {
				text-align: center;
				color: #6a6e7b;
				font-size: 16px;
				line-height: 1;
			}
			.md5-login-btn {
				height: 100%;
				font-size: 18px;
				width: 100%;
				border-radius: 6px;
				margin-top: 15px;
			}
			ul {
				margin-top: 20px;
				li {
					@include formitem();
					.left-icon {
						padding: 0px 0 0 16px;
						display: flex;
						align-items: center;
						i {
							font-size: 18px;
							color: #333333;
						}
						span {
							font-size: 14px;
							font-weight: bold;
							color: #333333;
							display: inline-block;
							margin-left: 5px;
						}
					}
					.right-input {
						flex: 1;
					}
					&:nth-child(2) {
						background: none;
						box-shadow: none;
					}
					.code-input {
						@include formitem();
						margin: 0;
					}
					.code-img {
						display: block;
						width: 113px;
						height: 46px;
						background: #ffffff;
						box-shadow: 0px 4px 10px 0px #23181533;
						border-radius: 6px;
						overflow: hidden;
						line-height: 46px;
						text-align: center;
						cursor: pointer;
						margin-left: 10px;
						.get-text {
							font-size: 14px;
							font-weight: bold;
							color: #44a9fe;
							cursor: pointer;
							display: block;
						}
						.send-text {
							font-size: 14px;
							font-weight: bold;
							color: #ccc;
						}
					}
				}
			}
		}
	}
	/deep/ .el-dialog__header {
		border-bottom: 1px solid #ddd;
		padding: 20px;
		text-align: center;
		.login-title {
			font-size: 18px;
			line-height: 18px;
			font-weight: bold;
			color: #6a6e7b;
		}
		.el-dialog__close {
			background: #f8f8f8;
			border-radius: 100%;
			font-size: 12px;
			padding: 6px;
		}
	}

	.qrcode-img {
		width: 160px;
		height: 160px;
		background: #cccccc80;
		margin: 0 auto;
		cursor: pointer;
		img {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
