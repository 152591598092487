import request from '@/utils/request'

export function logout(query) {
  return request({
    url: '/logout',
    method: 'post',
    data: query
  })
}
export function mycenter(query) {
  return request({
    url: '/trans/user',
    method: 'post',
    data: query
  })
}

export function passwdModify(query) {
  return request({
    url: '/trans/passwdModify',
    method: 'post',
    data: query
  })
}
