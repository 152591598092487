<!--QQ首次登录需要绑定邮箱-->
<template>
	<div class="QQ-login-content">
		<el-dialog
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			@close="closeQQloginDialog"
		>
			<span slot="title" class="login-title">QQ登录</span>
			<div class="first-set-email">
				<h3>首次登录需要绑定邮箱</h3>
				<ul>
					<li>
						<div class="left-icon">
							<i class="iconfont icon-youxiang1"></i>
							<span>邮箱</span>
						</div>
						<div class="right-input">
							<el-input
								placeholder="请输入正确的邮箱格式"
								v-model.trim="postObj.username"
								clearable
							></el-input>
						</div>
					</li>
					<li>
						<div class="code-input">
							<div class="left-icon">
								<i class="iconfont icon-yanzhengma"></i>
								<span>验证码</span>
							</div>
							<div class="right-input">
								<el-input
									placeholder="邮箱验证码"
									v-model.trim="postObj.verifyCode"
									clearable
								></el-input>
							</div>
						</div>
						<div class="code-img">
							<span class="get-text" @click="sendCode" v-show="!pastShow"
								>获取验证码</span
							>
							<span class="send-text" v-show="pastShow"
								>已发送({{ timerCount }}s)</span
							>
						</div>
					</li>
				</ul>
				<!--登录按钮-->
				<el-button type="primary" class="md5-login-btn" @click="toLogin"
					>登录</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { register, genCode } from '@/api/register';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			dialogVisible: false,
			getdataLoading: false,
			postObj: {
				username: '',
				verifyCode: '',
				qq_state: '',
			},
			pastShow: false,
			timer: null,
			timerCount: 0,
			timerGetWXreult: null,
			is_scan: false,
			is_reg: false,
		};
	},
	computed: {
		...mapState(['qqState']),
	},
	methods: {
		//关闭登录
		closeQQloginDialog() {
			this.clearTimerDataInit(); //初始化
		},
		clearTimerDataInit() {
			clearInterval(this.timer);
			this.timer = null;
			this.pastShow = false;
			this.timerCount = 0;
		},
		//提交首次登录(实际上 是注册)
		/***
		 * @description 首次扫码登录，提交登录
		 *
		 */
		toLogin() {
			//判断是否填写完毕
			if (!this.postObj.username.trim()) {
				return this.$message.error('请填写邮箱！');
			}
			if (!this.postObj.verifyCode.trim()) {
				return this.$message.error('请填写验证码！');
			}
			//注册操作
			this.getdataLoading = true;
			this.postObj['qq_state'] = this.qqState;
			register(this.postObj)
				.then((res) => {
					console.log(res, '扫码登录成功！！');
					this.getdataLoading = false;
					if (res && res.is_reg) {
						//保存token 和 用户信息
						this.$store.commit('setToken', res.token);
						this.$store.commit('setUserInfo', res.userInfo);
						this.dialogVisible = false; //关闭
						this.$message.success('登录成功！');
					}
				})
				.catch(() => {});
		},
		/**
		 * @description 发送验证码
		 */
		sendCode() {
			if (!this.postObj.username.trim()) {
				return this.$message.error('请填写邮箱!');
			}
			// 启动倒计时60s
			const TIME_COUNT = 60;
			if (!this.timer) {
				this.timerCount = TIME_COUNT;
				//计时器
				this.pastShow = true;
				this.timer = setInterval(() => {
					if (this.timerCount > 0 && this.timerCount <= TIME_COUNT) {
						this.timerCount--;
					} else {
						this.pastShow = false;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			}

			//发送验证码,数据对接。
			genCode({
				email: this.postObj.username,
			})
				.then((res) => {
					if (res && res.code == 200) {
						this.$message.success({
							title: '提示',
							message: '邮箱验证码已经发送，请耐心等待查收验证码',
							showClose: false,
							duration: 2000,
						});
					} else {
						this.pastShow = false;
						clearInterval(this.timer);
					}
				})
				.catch(() => {
					this.pastShow = false;
					clearInterval(this.timer);
				});
		},
		//打开QQ登录，首次绑定
		OpenQQLoginDialog(_state) {
			this.dialogVisible = _state;
		},
	},
	mounted() {
		this.$EventBus.$on('toQQLogin', this.OpenQQLoginDialog);
	},
	destroyed() {
		//移除监听事件总线
		this.$EventBus.$off('toQQLogin', false);
		clearInterval(this.timer);
		clearInterval(this.timerGetWXreult);
	},
};
</script>

<style lang="scss" scoped>
@mixin formitem() {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 46px;
	background: #ffffff;
	box-shadow: 0px 4px 10px 0px #23181533;
	border-radius: 6px;
	margin: 0 auto 25px;
	position: relative;
}
.QQ-login-content {
	/deep/ .el-dialog {
		max-width: 400px;
		overflow: hidden;
		border-radius: 4px;
		@media screen and (max-width: 768px) {
			width: 90%;
		}
		.el-dialog__header {
			border-bottom: 1px solid #ddd;
			padding: 20px;
			text-align: center;
			.login-title {
				font-size: 18px;
				line-height: 18px;
				font-weight: bold;
				color: #6a6e7b;
			}
			.el-dialog__close {
				background: #f8f8f8;
				border-radius: 100%;
				font-size: 12px;
				padding: 6px;
			}
		}
		//主体
		.el-dialog__body {
			padding: 50px 30px;

			.first-set-email {
				h3 {
					text-align: center;
					color: #6a6e7b;
					font-size: 16px;
					line-height: 1;
				}
				.md5-login-btn {
					height: 100%;
					font-size: 18px;
					width: 100%;
					border-radius: 6px;
					margin-top: 15px;
				}
				ul {
					margin-top: 20px;
					li {
						@include formitem();
						.left-icon {
							padding: 0px 0 0 16px;
							display: flex;
							align-items: center;
							i {
								font-size: 18px;
								color: #333333;
							}
							span {
								font-size: 14px;
								font-weight: bold;
								color: #333333;
								display: inline-block;
								margin-left: 5px;
							}
						}
						.right-input {
							flex: 1;
						}
						&:nth-child(2) {
							background: none;
							box-shadow: none;
						}
						.code-input {
							@include formitem();
							margin: 0;
						}
						.code-img {
							display: block;
							width: 113px;
							height: 46px;
							background: #ffffff;
							box-shadow: 0px 4px 10px 0px #23181533;
							border-radius: 6px;
							overflow: hidden;
							line-height: 46px;
							text-align: center;
							cursor: pointer;
							margin-left: 10px;
							.get-text {
								font-size: 14px;
								font-weight: bold;
								color: #44a9fe;
								cursor: pointer;
								display: block;
							}
							.send-text {
								font-size: 14px;
								font-weight: bold;
								color: #ccc;
							}
						}
					}
				}
			}
		}
	}
}
</style>
