<!-- 页面头部 导航 -->
<template>
	<header
		class="md5-header"
		:class="isPositionNav || isScrollTopFunc() ? 'md5-position-fixed' : ''"
	>
		<div class="md5-contain md5-row">
			<div class="content">
				<!-- logo图片 -->
				<div class="left">
					<router-link :to="{ path: '/' }">
						<div class="logo-img">
							<img src="@/assets/img/logo.png" alt="logo" />
						</div>
					</router-link>
				</div>
				<!-- 菜单栏 -->
				<el-menu
					:default-active="onRoutes"
					class="md5-top-navmenu"
					mode="horizontal"
					router
				>
					<template v-for="(item, index) in nav_menu_data">
						<template>
							<el-menu-item :index="item.path" :key="index">
								<!-- <span slot="title">{{item.title}}</span> -->
								<a
									:href="domainName + item.path"
									:title="item.title"
									slot="title"
									class
									>{{ item.title }}</a
								>
							</el-menu-item>
						</template>
					</template>
				</el-menu>
			</div>

			<!-- 登录的区域 -->
			<!-- 已登录的状态 -->
			<div class="right-box">
				<div class="login-box" v-show="userInfo">
					<el-popover
						placement="bottom-end"
						trigger="hover"
						popper-class="user-popover"
					>
						<ul class="user-nav">
							<li
								@click="
									$route.path == '/myCenter' ? '' : $router.push('/myCenter')
								"
							>
								{{ $t('lang.myCenter.title') }}
							</li>
							<!-- <li @click="$router.push('/mypoints')">
                {{ $t("lang.myCenter.integralRecord") }}
              </li> -->
							<li
								@click="
									$route.path == '/forgotpwd' ? '' : $router.push('/changePwd')
								"
							>
								{{ $t('lang.myCenter.forgotpwd') }}
							</li>
							<li @click="isloginOutFunc">{{ $t('lang.header.loginOut') }}</li>
						</ul>
						<div slot="reference">
							<el-avatar
								:src="require('../../assets/img/user.png')"
							></el-avatar>
							<span class="username">{{ userInfo && userInfo.email }}</span>
						</div>
					</el-popover>
					<!--移动端菜单栏抽屉 -->
					<div class="mobile-elmenu">
						<el-button plain @click="drawer = !drawer">
							<i class="iconfont icon-caidan"></i>
						</el-button>
					</div>
				</div>
				<!-- 未登录的状态 -->
				<div class="not-login-state" v-show="!userInfo">
					<a href="javascript:void(0)" class="link" @click="toLogin">{{
						$t('lang.header.login')
					}}</a>

					<!--移动端菜单栏抽屉 -->
					<div class="mobile-elmenu">
						<el-button plain @click="drawer = !drawer">
							<i class="iconfont icon-caidan"></i>
						</el-button>
					</div>
				</div>
				<!-- 国际化支持弹窗 -->
				<div class="caret-bottom">
					<el-select
						v-model="selectValue"
						@change="langChange"
						style="width: 6.5625rem; margin-left: 2.8125rem"
					>
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
					<div><i class="el-icon-caret-bottom"></i></div>
				</div>
			</div>
		</div>
		<div
			class="back-line"
			:style="{ display: isPositionNav || isScrollTopFunc() ? 'none' : '' }"
		></div>

		<!-- 移动端菜单抽屉 -->
		<el-drawer
			:visible.sync="drawer"
			:with-header="false"
			direction="ltr"
			:size="250"
			class="mobile-menu"
		>
			<div>
				<!-- 菜单栏 -->
				<el-menu
					:default-active="onRoutes"
					active-text-color="#44a9fe"
					class
					mode="vertical"
					router
				>
					<template v-for="(item, index) in nav_menu_data">
						<template>
							<el-menu-item :index="item.path" :key="index">
								<a
									:href="domainName + item.path"
									:title="item.title"
									slot="title"
									class
									>{{ item.title }}</a
								>
								<!-- <span slot="title">{{item.title}}</span> -->
							</el-menu-item>
						</template>
					</template>
				</el-menu>
			</div>
		</el-drawer>
	</header>
</template>
<script>
import { logout } from '@/api/header';
import { mapState } from 'vuex';
export default {
	name: 'md5-header',

	data() {
		return {
			//activeIndex: '/',
			drawer: false,
			email: '',
			photo: '',
			nav_menu_data: [
				{
					title: this.$t('lang.header.index'),
					path: '/',
				},
				/*{ 业务需求先隐藏
          title: '解密范围',
          path: '/desc'
        },*/
				{
					title: this.$t('lang.header.tool'),
					path: '/tool',
				},
				{
					title: this.$t('lang.header.batch'),
					path: '/decryption/batch',
				},
				{
					title: this.$t('lang.header.plaintextUpload'),
					path: '/plaintextUpload',
				},
				{
					title: 'API',
					path: '/decryption/api',
				},
			],

			selectValue: '简体中文',
			options: [
				{
					value: 'zh-CN',
					label: '简体中文',
				},
				{
					value: 'en-US',
					label: 'English',
				},
				// {
				// 	value: 'ru-RU',
				// 	label: 'Russian',
				// },
			],
			lang: '',
		};
	},
	watch: {
		'$i18n.locale'(news) {
			console.log(news);
			this.setlangNavmenuData(news);
		},
	},
	computed: {
		onRoutes() {
			return this.$route.fullPath;
		},
		//域名
		domainName() {
			return window.location.origin;
		},
		...mapState(['userInfo', '$lang']),
	},

	methods: {
		//动态修改导航语言
		/**
		 * @description sj
		 * @De
		 */
		setlangNavmenuData() {
			this.nav_menu_data.forEach((item) => {
				if (item.path == '/') item.title = this.$t('lang.header.index');
				if (item.path == '/tool') item.title = this.$t('lang.header.tool');
				if (item.path == '/decryption/batch')
					item.title = this.$t('lang.header.batch');
				if (item.path == '/plaintextUpload')
					item.title = this.$t('lang.header.plaintextUpload');
				//if(  item.path == '/decryption/api') 	item.title	= this.$t('lang.header.API');
			});
		},

		// 系统登录
		toLogin() {
			this.$store.commit('setDialogVisible', true);
			this.$EventBus.$emit('toLogin', true);
			this.$store.commit('setCormTitle', this.$t('lang.login.login'));
		},
		//----是否退出登录
		isloginOutFunc() {
			this.$confirm(
				this.$t('lang.myCenter.isloginOut'),
				this.$t('lang.prompt'),
				{
					confirmButtonText: this.$t('lang.myCenter.confirm'),
					cancelButtonText: this.$t('lang.myCenter.cancel'),
					type: 'warning',
				}
			)
				.then(() => {
					this.loginOut(); //退出登录
				})
				.catch(() => {
					//不退出登录
				});
		},
		//-----退出登录
		loginOut() {
			logout().then(() => {
				this.$message.success({
					title: this.$t('lang.prompt'),
					message: this.$t('lang.myCenter.successLogOut'),
					showClose: false,
					duration: 1000,
					onClose: () => {
						//弹框提示, 清空数据
						this.$store.commit('clearUserInfo');
						this.$store.commit('clearToken');
						this.$router.push({
							path: '/',
						});
					},
				});
			});
		},
		//语言切换
		langChange(lang) {
			this.$i18n.locale = lang;
			this.$store.commit('setLang', lang);
			location.reload();
		},
		getLangType() {
			this.$i18n.locale = this.$lang;
			this.selectValue = this.$lang;
		},
	},
	created() {
		this.getLangType();
	},

	mounted() {},
};
</script>

<style lang="scss">
.md5-header {
	height: 4rem;
}
.mobile-menu .el-menu-item {
	a {
		pointer-events: none;
		color: #303133;
	}
	&.is-active a {
		color: #409eff;
	}
}
.md5-position-fixed {
	position: sticky;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1996;
	background: #fff;
	width: 100%;
	height: auto;
	box-shadow: 0.1875rem 0.1875rem 0.8125rem 0.125rem rgba(6, 68, 191, 0.11);
}
header {
	transition: all 0.5s ease;
}
.md5-header {
	background: #ffffff;
	user-select: none;
	.mobile-elmenu {
		display: none;
	}
	.md5-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		// width: 75.5rem;
		.content {
			display: flex;
			align-items: center;
		}
	}
	.left {
		margin-right: 2.5rem;
	}
	.logo-img {
		width: 12.71rem;
		height: 2.3687rem;
		img {
			width: 100%;
			max-height: 100%;
		}
	}

	.md5-top-navmenu {
		background: 0;
		border-bottom: 0 !important;

		.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
			background: 0;
		}
		.el-menu-item {
			font-size: 1.125rem;
			font-weight: 400;
			color: #333333;
			.el-submenu__title {
				font-size: 1rem;
				color: #333333;
			}
			a {
				pointer-events: none;
			}
		}
		.el-menu-item.is-active {
			font-weight: bold;
			border-bottom: 0.1875rem solid #409eff;
			height: 4rem;
			span,
			a {
				color: rgba(68, 169, 254, 1);
				font-weight: 500;
			}
		}

		.el-menu-item:not(.is-disabled):focus,
		.el-menu-item:not(.is-disabled):hover,
		.el-submenu .el-submenu__title:hover {
			background-color: transparent !important;
		}
	}
	.menu-md5-submenu {
		font-size: 1rem;
		font-weight: 400;
		color: #333333;
		.el-submenu__title {
			font-size: 1rem;
			color: #333333 !important;
		}
	}

	.back-line {
		height: 0.125rem;
		background: #ffffff;
		opacity: 0.3;
		transform: translateY(-0.1875rem);
	}
	.right-box {
		display: flex;
	}
	// 登录状态
	.login-box {
		cursor: pointer;
		display: flex;
		align-items: center;
		.user-nav {
			width: 100%;
			font-size: 0.875rem;
			font-weight: 400;
			li {
				width: 100%;
				height: 2.375rem;
				line-height: 2.375rem;
			}
			li:hover {
				background: #44a9fe;
				color: #ffffff;
				cursor: pointer;
			}
		}

		.el-popover__reference {
			display: flex;
			align-items: center;
			.username {
				margin-left: 0.3125rem;
			}
		}
	}
	.not-login-state {
		display: flex;
		align-items: center;
		.gologin {
			width: 1.0625rem;
			height: 1.1875rem;
			img {
				width: 100%;
				max-height: 100%;
			}
		}
		.link {
			font-size: 0.875rem;
			font-weight: 400;
			color: rgba(68, 169, 254, 1);
		}
	}
	.mobile-elmenu .el-button {
		margin-left: 0.9375rem;
	}
}

// 自定义下拉框的样式
.el-menu--popup {
	background: #ffffff;
	box-shadow: 0rem 0.125rem 0.625rem 0.0625rem rgba(35, 24, 21, 0.23) !important;
	border-radius: 0.375rem !important;
}
.el-menu--popup {
	min-width: auto !important;
	.el-menu-item {
		padding: 0 1.25rem !important;
		font-size: 0.875rem;
	}
	.is-active {
		background-color: #44a9fe !important;
		color: #ffffff !important;
	}
}
//用户中心的下拉框
.user-popover {
	//width: 6.375rem !important;
	// height: 5.75rem;
	background: #ffffff;
	box-shadow: 0rem 0.125rem 0.625rem 0.0625rem rgba(35, 24, 21, 0.23);
	border-radius: 0.375rem;
	padding: 0.3125rem 0 !important;
	min-width: 7.1875rem !important;
	border: 0 !important;
	.popover-body {
		padding: 0;
	}
	.popper__arrow {
		left: 5rem !important;
	}
	.user-nav {
		width: 100%;
		// padding-top: .625rem;
		font-size: 0.875rem;
		font-weight: 400;
		user-select: none;
		li {
			width: 100%;
			height: 2.375rem;
			line-height: 2.375rem;
			padding-left: 1.125rem;
		}
		li:hover {
			background: #44a9fe;
			color: #ffffff;
			cursor: pointer;
		}
	}
}
.selected {
	color: #000;
}
.hover {
	background-color: #44a9fe !important;
	color: #fff !important;
}
.el-select__caret.is-reverse {
	display: none;
}
.el-select__caret.el-input__icon {
	display: none;
}

.caret-bottom {
	position: relative;
}
.el-icon-caret-bottom {
	position: absolute;
	right: 1rem;
	top: 0.625rem;
	color: rgba(166, 166, 166, 1);
}
</style>
