/***
 * @description  引入Vuex 持久化存储 保存状态
 * @date         2022-03-24  16:11 星期四 阴
 * @author       wander
 */

import createPersistedState from 'vuex-persistedstate'; //Vuex 持久化存储
import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		token: null,
		userInfo: null,
		md5hasLogin: false,
		uid: null,
		codeAudit_md5_key: null, // 代码审计 上传文件成功key
		codeAudit_file_name: null, // 代码审计 上传成功的文件
		codeAudit_download_md5_key: null, //代码审计 下载需要的key
		serverMessage: '',
		serverCode: 0,
		qqState: '',
		$lang: 'zh-CN',
		$dialogVisible: false,
		$componentType: 'login',
		wxDialog: false,
	},
	getters: {},
	mutations: {
		setWxDialog(state, _wxtype) {
			state.wxDialog = _wxtype;
		},
		setComponentType(state, _type) {
			state.$componentType = _type;
		},
		setCormTitle(state, _formTitle) {
			state.$formTitle = _formTitle;
		},
		setDialogVisible(state, _switchState) {
			state.$dialogVisible = _switchState;
		},
		setLang(state, _langState) {
			state.$lang = _langState;
		},
		setqqState(state, _qqState) {
			state.qqState = _qqState;
		},
		setServerMessage(state, _setServerMessage) {
			state.serverMessage = _setServerMessage;
		},
		setServerCode(state, _serverCode) {
			state.serverCode = _serverCode;
		},
		/**
		 * @param {string} codeAudit_md5_key  --- 上传文件成功的md5 key
		 */
		set_codeAudit_md5_key(state, codeAudit_md5_key) {
			state.codeAudit_md5_key = codeAudit_md5_key;
		},
		/**
		 * @param {string} codeAudit_file_name --- 上传文件成功的md5 key
		 */
		set_codeAudit_file_name(state, codeAudit_file_name) {
			state.codeAudit_file_name = codeAudit_file_name;
		},
		/**
		 * @param {string} codeAudit_download_md5_key --- 审计完成下载md5
		 */
		set_codeAudit_download_md5_key(state, codeAudit_download_md5_key) {
			state.codeAudit_download_md5_key = codeAudit_download_md5_key;
		},
		//设置 Token
		setToken(state, _Token) {
			state.token = _Token;
		},
		//清除Token
		clearToken(state) {
			state.token = null;
		},
		//设置用户
		setUserInfo(state, _userInfo) {
			state.userInfo = _userInfo;
		},
		//清除用户
		clearUserInfo(state) {
			state.userInfo = null;
		},
		//登录状态清除
		clearMd5hasLogin(state) {
			state.md5hasLogin = false;
		},
	},
	actions: {
		//清除登录数据
		clearLoginInfo({ commit }) {
			commit('clearUserInfo');
			commit('clearToken');
		},
	},
	plugins: [
		//注册持久化存储插件
		createPersistedState(),
	],
});

export default store;
