<template>
	<div class="md5-plaintextUpload-page">
		<md5-header />
		<div class="plaintextUpload-box">
			<h2>{{ $t('lang.plaintextUpload.plaintextUpload') }}</h2>
			<div class="action-content">
				<el-input
					:placeholder="$t('lang.plaintextUpload.placeholder')"
					v-model="plaintextUpload_val"
					clearable
					@keyup.enter.native="clickUploadCleartext"
				>
					<template slot="append">
						<el-button
							slot="append"
							icon="el-icon-upload"
							@click="clickUploadCleartext"
							:loading="btnLoading"
						>
							{{ $t('lang.plaintextUpload.plaintextSubmit') }}</el-button
						>
					</template>
				</el-input>
			</div>
			<div class="tip-text">
				<el-alert
					:title="$t('lang.plaintextUpload.warningText')"
					:description="$t('lang.plaintextUpload.note')"
					type="info"
					:closable="false"
					show-icon
				></el-alert>
			</div>
		</div>
		<md5-footer />
	</div>
</template>
uploadCleartext
<script>
import md5Header from './../../components/Header';
import md5Footer from './../../components/Footer';
import { uploadCleartext } from '@/api/md5';
export default {
	name: 'md5-plaintextUpload',
	components: {
		md5Header,
		md5Footer,
	},
	data() {
		return {
			plaintextUpload_val: '',
			btnLoading: false,
		};
	},
	methods: {
		//明文上传
		clickUploadCleartext() {
			if (!this.plaintextUpload_val) {
				return this.$message.error(this.$t('lang.plaintextUpload.error'));
			}
			this.btnLoading = true;
			let postObj = {
				text: this.plaintextUpload_val,
			};
			uploadCleartext(postObj)
				.then((res) => {
					if (res && res.code == 200) {
						this.$message.success(this.$t('lang.plaintextUpload.success'));
						this.plaintextUpload_val = '';
					}
					this.btnLoading = false;
				})
				.catch(() => {
					this.btnLoading = false;
				});
		},
	},
	mounted() {
		console.log(this.$store.serverMessage);
	},
};
</script>

<style lang="scss" scoped>
.md5-plaintextUpload-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	.plaintextUpload-box {
		flex: 1;
		padding-top: 70px;
		width: 1208px;
		margin: 0 auto;
		transition: all 0.5s;
		@media screen and (max-width: 992px) {
			width: 800px;
		}
		@media screen and (max-width: 768px) {
			width: 96%;
		}

		h2 {
			width: 100%;
			font-size: 30px;
			font-weight: bold;
			color: #333333;
			line-height: 36px;
			text-align: center;
		}
		.tip-text {
			width: 100%;
			margin: 10px auto 0;
			@media screen and (max-width: 992px) {
				width: 90%;
			}
			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}
		::v-deep .el-alert__icon.is-big {
			color: #44a9fe;
		}
		::v-deep .el-alert--info.is-light {
			height: 80px;
			background: rgba(255, 255, 255, 0.6);
		}
		::v-deep .el-alert__title.is-bold {
			color: #000;
			font-weight: 500;
		}
		::v-deep .el-button {
			height: 56px !important;
		}
		::v-deep .el-input-group__append {
			height: 56px !important;
		}
		::v-deep .el-input__inner {
			padding-top: 15px;
		}
		.action-content {
			width: 100%;
			height: 56px;
			margin: 40px auto 0;
			background: #fff;
			box-shadow: 0px 0px 8px 0px rgba(102, 183, 251, 0.15);

			// box-shadow: 0 1px 8px 0 #2318151a;
			border-radius: 6px;
			@media screen and (max-width: 992px) {
				width: 90%;
			}
			@media screen and (max-width: 768px) {
				width: 100%;
			}
			/deep/ .el-button {
				background-color: #44a9fe !important;
				border-radius: 0px 6px 6px 0px !important;
				color: #ffffff !important;
				border-color: #44a9fe !important;
				height: 40px;
			}
		}
	}
}
</style>
