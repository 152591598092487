<!--cmd5注册页-->
<template>
	<div class="cmd5-register">
		<!--登录页内容区-->
		<div class="login-content">
			<div class="desc-content">
				<div class="logo-img">
					<img
						src="../assets/img/logo.png"
						alt="LOGO"
						style="width: 20.125rem; height: 3.5625rem"
					/>
				</div>
			</div>
			<div class="back-box">
				<el-link
					@click="$router.push('/')"
					class="right-backindex"
					:underline="false"
					><img src="../assets/img/login-icon/jiantou.png" alt="" />{{
						$t('lang.changePsw.gohomg')
					}}</el-link
				>
				<div class="login-tiptext">
					<div class="left-welcome">{{ $t('lang.registerType') }}</div>
				</div>
				<!--表单区域-->
				<div class="login-form">
					<!--邮箱-->
					<div
						class="form-item"
						:class="errTipObj.username.status ? 'err-input-border' : ''"
					>
						<div class="left-icon">
							<img
								src="../assets/img/login-icon/zhanghao.png"
								:alt="$t('lang.forgotPwd.email')"
							/>
							<span>{{ $t('lang.register.email') }}</span>
						</div>
						<div class="right-input">
							<el-input
								:placeholder="$t('lang.register.emailPlaceholder')"
								v-model.trim="postObj.username"
								@focus="
									() => {
										errTipObj.username.status = false;
									}
								"
								@keyup.enter="GoRegister"
							></el-input>
						</div>
						<div class="err-tip" v-show="errTipObj.username.status">
							{{ errTipObj.username.errTiptext }}
						</div>
					</div>
					<!--密码-->
					<div
						class="form-item"
						:class="errTipObj.password.status ? 'err-input-border' : ''"
					>
						<div class="left-icon">
							<img
								src="../assets/img/login-icon/mima1.png"
								:alt="$t('lang.forgotPwd.password')"
							/>
							<span>{{ $t('lang.register.password') }}</span>
						</div>
						<div class="right-input">
							<el-input
								type="password"
								:placeholder="$t('lang.register.pwdPlaceholder')"
								v-model.trim="postObj.password"
								@keyup.enter="GoRegister"
								show-password
								@focus="
									() => {
										errTipObj.password.status = false;
									}
								"
							></el-input>
						</div>
						<div class="err-tip" v-show="errTipObj.password.status">
							{{ errTipObj.password.errTiptext }}
						</div>
					</div>
					<!-- 确认密码 -->
					<div
						class="form-item"
						:class="errTipObj.againPassword.status ? 'err-input-border' : ''"
					>
						<div class="left-icon">
							<img
								src="../assets/img/login-icon/mima1.png"
								:alt="$t('lang.forgotPwd.password')"
							/>
							<span>{{ $t('lang.register.againPassword') }}</span>
						</div>
						<div class="right-input">
							<el-input
								type="password"
								:placeholder="$t('lang.register.pwdPlaceholder')"
								v-model.trim="checkPassword"
								@keyup.enter="GoRegister"
								show-password
								@focus="
									() => {
										errTipObj.againPassword.status = false;
									}
								"
							></el-input>
						</div>
						<div class="err-tip" v-show="errTipObj.againPassword.status">
							{{ errTipObj.againPassword.errTiptext }}
						</div>
					</div>
					<!--验证码-->
					<div class="verifycode-item">
						<div
							class="code-input"
							:class="errTipObj.verifyCode.status ? 'err-input-border' : ''"
						>
							<div class="left-icon">
								<img
									src="../assets/img/login-icon/yanzhengma.png"
									:alt="$t('lang.forgotPwd.captcha')"
								/>
								<span>{{ $t('lang.register.captcha') }}</span>
							</div>
							<div class="right-input">
								<el-input
									:placeholder="$t('lang.register.captchaPlaceholder')"
									v-model.trim="postObj.verifyCode"
									@focus="
										() => {
											errTipObj.verifyCode.status = false;
										}
									"
									@keyup.enter="GoRegister"
								></el-input>
							</div>
						</div>
						<div class="code-img">
							<span class="get-text" @click="sendCode" v-show="!pastShow">{{
								$t('lang.register.getCaptcha')
							}}</span>
							<span class="send-text" v-show="pastShow"
								>{{ $t('lang.register.sent') }}({{ timerCount }}s)</span
							>
							<!-- <span class="send-text">再次发送验证码</span> -->
						</div>
					</div>
					<!--注册按钮-->
					<div class="login-btn">
						<el-button
							type="primary"
							class="cmd5-registerbtn"
							@click="GoRegister"
							>{{ $t('lang.register.register') }}</el-button
						>
					</div>
					<!--提示去注册-->
					<div class="tip-register">
						<el-link
							@click="$router.push('/login')"
							class="register-link"
							:underline="false"
							>{{ $t('lang.register.goLogin') }}</el-link
						>
					</div>
				</div>
			</div>
		</div>
		<!-- 页脚组件 -->
		<Footer />
	</div>
</template>

<script>
// 测试打包
import Footer from '../components/Footer';
import { register, genCode } from './../api/register';
export default {
	name: 'cmd5-register',
	layout: 'page',
	components: {
		Footer,
	},
	data() {
		return {
			verifyCode: '', //验证码
			postObj: {
				username: '',
				password: '',
				verifyCode: '', //验证码
				shareId: '', //分享的id
			},

			errTipObj: {
				username: {
					status: false,
					errTiptext: '',
				},
				password: {
					status: false,
					errTiptext: '',
				},
				againPassword: {
					status: false,
					errTiptext: '',
				},
				verifyCode: {
					status: false,
				},
			},
			checkPassword: '',
		};
	},
	methods: {
		//点击注册
		GoRegister() {
			const IsSubmit = this.checkForm(); //状态
			if (!IsSubmit) {
				return false;
			}
			const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			if (!emailreg.test(this.postObj.username)) {
				this.errTipObj.username.status = true;
				this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
				return false;
			}
			if (!this.postObj.verifyCode) {
				this.errTipObj.verifyCode.status = true;
				this.$message.error(this.$t('lang.register.captchaPlaceholder'));
				return false;
			}

			// 执行查询url地址 是否包含有 分享id
			this.postObj.shareId = this.GetQueryString('shareId');
			// this.$t('lang.register.msg1')
			if (IsSubmit) {
				register(this.postObj).then((res) => {
					if (res && res.code == 400) {
						this.pastShow = false;
						clearInterval(this.timer);
						return false;
					}
					//成功注册
					this.$router.push({
						path: '/login',
					});
					this.$message.success({
						title: this.$t('lang.prompt'),
						message: this.$t('lang.register.msg7'),
						duration: 1000,
						onClose: () => {},
					});
				});
			}
		},

		// 获取邮箱的验证码 const TIME_COUNT = 60;
		sendCode() {
			if (!this.checkForm()) return;
			const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			if (!emailreg.test(this.postObj.username)) {
				this.errTipObj.username.status = true;
				this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
				return false;
			}
			// 倒计时60s
			const TIME_COUNT = 60;
			if (!this.timer) {
				this.timerCount = TIME_COUNT;
				//计时器
				this.pastShow = true;
				this.timer = setInterval(() => {
					if (this.timerCount > 0 && this.timerCount <= TIME_COUNT) {
						this.timerCount--;
					} else {
						this.pastShow = false;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			}

			// 发送验证码,数据对接。
			genCode({
				email: this.postObj.username,
			})
				.then((res) => {
					if (res && res.code == 200) {
						this.$message.success({
							title: this.$t('lang.prompt'),
							message: this.$t('lang.register.msg2'),
							showClose: false,
							duration: 1000,
						});
					} else {
						this.pastShow = false;
						clearInterval(this.timer);
					}
				})
				.catch(() => {
					this.pastShow = false;
					clearInterval(this.timer);
				});
		},
		// 检查提交数据
		checkForm() {
			if (!this.postObj.username) {
				//判断邮箱
				this.errTipObj.username.status = true;
				this.errTipObj.username.errTiptext = this.$t('lang.register.msg3');
				return false;
			}
			if (!this.postObj.password) {
				//判断密码
				this.errTipObj.password.status = true;
				this.errTipObj.password.errTiptext = this.$t('lang.register.msg4');
				return false;
			}
			if (!this.checkPassword) {
				//判断确认密码
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t('lang.register.msg5');
				return false;
			}
			if (this.postObj.password !== this.checkPassword) {
				//判断 确认密码和密码 一致
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t('lang.register.msg6');
				return false;
			}
			return true;
		},
	},
};
</script>

<style lang="scss">
/***固定定位的方式，展示登录页组件 ***/
.cmd5-register {
	background-image: url(../assets/img/cmd-index-bg.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-attachment: fixed;
	position: absolute;
	height: 120vh;
	width: 100%;

	.err-tip {
		font-size: 0.75rem;
		font-weight: 400;
		color: #ff0000;
		position: absolute;
		left: 0;
		top: 3.4375rem;
		transform: translateY(-50%);
		width: 100%;
	}

	.err-input-border {
		border: 0.0625rem solid #ff0000;
	}
	.back-box {
		background: #ffffff;
		padding-bottom: 2.75rem;
		border-radius: 0.375rem;
	}
	.login-content {
		width: 28.125rem;
		margin: 0 auto;
		padding-top: 8rem;
		min-height: calc(100vh - 6.5625rem);

		.logo-img {
			text-align: center;
			margin-bottom: 0.9375rem;
		}

		.title {
			text-align: center;
			font-size: 1rem;
			font-weight: 400;
			color: #333333;
		}

		.login-tiptext {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 0;
			margin-bottom: 2.5rem;
			border-bottom: 0.125rem solid #eee;
			height: 2.8125rem;
			width: 100%;
			text-align: center;
			margin-bottom: 0.75rem;
			.left-welcome {
				font-size: 1.5rem;
				font-weight: bold;
				color: #333333;
				margin-bottom: 0.75rem;
			}
		}
		.right-backindex {
			color: #44a9fe;
			position: relative;
			top: 1.5rem;
			left: 20.6875rem;
			.el-link--inner {
				display: flex;
				align-items: center;
				img {
					width: 1.125rem;
					height: 0.75rem;
					object-fit: cover;
					margin-right: 0.25rem;
				}
			}
		}

		.login-form {
			margin-top: 3.125rem;
			.form-item {
				width: 22.5rem;
				height: 2.875rem;
				background: #ffffff;
				box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.15);
				border-radius: 0.375rem;
				margin: 0 auto 1.5625rem;
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.left-icon {
					width: 9rem;
					height: 100%;
					display: flex;
					align-items: center;
					padding: 0rem 0 0 1rem;

					span {
						font-size: 0.875rem;
						font-weight: bold;
						color: #333333;
						line-height: 2.875rem;
						display: inline-block;
						margin-left: 0.625rem;
					}
					img {
						width: 1.125rem;
						height: auto;
						object-fit: cover;
					}
				}

				.right-input {
					width: calc(100% - 5.25rem);

					input {
						border: 0;
						outline: 0;
					}

					input:focus {
						box-shadow: none;
					}
				}
			}
		}
		::v-deep .el-input__inner {
			padding: 0 !important;
		}
		.verifycode-item {
			margin: 0 auto 2.1875rem;
			width: 22.5rem;
			height: 2.875rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;

			.code-input {
				width: 14.875rem;
				height: 2.875rem;
				background: #ffffff;
				box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.15);
				border-radius: 0.375rem;
				display: flex;
				justify-content: space-between;
				align-items: center;

				input {
					border: 0;
					outline: 0;
					padding: 6px 12px 6px 0;
				}

				input:focus {
					box-shadow: none;
				}
			}

			.code-img {
				width: 7.0625rem;
				height: 2.875rem;
				background: #ffffff;
				box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.15);
				border-radius: 0.375rem;
				overflow: hidden;
				line-height: 2.875rem;
				text-align: center;
				cursor: pointer;

				.get-text {
					font-size: 0.875rem;
					font-weight: bold;
				}

				.send-text {
					font-size: 0.875rem;
					font-weight: bold;
					color: #ccc;
				}
			}
		}
		.left-icon {
			width: 9rem;
			height: 100%;
			display: flex;
			// justify-content: space-between;
			align-items: center;
			padding: 0rem 0 0 1rem;

			span {
				font-size: 0.875rem;
				font-weight: bold;
				color: #333333;
				line-height: 2.875rem;
				display: inline-block;
				margin-left: 0.625rem;
			}
			img {
				width: 1.125rem;
				height: auto;
				object-fit: cover;
			}
		}
		.login-btn {
			width: 22.5rem;
			height: 2.875rem;
			box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.15);
			border-radius: 0.375rem;
			margin: auto;

			.cmd5-registerbtn {
				height: 100%;
				font-size: 1.125rem;
				width: 100%;
				border-radius: 0.375rem;
			}
		}

		.tip-register {
			margin: 1.25rem auto 1.25rem;
			width: 22.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.register-link {
				font-size: 0.875rem;
				font-weight: 400;
				color: #333333;
			}

			.register-link:hover {
				text-decoration: none;
				color: #44a9fe;
			}

			.checkbox {
				-moz-user-select: none;
				/* Firefox私有属性 */
				-webkit-user-select: none;
				/* WebKit内核私有属性 */
				-ms-user-select: none;
				/* IE私有属性(IE10及以后) */
				-khtml-user-select: none;
				/* KHTML内核私有属性 */
				-o-user-select: none;
				/* Opera私有属性 */
				user-select: none;
				/* CSS3属性 */
				cursor: pointer;
			}
		}
	}
}
</style>
