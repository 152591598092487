var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md5-desc"},[_c('div',{staticClass:"banner-bg"},[_c('md5-header'),_vm._m(0)],1),_c('div',{staticClass:"white-container"},[_c('div',[_c('div',{staticClass:"md5-contain article-box"},[_vm._m(1),_c('div',{staticClass:"article-item"},[_vm._m(2),_c('div',{staticClass:"data-centet"},[_c('div',{staticClass:"table-shadow"},[_c('comdTable',{attrs:{"columns":_vm.fields_md5,"data":_vm.items_md5}})],1)])]),_c('div',{staticClass:"article-item"},[_vm._m(3),_c('div',{staticClass:"data-centet"},[_c('div',{staticClass:"table-shadow"},[_c('comdTable',{attrs:{"columns":_vm.fields_type,"data":_vm.items_type}}),_vm._m(4)],1)])]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])]),_c('md5-footer')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md5-contain desc-page"},[_c('div',{staticClass:"top-description-content"},[_c('div',{staticClass:"description-flex"},[_c('div',{staticClass:"description-title"},[_vm._v("查询MD5")]),_c('div',{staticClass:"sprit"},[_vm._v("/")]),_c('div',{staticClass:"second-title"},[_c('p',[_vm._v("反向查询")]),_c('div',{staticClass:"blue-bgcolor"})])]),_c('div',{staticClass:"depict-text"},[_vm._v("针对md5等全球通用公开的加密算法进行反向查询")]),_c('div',{staticClass:"text-border-cot"},[_c('div',{staticClass:"text-border"},[_c('p',[_vm._v("建立了密文对应查询数据库，很多复杂密文只有本站才可查询，支持多种算法，实时查询记录超过24万亿条，共占用160T硬盘，成功率95%以上。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-item"},[_c('div',{staticClass:"title"},[_vm._v(" MD5 "),_c('h2',[_vm._v("实时查询解密范围")])]),_c('div',{staticClass:"data-centet"},[_c('div',{staticClass:"range-con"},[_c('p',[_vm._v("山东大学王小云教授发现了md5的碰撞原理震惊了世界，本站就是专门针对全球通用的md5等算法进行反向解密，实时查询拥有全世界最大的数据库，实测解密成功率在95%以上，有的用户已经超过了98%。目前世界上同类网站越来越多，但是我们一直保持着遥遥领先的优势。即使千万条以上的数据，我们一个小时就能解密到90%以上的水平，其余绝大部分时间都是为了提高哪怕一个百分点的成功率。本站帮您最大限度的恢复数据价值。")]),_c('p',[_vm._v("本站还支持dz、vb、mssql等实时查询和实时批量解密，此类带salt的加密类型，由于算法的特殊性，一般方法无法建库，本站采用独特技术，在保证最高成功率的前提下，实现了实时批量解密，这在全球都是绝无仅有的，2010年正式上线以来，至今没有其它任何一家真正实现了此功能。")]),_c('p',[_vm._v("密文破解早已经变成了资金密集型和技术密集型的行业，本站在全球的领先优势持续扩大，在美国，在俄罗斯，在中国，行业内无人不识cmd5。有些密文只有本站可以破解，除此以外别无他法，如果本站不能破出，你就没有任何办法。")]),_c('p',[_vm._v("国内从来不缺小偷和小人，本站被大量抄袭，大家可任意测试，任意对比，本站的解密速度、解密成功率是无与伦比的。此类网站完全用技术说话，完全用数据说话，但小白太多，新用户不知道我们的实力。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_vm._v(" MD5 "),_c('h2',[_vm._v("MD5")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_vm._v(" MD5 "),_c('h2',[_vm._v("本站支持的密文类型及格式说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"attention"},[_vm._v(" 注： "),_c('br'),_vm._v("1）如果你不不知道密文类型，在首页查询时，密文类型选择\"自动\"即可，将尝试所有的密文类型，多试几条，解密成功提示真正的密文类型，除此以外则无法从一个32位等字符串判断密文类型。 "),_c('br'),_vm._v("2）除了按以上直观方法判断密文类型，再无其它办法，请不要用任何方式咨询我们关于密文类型的问题。 "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-item"},[_c('div',{staticClass:"title"},[_vm._v(" MD5 "),_c('h2',[_vm._v("解密举例")])]),_c('div',{staticClass:"data-centet"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/demo.png"),"alt":"举例"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-item"},[_c('div',{staticClass:"title"},[_vm._v(" MD5 "),_c('h2',[_vm._v("关于新的密文类型/加密算法")])]),_c('div',{staticClass:"data-centet"},[_vm._v(" 你们不是很牛吗,为什么我这个不能解密？不是骗子吧！\" "),_c('br'),_vm._v("本站只支持常用的加密算法，对于新的算法加密的密文，是无法解密的。更不可能根据无法查询的密文推断加密算法。 "),_c('br'),_vm._v("如果你知道新的加密算法，请根据网站底部的email和我们联系。需详细描述算法内容，最好是附上源码。除此以外，请举例说明，例如123456加密后的密文是什么。 "),_c('br'),_vm._v("只要加密算法明确，一般就可以解密。 "),_c('br'),_vm._v("本站可以在一天内增加新的类型。 "),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-item"},[_c('div',{staticClass:"title"},[_vm._v(" MD5 "),_c('h2',[_vm._v("独有数据说明")])]),_c('div',{staticClass:"data-centet"},[_c('p',[_vm._v("本站的数据量比世界上任何其它网站都超过100倍以上，举个很简单的例子，任何网站很难收录到8位小写字母，而本站多个类型都已经收录8位以上小写字母+数字。根据排列组合原理，每增加一位，总数据量就是几何级别的增加。也就是说，本站数据95%以上都是全球独有的。")]),_c('p',[_vm._v("除此以外，本站筹建了独立服务器，收录了一批极为冷僻的数据，这些数据命中的几率非常低，因此相对成本要高出很多。但仍然按普通的计费。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-item"},[_c('div',{staticClass:"title"},[_vm._v(" MD5 "),_c('h2',[_vm._v("后台分布式解密")])]),_c('div',{staticClass:"data-centet"},[_vm._v(" 无论是在首页查询，或者通过在线网页或软件进行批量解密，只要是非salt类型查询失败的，将立即自动进入后台分布式云解密。用户只需过一段时间再次查询或再次进行批量解密即可。如果是登录后查询，则破解成功将自动发送邮件通知。 "),_c('br'),_vm._v("由于本站实时解密成功率已经相当高，实际上后台分布式解密的成功可能性是相当低的。 "),_c('br')])])
}]

export { render, staticRenderFns }