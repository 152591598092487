<template>
<!-- 微信充值 dialog -->
<div class="wechatpayup-dialog">
    <el-dialog :visible="wechatpayupDialogVisible" :show-close="false" @close="cancelWechatpayup">
        <div slot="title">
            <!-- 关闭图标 -->
            <div class="close-icon">
                <i class="iconfont icon-guanbi" @click="cancelWechatpayup"></i>
                <div class="black-line"></div>
            </div>
            <div class="userinfo">
                <!-- 左侧用户信息 -->
                <div class="left-box">
                    <el-avatar class="md5-avatar" :src="require('../../assets/img/user.png')"></el-avatar>
                    <div>
                        <span class="text">充值账号：</span>
                        <span class="username">{{userinfo.email}}</span>
                    </div>
                </div>
                <!-- 右侧积分余额 -->
                <div class="right-box">
                    <div class="icon">
                        <i class="iconfont icon-yue2" style="font-size:32px;color:#44a9fe"></i>
                    </div>
                    <div class="current-total">
                        <span class="text">当前积分：</span>
                        <span class="total blue-color">{{userinfo.points}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 主体部分 -->
        <div class="content">
            <!-- 充值部分 -->
            <div class="recharge-box">
                <ul>
                    <li v-for="(item,index) in rechargeBtnDataList" :key="index">
                        <div :class="['item', curActiveBtnIndex == index ? 'active' : '']" @click="clickAmountBtn(item,index)">
                            <div class="custom-input" v-if="item.customState && curActiveBtnIndex == index">
                                <el-input ref="customAmount" v-model.trim="inputAmount" placeholder="请输入金额" autofocus type=""></el-input>
                            </div>
                            <span :style="{'whiteSpace': 'nowrap','fontSize':item.customState ? '16px':'18px'}" v-else>{{ item.customState ? `自定义金额(${min}~${max})` : item.amount+" 元"}}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 充值的方式 -->
            <div class="recharge-type-box">
                <h3>充值的方式</h3>
                <div class="type-content">
                    <ul>
                        <li>
                            <div class="type-item active">
                                <i class="iconfont icon-weixinchongzhi"></i>
                                微信充值
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 获得积分 -->
            <div class="payMoney-box">
                <h3>获得积分</h3>
                <div class="tip-text">
                    <div>
                        <div class="left">
                            <countTo :startVal="0" :endVal="score" :duration="800" :decimals="0" class="money-color"></countTo> 积分
                        </div>
                        <div class="right">{{ratio}} 积分 = 1元</div>
                        <div class="right right-give">
                            <span>赠送</span>
                            <span> {{giveScore}} 积分</span>
                        </div>
                    </div>
                    <div>
                        <span>共 </span>
                        <span>{{score+giveScore}}</span>
                        <span> 积分</span>
                    </div>
                </div>
                <div class="bottom-line"></div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button class="confirmWechatpay-btn" type="primary" @click="confirmWechatpayup()" :loading="confirmWechatpayLoading">确认支付</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import countTo from 'vue-count-to';
import {
    md5Pay,
    rechargeTemplateData
} from "@/api/mycenter";
export default {
    name: 'wechatpayup',
    props: ['wechatpayupDialogVisible', 'userinfo'],
    components: {
        countTo
    },
    data() {
        return {
            confirmWechatpayLoading: false,
            curActiveBtnIndex: -1, //当前按钮下标
            curSelectRechargeData: null, //当前选中充值数据
            inputAmount: '', //输入金额              
            rechargeAmount: 0, //充值金额
            ratio: 0,
            max: 0,
            min: 0,
            rechargeTemplateData: null, //充值模版数据
            isCustomState: false
        }
    },
    watch: {
        inputAmount() {
            this.updateCurSelectByInput();
        }
    },
    computed: {
        //赠送积分
        giveScore() {
            let result = 0;
            if (this.rechargeTemplateData && this.curSelectRechargeData) {
                result = this.curSelectRechargeData["give_point"];
            }
            return result;
        },
        //积分
        score() {
            let result = 0;
            if (this.rechargeTemplateData && this.curSelectRechargeData) {
                result = this.ratio * this.rechargeAmount;
            }
            return result;
        },
        //充值按钮数据列表
        rechargeBtnDataList() {
            let list = [];
            if (this.rechargeTemplateData && this.rechargeTemplateData.item) {
                this.rechargeTemplateData.item.map((data, index) => {
                    if (index < 3) {
                        list.push(data);
                    }
                })
            }
            list.push({
                customState: true
            });
            return list;
        }
    },
    methods: {
        updateCurSelectByInput() {
            this.rechargeAmount = Number(this.inputAmount);
            //console.log("rechargeAmount updateCurSelectByInput>>",this.rechargeAmount)
            if (this.rechargeTemplateData) {
                if (this.inputAmount > 0) {
                    let list = this.rechargeTemplateData['item'];
                    let index = 0;
                    for (let i = 0; i < list.length; i++) {
                        if (this.inputAmount >= list[i]['amount']) {
                            index = i;
                        }
                    }
                    this.curSelectRechargeData = this.rechargeTemplateData['item'][index];
                } else {
                    this.curSelectRechargeData = null;
                }
            }
        },
        //取消
        cancelWechatpayup() {
            this.$emit('cancelClose');
        },
        //确定
        confirmWechatpayup() {
            let errMsg = null;
            if (this.rechargeAmount > this.max) {
                errMsg = `充值金额最大值不能超过${this.max}`;
            }
            if (this.rechargeAmount < this.min) {
                errMsg = `充值金额最小值不能低于${this.min}`;
            }
            if (errMsg) {
                this.$message.error(errMsg);
            } else {
                let postObj = {
                    amount: this.rechargeAmount
                }
                //全局loading
                this.$loading({
                    text: '正在支付',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.confirmWechatpayLoading = true;
                md5Pay(postObj).then(res => {
                    if (res) {
                        this.$emit('confirmWechatPay', res);
                    }
                    this.confirmWechatpayLoading = false;
                    this.$loading().close();
                }).catch(() => {
                    this.confirmWechatpayLoading = false;
                    this.$loading().close();
                })
                //this.$emit('cancelClose');
            }
        },
        // 点击充值按钮
        clickAmountBtn(item, index) {
            this.curActiveBtnIndex = index;
            let isCustomStateTemp = !!item["customState"];
            if (isCustomStateTemp) { //如果是自定义积分                
                this.$nextTick(() => {
                    this.$refs.customAmount[0].focus() //获得焦点
                })
                if (!this.isCustomState) { //由固定充值转向自定义时
                    this.updateCurSelectByInput();
                }
            } else {
                this.curSelectRechargeData = item;
                this.rechargeAmount = Number(this.curSelectRechargeData['amount']);
                // console.log("clickAmountBtn updateCurSelectByInput>>",this.rechargeAmount)
            }
            this.isCustomState = isCustomStateTemp;
        }
    },
    mounted() {
        this.confirmWechatpayLoading = true;
        rechargeTemplateData(null).then(res => {
            if (res) {
                this.rechargeTemplateData = res;
                this.ratio = this.rechargeTemplateData['ratio'];
                this.max = this.rechargeTemplateData['max'];
                this.min = this.rechargeTemplateData['min'];
                this.curSelectRechargeData = this.rechargeTemplateData["item"][0];
                this.clickAmountBtn(this.curSelectRechargeData,0);
            }
            this.confirmWechatpayLoading = false;
            this.$loading().close();
        }).catch(() => {
            this.confirmWechatpayLoading = false;
            this.$loading().close();
        })
    }

}
</script>

<style lang="scss">
.wechatpayup-dialog {
    position: relative;

    .el-dialog {
        border-radius: 6px 6px 0px 0px;
        max-width: 868px;
        width: 95%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0 !important;
    }

    .blue-color {
        color: #44a9fe;
    }

    //关闭图标
    .close-icon {
        position: absolute;
        top: -58px;
        right: 30px;

        .black-line {
            width: 2px;
            height: 28px;
            background: #ffffff;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        i {
            font-size: 30px;
            color: #ffffff;
            cursor: pointer;

            &:hover {
                color: #44a9fe;
            }
        }
    }

    //头部
    .el-dialog__header {
        height: 100px;
        background: #e1e1e1;
        border-radius: 6px 6px 0px 0px;
        padding: 0 20px;

        .userinfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 100px;

            .md5-avatar {
                width: 30px !important;
                height: 30px !important;
                margin-right: 10px !important;
            }

            .left-box {
                display: flex;
                align-items: center;

                .text {
                    color: #333333;
                    font-size: 14px;
                }

                .username {
                    font-size: 18px;
                    color: #44a9fe;
                }
            }

            .right-box {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 10px;
                }

                .current-total .total {
                    font-size: 18px;
                }
            }
        }
    }

    // 主体部分
    .content {
        .recharge-box ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            li {
                margin-right: 36px;
                margin-bottom: 20px;

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }

            .active {
                border: 1px solid #44a9fe !important;
                box-shadow: 0px 2px 11px 0px rgba(35, 24, 21, 0.11);
                font-weight: bold !important;
                color: #44a9fe !important;
            }

            .item {
                width: 180px;
                height: 80px;
                font-size: 18px;
                font-weight: 400;
                color: #666666;
                background: #ffffff;
                border: 1px solid #dddddd;
                border-radius: 6px;
                text-align: center;
                line-height: 80px;
                user-select: none;
                cursor: pointer;

                .custom-input {
                    height: 100%;

                    .el-input,
                    input {
                        width: 100%;
                        height: 96%;
                        border: 0;
                        font-size: 18px;
                        text-align: center;
                    }
                }
            }
        }

        // 充值方式
        .recharge-type-box {
            h3 {
                font-size: 14px;
                font-weight: bold;
                color: #333333;
                line-height: 1;
                margin-bottom: 20px;
            }

            .type-content ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .active {
                    border: 1px solid #44a9fe !important;
                }

                li {
                    margin-right: 36px;
                    margin-bottom: 20px;

                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                }

                .type-item {
                    width: 180px;
                    height: 80px;
                    font-size: 18px;
                    background: #ffffff;
                    border: 1px solid #44a9fe;
                    box-shadow: 0px 2px 11px 0px rgba(35, 24, 21, 0.11);
                    border-radius: 6px;
                    text-align: center;
                    line-height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    i {
                        margin-right: 10px;
                        font-size: 32px;
                        color: #09bb07;
                    }
                }
            }
        }

        // 应付金额
        .payMoney-box {
            h3 {
                font-size: 14px;
                font-weight: bold;
                color: #333333;
                margin-bottom: 19px;
            }

            .tip-text {
                display: flex;
                align-items: baseline;
                justify-content: space-between;

                div:first-child {
                    display: flex;
                    align-items: baseline;

                    .left {
                        color: #333333;
                        font-size: 14px;
                    }

                    .right {
                        color: #666666;
                        font-size: 14px;
                        margin-left: 30px;

                    }

                    .right-give {
                        color: #FF3600;
                        font-weight: bold;

                        span:first-child {
                            font-size: 24px;

                        }

                        span:nth-child(2) {
                            font-size: 14px;
                        }
                    }

                    .money-color {
                        color: #ff3600;
                        font-size: 26px;
                        line-height: 0;
                        font-weight: bold;
                    }
                }

                div:nth-child(2) {
                    span:nth-child(2) {
                        color: #FF3600;
                        font-size: 26px;
                        font-weight: bold;
                    }

                }

            }

            .bottom-line {
                border-bottom: 1px solid #dddddd;
                margin-top: 10px;
            }
        }
    }

    .el-dialog__body {
        padding: 30px 20px 20px 20px;
    }

    .el-dialog__footer {
        padding: 10px 20px 30px;
    }

    //脚部
    .dialog-footer {
        text-align: center;

        .confirmWechatpay-btn {
            width: 180px;
            height: 48px;
            background: #44a9fe;
            border-radius: 6px;
            font-size: 14px;
            font-weight: bold;

            &:focus,
            &:hover {
                background: #66b1ff;
                border-color: #66b1ff;
                color: #fff;
            }
        }
    }
}
</style>
