/**
 * @description MD5 公共方法的使用
 * @author wander
 * @date 2021-12-08 15:48  星期三  晴
 * 
 **/
import { mycenter } from "@/api/mycenter";
export default {
  data() {
    return {
      md5hasLogin: false, //用户是否登录了
      userInfoObj: {},
      isPositionNav: false,
      timerCount: '', // 60s倒计
      timer: null, // 计时器
      pastShow: false, // 显示已发送
      pastShowText: 0,
      //ScrollTopNum: 0, //滚动条距离顶部
    }
  },
  methods: {
    isScrollTopFunc() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      return scrollTop >= 65 ? true : false;
    },
    // 采用正则表达式获取地址栏参数
    // 获取分享接口 shareId  
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    },
    // 更新个人数据
    async updateUserInfo() {
      try {
        let userInfoData = await mycenter();
        if (userInfoData) {
          this.$store.commit('setUserInfo', userInfoData);
        }
      } catch (error) {
        console.log(error)
      }
    },
    getScrollTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      this.ScrollTopNum = scrollTop;
      if (scrollTop >= 65) {
        this.isPositionNav = true;
      } else {
        this.isPositionNav = false;
      }

    },
    /**
     * @description  公共调用 复制功能方法
     * 
     */
    copyData(data) {
      // 存储传递过来的数据
      let OrderNumber = data;
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      let newInput = document.createElement("textarea");
      // 讲存储的数据赋值给input的value值
      newInput.value = OrderNumber;
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput);
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select();
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand("Copy");
      // 清空输入框
      newInput.remove();
      // 下面是element的弹窗 不需要的自行删除就好
      this.$message({
        message: this.$t('lang.copyDataMsg'),
        type: "success",
      });
    },
    /**
     * @description 
     *  如果在页面在 1.个人中心页面刷新，登录失效了。要告知
     *  2.判断个人中心页面 是否登录失效，登录失效，必须要退出个人中心，去到登录页
     *  
     */
    async IsMyCenterLogin() {

      this.$route.path; //当前路由
      if (this.$route.path == '/myCenter') {
        try {
          let userInfoData = await mycenter();
          if (userInfoData == null) { //未登录需要登录
            this.$alert(this.$t('lang.loginFailure'), this.$t('lang.prompt'), {
              confirmButtonText: this.$t('lang.confirm'),
              callback: () => {

                // 跳转到登录页
                this.$router.push({
                  path: '/login'
                });
              }
            });
          }

          console.log('判断登录', userInfoData)
        } catch (error) {
          console.log(error)
          this.$message({
            type: 'error',
            message: error
          });

        }

      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.getScrollTop);
  },
  created() {

  }

}