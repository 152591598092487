<!--找回密码页面-->
<template>
	<div class="cmd5-forgotpwd">
		<div class="forgotpwd-content">
			<div class="desc-content">
				<div class="logo-img">
					<img
						src="../assets/img/logo.png"
						alt="LOGO"
						style="width: 322px; height: 57px"
					/>
				</div>
			</div>
			<div class="back-box">
				<el-link
					@click="$router.push('/')"
					class="right-backindex"
					:underline="false"
					><img src="../assets/img/login-icon/jiantou.png" alt="" />{{
						$t('lang.changePsw.gohomg')
					}}</el-link
				>
				<el-link
					@click="$router.push('/login')"
					class="left-backindex"
					:underline="false"
					><img src="../assets/img/login-icon/jiantou.png" alt="" />{{
						$t('lang.login.gobacklogin')
					}}</el-link
				>
				<div class="login-tiptext" v-show="!verifyMailStatus">
					<div class="left-welcome">
						{{ $t('lang.login.loginType2') }}
					</div>
				</div>
				<!-- 表单区域 -->
				<div class="login-form">
					<!-- 没验证邮箱的情况下 -->
					<div class="input-mailbox">
						<!--账号-->
						<div
							class="form-item"
							:class="[errTipObj.username.status ? 'err-input-border' : '']"
						>
							<div class="left-icon">
								<img src="../assets/img/login-icon/zhanghao.png" alt="邮箱" />
								<span>{{ $t('lang.register.email') }}</span>
							</div>
							<div class="right-input">
								<el-input
									:placeholder="$t('lang.login.emailPlaceholder')"
									v-model.trim="postObj.username"
									@focus="
										() => {
											errTipObj.username.status = false;
										}
									"
								></el-input>
							</div>
							<div class="err-tip" v-show="errTipObj.username.status">
								{{ errTipObj.username.errTiptext }}
							</div>
						</div>
						<!--密码-->
						<div
							class="form-item"
							:class="[errTipObj.password.status ? 'err-input-border' : '']"
						>
							<div class="left-icon">
								<img
									src="../assets/img/login-icon/mima1.png"
									:alt="$t('lang.login.email')"
								/>
								<span>{{ $t('lang.forgotPwd.password') }}</span>
							</div>
							<div class="right-input">
								<el-input
									type="password"
									:placeholder="$t('lang.login.pwdPlaceholder')"
									v-model.trim="postObj.password"
									show-password
									@focus="
										() => {
											errTipObj.password.status = false;
										}
									"
								></el-input>
							</div>
							<div class="err-tip" v-show="errTipObj.password.status">
								{{ errTipObj.password.errTiptext }}
							</div>
						</div>
						<!-- 确认密码 -->
						<div
							class="form-item"
							:class="[
								errTipObj.againPassword.status ? 'err-input-border' : '',
							]"
						>
							<div class="left-icon">
								<img
									src="../assets/img/login-icon/mima1.png"
									:alt="$t('lang.login.email')"
								/>
								<span>{{ $t('lang.forgotPwd.againPassword') }}</span>
							</div>
							<div class="right-input">
								<el-input
									type="password"
									:placeholder="$t('lang.forgotPwd.msg5')"
									v-model.trim="checkPassword"
									show-password
									@focus="
										() => {
											errTipObj.againPassword.status = false;
										}
									"
								></el-input>
							</div>
							<div class="err-tip" v-show="errTipObj.againPassword.status">
								{{ errTipObj.againPassword.errTiptext }}
							</div>
						</div>
						<!--验证码-->
						<div class="verifycode-item">
							<div
								class="code-input"
								:class="[errTipObj.verifyCode.status ? 'err-input-border' : '']"
							>
								<div class="left-icon">
									<img
										src="../assets/img/login-icon/zhanghao.png"
										:alt="$t('lang.forgotPwd.email')"
									/>
									<span>{{ $t('lang.forgotPwd.captcha') }}</span>
								</div>
								<div class="right-input">
									<el-input
										:placeholder="$t('lang.register.captchaPlaceholder')"
										v-model.trim="postObj.verifyCode"
										@focus="
											() => {
												errTipObj.verifyCode.status = false;
											}
										"
									></el-input>
								</div>
							</div>
							<div class="code-img">
								<span class="get-text" @click="sendCode" v-show="!pastShow">{{
									$t('lang.register.getCaptcha')
								}}</span>
								<span class="send-text" v-show="pastShow"
									>{{ $t('lang.forgotPwd.sent') }}({{ timerCount }}s)</span
								>
								<!-- <span class="send-text">再次发送验证码</span> -->
							</div>
						</div>

						<!--下一步-->
						<div class="login-btn">
							<el-button
								type="primary"
								class="cmd5-loginbtn"
								:loading="nextStepLoading"
								@click="nextStep"
								>{{ $t('lang.forgotPwd.nextStep') }}</el-button
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//import Footer from "../components/Footer";
import { forgotPwd, genCode } from './../api/pwd';
export default {
	name: 'cmd5-forgotpwd',
	components: {},
	data() {
		return {
			nextStepLoading: false,
			verifyMailStatus: false, // 验证邮箱通过状态
			checkPassword: '',
			postObj: {
				username: '',
				password: '',
				verifyCode: '',
			},
			activeblueState: false,
			// 错误状态的提示
			errTipObj: {
				username: {
					status: false,
					errTiptext: '',
				},
				password: {
					status: false,
					errTiptext: '',
				},
				againPassword: {
					status: false,
					errTiptext: '',
				},
				verifyCode: {
					status: false,
				},
			},
		};
	},
	methods: {
		//点击找回密码
		nextStep() {
			const IsSubmit = this.checkForm(); //状态
			if (IsSubmit) {
				if (!this.postObj.verifyCode) {
					this.$message.error(this.$t('lang.forgotPwd.captchaPlaceholder'));
					return false;
				}
				const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
				if (!emailreg.test(this.postObj.username)) {
					this.errTipObj.username.status = true;
					this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
					return false;
				}
				this.nextStepLoading = true;
				forgotPwd(this.postObj)
					.then((res) => {
						if (res && res.code == 200) {
							this.$message.success(this.$t('lang.forgotPwd.tologin'));
							this.$router.push('/login');
						} else {
							this.pastShow = false;
							clearInterval(this.timer);
							this.timer = null;
						}
						this.nextStepLoading = false;
					})
					.catch(() => {
						this.nextStepLoading = false;
						// 报错重置计时器
						this.pastShow = false;
						clearInterval(this.timer);
						this.timer = null;
					});
			}
		},
		sendCode() {
			const IsSubmit = this.checkForm(); //状态
			const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			if (!emailreg.test(this.postObj.username)) {
				this.errTipObj.username.status = true;
				this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
				return false;
			}
			if (IsSubmit) {
				genCode({
					email: this.postObj.username,
				})
					.then((res) => {
						console.log(res);
						if (res && res.code == 200) {
							// 倒计时60s
							const TIME_COUNT = 60;
							if (!this.timer) {
								this.timerCount = TIME_COUNT;
								//计时器
								this.pastShow = true;
								this.timer = setInterval(() => {
									if (this.timerCount > 0 && this.timerCount <= TIME_COUNT) {
										this.timerCount--;
									} else {
										this.pastShow = false;
										clearInterval(this.timer);
										this.timer = null;
									}
								}, 1000);
							}

							// 提示邮箱验证码发送成功
							this.$message.success({
								title: this.$t('lang.prompt'),
								message: this.$t('lang.forgotPwd.msg'),
								showClose: false,
								duration: 2000,
							});
						}
					})
					.catch((err) => {
						console.log(err);
						this.pastShow = false;
						clearInterval(this.timer);
						this.timer = null;
					});
			}
		},
		// 检查提交数据
		checkForm() {
			if (!this.postObj.username) {
				//this.$message.error('请输入邮箱账号!');
				this.errTipObj.username.status = true;
				this.errTipObj.username.errTiptext = this.$t('lang.forgotPwd.errEmail');

				return false;
			}
			if (!this.postObj.password) {
				//this.$message.error('请输入密码!');
				this.errTipObj.password.status = true;
				this.errTipObj.password.errTiptext = this.$t('lang.forgotPwd.errPwd');

				return false;
			}
			if (!this.checkPassword) {
				//this.$message.error('请输入确认密码!');
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t(
					'lang.forgotPwd.msg5'
				);
				return false;
			}
			if (this.postObj.password !== this.checkPassword) {
				this.errTipObj.againPassword.status = true;
				this.errTipObj.againPassword.errTiptext = this.$t(
					'lang.forgotPwd.msg6'
				);
				//this.$message.error('密码和确认密码不一致');
				return false;
			}

			return true;
		},
	},
};
</script>

<style lang="scss">
.cmd5-forgotpwd {
	background-image: url(../assets/img/cmd-index-bg.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-attachment: fixed;
	position: absolute;
	height: 100%;
	width: 100%;

	.active-blue {
		border: 1px solid #44a9fe !important;
	}

	.err-input-border {
		border: 1px solid #ff0000;
	}

	.pass-mailbox {
		.title {
			font-size: 24px !important;
			font-weight: bold !important;
			color: #333333 !important;
			text-align: center;
			margin-bottom: 20px;
		}

		.sent-text {
			text-align: center;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
		}

		.emails-tip {
			text-align: center;
			margin-bottom: 30px;

			.email {
				font-size: 18px;
				font-weight: bold;
				color: #333333;
			}
		}
	}

	.forgotpwd-content {
		width: 450px;
		margin: 0 auto;
		margin-top: 128px;

		.logo-img {
			text-align: center;
			margin-bottom: 47px;
		}

		.title {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.back-box {
			background: #ffffff;
			padding-bottom: 44px;
			border-radius: 6px;
		}

		.login-tiptext {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 0;
			margin-bottom: 40px;
			border-bottom: 2px solid #eee;
			height: 45px;

			.left-welcome {
				font-size: 24px;
				font-weight: bold;
				color: #333333;
				cursor: pointer;
				margin-bottom: 12px;
			}
		}
		.right-backindex,
		.left-backindex {
			color: #44a9fe;
			position: relative;
			top: 24px;
			left: 331px;
			.el-link--inner {
				display: flex;
				align-items: center;
				img {
					width: 18px;
					height: 12px;
					object-fit: cover;
					margin-right: 4px;
				}
			}
		}
		.left-backindex {
			left: -35px;
		}
		.login-form {
			margin-top: 50px;

			.form-item {
				width: 360px;
				height: 46px;
				background: #ffffff;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: 6px;
				margin: 0 auto 25px;
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.err-tip {
					font-size: 12px;
					font-weight: 400;
					color: #ff0000;
					position: absolute;
					left: 0;
					top: 55px;
					transform: translateY(-50%);
					width: 100%;
				}

				.left-icon {
					width: 144px;
					height: 100%;
					display: flex;
					// justify-content: space-between;
					align-items: center;
					padding: 0px 0 0 16px;

					span {
						font-size: 14px;
						font-weight: bold;
						color: #333333;
						line-height: 46px;
						display: inline-block;
						margin-left: 10px;
					}
					img {
						width: 18px;
						height: auto;
						object-fit: cover;
					}
				}

				.right-input {
					width: calc(100% - 84px);

					input {
						border: 0;
						outline: 0;
					}

					input:focus {
						box-shadow: none;
					}
				}
			}
		}

		.verifycode-item {
			margin: 0 auto 35px;
			width: 360px;
			height: 46px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;

			.code-input {
				width: 238px;
				height: 46px;
				background: #ffffff;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: 6px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.left-icon {
					width: 144px;
					height: 100%;
					display: flex;
					// justify-content: space-between;
					align-items: center;
					padding: 0px 0 0 16px;

					span {
						font-size: 14px;
						font-weight: bold;
						color: #333333;
						line-height: 46px;
						display: inline-block;
						margin-left: 10px;
					}
					img {
						width: 18px;
						height: auto;
						object-fit: cover;
					}
				}

				input {
					border: 0;
					outline: 0;
					padding: 0.375rem 0.75rem 0.375rem 0;
				}

				input:focus {
					box-shadow: none;
				}
			}

			.code-img {
				width: 113px;
				height: 46px;
				background: #ffffff;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: 6px;
				line-height: 46px;
				text-align: center;
				overflow: hidden;
				cursor: pointer;

				img {
					width: 103px;
					height: 35px;
					cursor: pointer;
				}
				.get-text {
					font-size: 14px;
					font-weight: bold;
				}

				.send-text {
					font-size: 14px;
					font-weight: bold;
					color: #ccc;
				}
			}
		}

		.login-btn {
			width: 360px;
			height: 46px;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			margin: auto;

			.cmd5-loginbtn {
				height: 100%;
				font-size: 18px;
				width: 100%;
				border-radius: 6px;
			}
		}

		.tip-register {
			margin: 20px auto 20px;
			width: 360px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.register-link {
				font-size: 14px;
				font-weight: 400;
				color: #333333;
			}

			.register-link:hover {
				text-decoration: none;
			}

			.checkbox {
				-moz-user-select: none;
				/* Firefox私有属性 */
				-webkit-user-select: none;
				/* WebKit内核私有属性 */
				-ms-user-select: none;
				/* IE私有属性(IE10及以后) */
				-khtml-user-select: none;
				/* KHTML内核私有属性 */
				-o-user-select: none;
				/* Opera私有属性 */
				user-select: none;
				/* CSS3属性 */
				cursor: pointer;
			}
		}
	}
}
</style>
