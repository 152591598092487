<template>
  <div class="privacyPolicy-page">
    <div class="header"></div>
    <div class="doc-container">
      <div class="doc-title-box">
        <h2>{{$t('lang.privacyPolicy.title')}}</h2>
      </div>
      <!-- 内容区域 -->
      <div class="doc-body">
        <h3>{{$t('lang.privacyPolicy.privacyPolicy')}}</h3>
        <!-- <p>MD5.cn（“我们”）非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《MD5.cn隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。</p>
        <h4>本政策将帮助您了解以下内容：</h4>
        <p>一、隐私保护原则二、我们收集的信息三、我们如何使用收集的信息四、我们如何使用cookie及相关技术五、您如何管理自己的信息六、我们可能向您发送的信息七、通知和修订</p>
        <p>一、隐私保护原则</p>
        <p>MD5.cn严格遵守法律法规、遵守消费者隐私法 (CCPA)、遵守通用数据保护条例GDPR，遵循以下隐私保护原则，为您提供更加安全、可靠的服务：</p>-->
        <p>{{ $t('lang.privacyPolicy.doc_title') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p1') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p2') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p3') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p4') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p5') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p6') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p7') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p8') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p9') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p10') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p11') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p12') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p13') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p14') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p15') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p16') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p17') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p18') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p19') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p20') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p21') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p22') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p23') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p24') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p25') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p26') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p27') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p28') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p29') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p30') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p31') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p32') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p33') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p34') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p35') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p36') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p37') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p38') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p39') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p40') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p41') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p42') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p43') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p44') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p45') }}</p>
        <p>{{ $t('lang.privacyPolicy.doc_p46') }}</p>
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p47') }}</p>        
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p48') }}</p>    
        <p>{{ $t('lang.privacyPolicy.doc_p49') }}</p>    
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p50') }}</p>    
        <p>{{ $t('lang.privacyPolicy.doc_p51') }}</p>            
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p52') }}</p>              
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p53') }}</p>      
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p54') }}</p>      
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p55') }}</p>      
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p56') }}</p>      
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p57') }}</p>      
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p58') }}</p>   
        <p></p>   
        <p>{{ $t('lang.privacyPolicy.doc_p59') }}</p>      
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p60') }}</p>   
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p61') }}</p>  
        <p>{{ $t('lang.privacyPolicy.doc_p62') }}</p> 
        <p>{{ $t('lang.privacyPolicy.doc_p63') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p64') }}</p> 
        <p>{{ $t('lang.privacyPolicy.doc_p65') }}</p> 
        <p>{{ $t('lang.privacyPolicy.doc_p66') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p67') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p68') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p69') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p70') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p71') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p72') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p73') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p74') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p75') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p76') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p77') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p78') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p79') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p80') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p81') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p82') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p83') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p84') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p85') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p86') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p87') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p88') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p89') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p90') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p91') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p92') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p93') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p94') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p95') }}</p> 
        <p></p>
        <p>{{ $t('lang.privacyPolicy.doc_p96') }}</p>       
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.privacyPolicy-page {
  width: 100%;
  min-height: 100%;
  position: relative;
  .header {
    height: 80px;
  }
  .doc-container {
    position: static;
    box-shadow: 0 1px 6px #ccc;
    background-color: #fff;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 20px;
    width: 850px;
    min-height: 750px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 8px;
    h2 {
      text-align: center;
    }
    .doc-title-box {
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 20px;
    }
    .doc-body {
      width: 96%;
      margin: 0 auto;
      padding-top: 10px;

      h3,
      h4 {
        margin-bottom: 15px;
      }
      p {
        color: #606266;
        margin-bottom: 15px;
      }
    }
  }
}
</style>