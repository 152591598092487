<!--工具大全页面-->
<template>
	<div class="md5-tools-page">
		<md5-header />
		<main class="md5-tools-box">
			<!-- 分类 -->
			<ul class="tools-category">
				<li v-for="(item, index) in category" :key="item.id">
					<a
						href="javascript:void(0)"
						@click="actionCategory(index, item.id)"
						:class="categoryActive == index ? 'active' : ''"
						>{{ item.name }}</a
					>
				</li>
				<li>
					<a :href="moreTools" target="_blank">
						{{ $t('lang.tool.moreTools') }}
					</a>
				</li>
			</ul>
			<!-- 列表 -->
			<div v-loading="listdataLoading">
				<ul class="tools-list-box">
					<li v-for="(item, index) in toolsListDataArr" :key="index">
						<a
							href="javascript:void(0)"
							class="tools-item"
							:title="item.name"
							@click="handleClick(item)"
						>
							<div
								class="head-top-bg"
								:style="'background:' + bgColorArr[index]"
							>
								<div class="tools-img">
									<img :src="item.icon" />
								</div>
								<span class="tools-name">{{ item.name }}</span>
							</div>
							<div class="tools-description" :title="item.description">
								<p class="text-overflow-3">{{ item.description }}</p>
							</div>
						</a>
					</li>
				</ul>
			</div>
			<!-- 分页 -->
			<div class="md5-laypage-box">
				<el-pagination
					background
					layout="prev, pager, next"
					:total="pogeObj.total"
					:current-page.sync="pogeObj.currentPage"
					@current-change="handleCurrentChange"
				></el-pagination>
			</div>
		</main>
		<md5-footer />
	</div>
</template>

<script>
import md5Header from './../../components/Header';
import md5Footer from './../../components/Footer';
import { get_tools_list } from '@/api/tools';
export default {
	name: 'index-tools-list',
	components: {
		md5Header,
		md5Footer,
	},
	data() {
		return {
			bgColorArr: [
				'rgb(66 154 255)',
				'rgb(77 175 255)',
				'rgb(0 172 208)',
				'rgb(84 214 136)',
				'rgb(43 171 240)',
				'rgb(50 113 236)',
				'rgb(100 140 250)',
				'rgb(0 186 173)',
				'rgb(77 175 255)',
			], //颜色arr
			category: [],
			categoryActive: 0,
			toolsListDataArr: [],
			listdataLoading: false,
			pogeObj: {
				total: 0,
				currentPage: 1,
			},
			category_id: 0,
		};
	},
	computed: {
		moreTools() {
			return window.location.origin + '/tools '; //跳转到新的工具网站
		},
	},
	methods: {
		/**
		 * @param {number} _index 分类的序号
		 * @param {string} _id    分类的id
		 */
		actionCategory(_index, _id) {
			this.categoryActive = _index;
			this.category_id = _id;
			this.getListData(_id);
		},
		/**
		 * @param {object} item 工具详情
		 */
		handleClick(item) {
			if (item.url) {
				this.$router.push('/');
			} else {
				this.$router.push({
					path: `tool/${item.component_name}`,
					query: { id: item.id },
				});
			}
		},
		/**
		 * @param {string} category_id 分类id
		 */
		getListData(_category_id = 0, _current_page = 1) {
			//发送的参数
			let postObj = {
				category_id: _category_id,
				page: _current_page,
			};
			this.listdataLoading = true;
			get_tools_list(postObj)
				.then((res) => {
					if (res) {
						this.category = res.category; //分类数据
						this.toolsListDataArr = res.list.data; //列表数据
						//分页的信息
						this.pogeObj.total = res.list.total;
						this.pogeObj.currentPage = res.list.current_page;
					}
					this.listdataLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.listdataLoading = false;
				});
		},
		//分页
		handleCurrentChange(_page) {
			this.getListData(this.category_id, _page);
		},
	},
	created() {
		this.getListData();
	},
};
</script>

<style lang="scss" scoped>
.md5-tools-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: url(../../assets/img/cmd-index-bg.png) no-repeat center;
	background-size: cover;
	background-attachment: fixed;

	.md5-tools-box {
		flex: 1;
		padding-top: 20px;
		width: 1208px;
		margin: 0 auto;
	}
	.tools-category {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		li {
			margin-right: 26px;
			margin-bottom: 20px;
			border: 0;
			&:nth-child(8n) {
				margin-right: 0;
			}
			a {
				display: block;
				height: 40px;
				background: #ffffff;
				border-radius: 6px;
				line-height: 40px;
				text-align: center;
				font-weight: 500;
				color: #000;
				transition: all 0.3s;
				padding: 0 33px;
				&:hover {
					font-weight: 500;
					color: rgba(68, 169, 254, 1);
				}
				&.active {
					font-weight: 500;
					color: rgba(68, 169, 254, 1);
				}
			}
		}
	}
	//列表
	.tools-list-box {
		display: flex;
		flex-wrap: wrap;
		min-height: 548px;
		li {
			margin-right: 20px;
			margin-bottom: 20px;
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
		.tools-item {
			display: block;
			width: 389px;
			height: 245px;
			background: #ffffff;
			transition: all 0.3s;
			animation-duration: 1s;
			animation-fill-mode: both;
			border-radius: 6px;
			&:hover {
				border: 2px solid #ffffff;
				box-shadow: 0px 3px 9px 1px #2217141a;
				animation-name: pulse;
			}

			.head-top-bg {
				height: 160px;
				border-radius: 6px 6px 0px 0px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.tools-img {
					width: 60px;
					height: 60px;
					border-radius: 50%;
					// 居中显示
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						width: 60px;
						height: 60px;
						object-fit: cover;
						border-radius: 50%;
					}
				}
				.tools-name {
					margin-top: 20px;
					font-size: 20px;
					font-weight: bold;
					color: #fefefe;
					line-height: 1;
				}
			}
			.tools-description {
				padding: 14px 21px 0 21px;
				p {
					font-size: 14px;
					font-weight: 400;
					color: rgb(106, 108, 116);
					line-height: 20px;
					word-break: break-word;
				}
			}
		}
	}
	//分页
	.md5-laypage-box {
		text-align: center;
		padding-top: 30px;
		padding-bottom: 20px;
	}
}

@keyframes pulse {
	0% {
		transform: scaleX(1);
	}

	50% {
		transform: scale3d(1.03, 1.03, 1.03);
	}

	to {
		transform: scaleX(1);
	}
}
</style>
