/**
 * @description 页面加载进度条的配置
 * @author   wander
 * @date     2021-11-16 13:43  星期二
 */
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import router from "../../router/router";

//进度条
NProgress.configure({
  easing: 'ease',  // 动画方式
  speed: 500,  // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})


// ----------------路由守卫的监听--------------
//  开始跳转
router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

//  跳转完成之后执行
router.afterEach(() => {
  //结束
  NProgress.done()
})