var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"register-dialog-box"},[_c('div',{staticClass:"login-form"},[_c('div',{staticClass:"form-item",class:_vm.errTipObj.username.status ? 'err-input-border' : ''},[_c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("../../assets/img/login-icon/zhanghao.png"),"alt":_vm.$t('lang.forgotPwd.email')}}),_c('span',[_vm._v(_vm._s(_vm.$t('lang.register.email')))])]),_c('div',{staticClass:"right-input"},[_c('el-input',{attrs:{"placeholder":_vm.$t('lang.register.emailPlaceholder')},on:{"focus":() => {
								_vm.errTipObj.username.status = false;
							},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.GoRegister.apply(null, arguments)}},model:{value:(_vm.postObj.username),callback:function ($$v) {_vm.$set(_vm.postObj, "username", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"postObj.username"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errTipObj.username.status),expression:"errTipObj.username.status"}],staticClass:"err-tip"},[_vm._v(" "+_vm._s(_vm.errTipObj.username.errTiptext)+" ")])]),_c('div',{staticClass:"form-item",class:_vm.errTipObj.password.status ? 'err-input-border' : ''},[_c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("../../assets/img/login-icon/mima1.png"),"alt":_vm.$t('lang.forgotPwd.password')}}),_c('span',[_vm._v(_vm._s(_vm.$t('lang.register.password')))])]),_c('div',{staticClass:"right-input"},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.$t('lang.register.pwdPlaceholder'),"show-password":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.GoRegister.apply(null, arguments)},"focus":() => {
								_vm.errTipObj.password.status = false;
							}},model:{value:(_vm.postObj.password),callback:function ($$v) {_vm.$set(_vm.postObj, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"postObj.password"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errTipObj.password.status),expression:"errTipObj.password.status"}],staticClass:"err-tip"},[_vm._v(" "+_vm._s(_vm.errTipObj.password.errTiptext)+" ")])]),_c('div',{staticClass:"form-item",class:_vm.errTipObj.againPassword.status ? 'err-input-border' : ''},[_c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("../../assets/img/login-icon/mima1.png"),"alt":_vm.$t('lang.forgotPwd.password')}}),_c('span',[_vm._v(_vm._s(_vm.$t('lang.register.againPassword')))])]),_c('div',{staticClass:"right-input"},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.$t('lang.register.pwdPlaceholder'),"show-password":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.GoRegister.apply(null, arguments)},"focus":() => {
								_vm.errTipObj.againPassword.status = false;
							}},model:{value:(_vm.checkPassword),callback:function ($$v) {_vm.checkPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"checkPassword"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errTipObj.againPassword.status),expression:"errTipObj.againPassword.status"}],staticClass:"err-tip"},[_vm._v(" "+_vm._s(_vm.errTipObj.againPassword.errTiptext)+" ")])]),_c('div',{staticClass:"verifycode-item"},[_c('div',{staticClass:"code-input",class:_vm.errTipObj.verifyCode.status ? 'err-input-border' : ''},[_c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("../../assets/img/login-icon/yanzhengma.png"),"alt":_vm.$t('lang.forgotPwd.captcha')}}),_c('span',[_vm._v(_vm._s(_vm.$t('lang.register.captcha')))])]),_c('div',{staticClass:"right-input"},[_c('el-input',{attrs:{"placeholder":_vm.$t('lang.register.captchaPlaceholder')},on:{"focus":() => {
									_vm.errTipObj.verifyCode.status = false;
								},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.GoRegister.apply(null, arguments)}},model:{value:(_vm.postObj.verifyCode),callback:function ($$v) {_vm.$set(_vm.postObj, "verifyCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"postObj.verifyCode"}})],1)]),_c('div',{staticClass:"code-img"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pastShow),expression:"!pastShow"}],staticClass:"get-text",on:{"click":_vm.sendCode}},[_vm._v(_vm._s(_vm.$t('lang.register.getCaptcha')))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.pastShow),expression:"pastShow"}],staticClass:"send-text"},[_vm._v(_vm._s(_vm.$t('lang.register.sent'))+"("+_vm._s(_vm.timerCount)+"s)")])])]),_c('div',{staticClass:"login-btn"},[_c('el-button',{staticClass:"cmd5-registerbtn",attrs:{"type":"primary"},on:{"click":_vm.GoRegister}},[_vm._v(_vm._s(_vm.$t('lang.register.register')))])],1),_c('div',{staticClass:"tip-register"},[_c('el-link',{staticClass:"register-link",attrs:{"underline":false},on:{"click":_vm.goLogin}},[_vm._v(_vm._s(_vm.$t('lang.register.goLogin')))])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }