/**
 * @description 工具大全模块的数据请求
 * @date  2022-04-21 14:25 星期四  阴
 */

import request from '@/utils/request';


//获取列表信息
export function get_tools_list(query) {
  return request({
    url: '/online_tools/list',
    method: 'get',
    params: query,
  });
}

// online_tools/detail
export function get_tools_detail(query) {
  return request({
    url: '/online_tools/detail',
    method: 'get',
    params: query,
  });
}