<!--
  ASCII编码解码 组件
-->
<template>
	<div class="md5-tools-page">
		<md5-header />
		<main class="md5-tools-box">
			<div class="ascll-component">
				<a
					href="javascript:history.back(-1)"
					rel="noopener noreferrer"
					class="goback"
					:title="$t('lang.gobackBack')"
				>
					<i class="el-icon-back"></i>
					{{ $t('lang.goback') }}
				</a>
				<h3 class="title">{{ h3 }}</h3>
				<el-row>
					<el-col :span="24" class="col">
						<el-input
							type="textarea"
							:placeholder="$t('lang.websocket.ascii')"
							v-model.trim="value"
							rows="12"
							:class="textareaState ? 'warn-active' : ''"
							@focus="textareaState = false"
						></el-input>
					</el-col>
					<el-col :span="24" class="col button">
						<div class="submit" @click="encrypt">
							<a href="javascript:void(0)">{{ $t('lang.websocket.code') }}</a>
						</div>
						<div class="submit" @click="decode">
							<a href="javascript:void(0)">{{ $t('lang.websocket.decode') }}</a>
						</div>
						<div class="submit" @click="copy()">
							<a href="javascript:void(0)">{{ $t('lang.copyResult') }}</a>
						</div>
						<div class="submit" @click="clear">
							<a href="javascript:void(0)">{{ $t('lang.clear') }}</a>
						</div>
					</el-col>
					<el-col :span="24" class="col result-textarea">
						<el-input
							type="textarea"
							:placeholder="$t('lang.getRes')"
							v-model.trim="result"
							rows="12"
							readonly
						></el-input>
					</el-col>
				</el-row>
			</div>
		</main>
		<md5-footer />
	</div>
</template>

<script>
import md5Header from './../../components/Header';
import md5Footer from './../../components/Footer';
import { get_tools_detail } from '@/api/tools';

export default {
	name: 'Ascll',
	components: {
		md5Header,
		md5Footer,
	},
	data() {
		return {
			activeName: 'morse',
			value: '',
			result: '',
			textareaState: false,
			h3: '',
		};
	},
	created() {
		this.getToolsDetail();

		this.test();
	},
	methods: {
		getToolsDetail() {
			get_tools_detail({ id: this.$route.query.id }).then((res) => {
				if (res) {
					this.h3 = res.name;
				}
			});
		},
		test() {
			console.log(this.$route.query.id);
		},
		clear() {
			this.value = '';
			this.result = '';
		},
		copy() {
			this.copyData(this.result);
			document.querySelector('.result-textarea .el-textarea__inner').select(); //复制 获得焦点
		},
		encrypt() {
			this.result = '';
			// console.log(JSON.stringify(this.value));
			if (!this.value) {
				this.textareaState = true;
				this.$message({
					message: this.$t('lang.websocket.error2'),
					type: 'error',
				});
			} else {
				for (const ch of this.value) {
					this.result += ch.charCodeAt() + ' ';
				}
			}
			// this.value = this.result
		},
		decode() {
			if (!this.value) {
				this.textareaState = true;
				this.$message({
					message: this.$t('lang.websocket.error2'),
					type: 'error',
				});
			} else {
				const arr = this.value.split(' ');
				this.result = '';
				console.log(arr);
				arr.forEach((item) => {
					if (item !== '') {
						const ca = String.fromCharCode(item);
						if (ca === '\u0000') {
							this.result += item;
						} else {
							this.result += ca;
						}
					}
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.md5-tools-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: url(../../assets/img/cmd-index-bg.png) no-repeat center;
	background-size: cover;
	background-attachment: fixed;
	.md5-tools-box {
		flex: 1;
		padding-top: 30px;
		width: 1208px;
		margin: 0 auto;
	}
	.title {
		width: 100%;
		height: 43px;
		font-size: 30px;
		font-weight: bold;
		color: #333333;
		line-height: 36px;
		text-align: center;
	}
	.goback {
		float: right;
		font-size: 16px;
		font-weight: 400;
		color: rgba(68, 169, 254, 1);
		cursor: pointer;
		display: flex;
		align-items: center;
		i {
			font-size: 18px;
		}
		&:hover {
			color: #37bef0;
		}
	}

	.ascll-component {
		width: 100%;
		height: auto;
		background: #ffffff;
		box-shadow: 0px 0px 20px 0px rgba(68, 169, 254, 0.15);

		padding: 35px;
		border-radius: 6px;
		.el-tabs__header {
			margin: 0;
		}
		.col {
			margin-top: 30px;
			color: rgba(128, 128, 128, 1);
		}
		.button {
			display: flex;
			justify-content: center;
			.submit {
				width: 80px;
				height: 36px;
				border-radius: 6px;
				text-align: center;
				padding-top: 9px;
				background: linear-gradient(
					0deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(240, 248, 255, 1) 100%
				);
				border: 1px solid rgba(255, 255, 255, 1);
				margin-right: 20px;
				box-shadow: 0px 0px 10px 0px rgba(79, 94, 106, 0.15);
			}
			.submit a {
				color: rgba(22, 148, 250, 1);
			}
		}
		.warn-active {
			/deep/ .el-textarea__inner {
				border: 1px solid #f56c6c;
			}
		}
	}
}
::v-deep .el-textarea__inner {
	resize: none;
	font-family: '';
	padding: 20px 25px;
	border-radius: 6px !important;
	border: 1px solid rgba(229, 229, 229, 1);
}
@keyframes pulse {
	0% {
		transform: scaleX(1);
	}

	50% {
		transform: scale3d(1.03, 1.03, 1.03);
	}

	to {
		transform: scaleX(1);
	}
}
</style>
