import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/main.scss';
import './assets/js/NProgress'; //进度条配置
import axios from 'axios';
import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies';
import md5Mixin from '@/utils/md5Mixin'; //公共混入 (mixin) ，复用
import store from '@/store/index.js'; //使用Vuex
//加载MD5 加密类型方法 16位 32位
import './utils/md5_encryption';

Vue.use(VueCookies);
Vue.use(VueI18n);
axios.defaults.withCredentials = true;

// 自动设置多语言 默认选择浏览器系统语言
//const navLang = navigator.language;    //判断当前浏览器使用的语言

//const localLang= (navLang === 'zh-CN' || navLang === 'en-US') ? navLang : false;
let lang = localStorage.getItem('language') || 'zh-CN';

console.log('navLang', lang);
const i18n = new VueI18n({
	locale: 'zh-CN', // 语言标识
	//this.$i18n.locale // 通过切换locale的值来实现语言切换
	messages: {
		'zh-CN': require('./lang/zh'), // 中文语言包
		'en-US': require('./lang/en'), // 英文语言包
		'ru-RU': require('./lang/ru'), // 俄语语言包
	},
});

// localStorage.setItem('language',lang);    //最后缓存当前使用的语言
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.prototype.$store = store;
Vue.config.productionTip = false;

Vue.mixin(md5Mixin);
Vue.prototype.$EventBus = new Vue(); //注册 全局事件总线

/**@ 路由守卫
 * @description 未登录的情况下，不能进入 个人中心模块，如： 1.个人中心  2.修改密码 页面。
 *
 * @param {object} to   将要访问的路径
 * @param {object} from 代表从哪个路径跳转而来
 * @param {function} next  是一个函数，表示放行 --例如  next('/login') 强制跳转
 *
 */
import { mycenter } from '@/api/mycenter';
import NProgress from 'nprogress';
router.beforeEach(async (to, from, next) => {
	if (to.fullPath == '/myCenter' || to.fullPath == '/changePwd') {
		try {
			let res = await mycenter(); //请求个人数据,判断是否登录或者失效
			if (res == null) {
				Vue.prototype.$confirm(
					this.$t('lang.login.againlogin'),
					this.$t('lang.login.Tips'),
					{
						confirmButtonText: this.$t('lang.login.gologin'),
						cancelButtonText: this.$t('lang.login.cancel'),
						type: 'warning',
						callback: (action) => {
							if (action == 'confirm') {
								next({ path: '/login' }); //跳转到登录页
							} else {
								next({ path: from.fullPath }); //留在原页面
							}
						},
					}
				);
			} else {
				next(); //前往个人中心
			}
		} catch (err) {
			next({ path: '/404' });
		}
		NProgress.done();
	} else {
		//正常路由跳转，无限制的情况下

		next();
	}
});

new Vue({
	router,
	i18n,
	store,
	render: (h) => h(App),
}).$mount('#app');
