<!-- 解密范围 -->
<template>
  <div class="md5-desc">
    <div class="banner-bg">
      <md5-header />
      <div class="md5-contain desc-page">
        <!-- 描述 -->
        <div class="top-description-content">
          <div class="description-flex">
            <div class="description-title">查询MD5</div>
            <div class="sprit">/</div>
            <div class="second-title">
              <p>反向查询</p>
              <div class="blue-bgcolor"></div>
            </div>
          </div>
          <div class="depict-text">针对md5等全球通用公开的加密算法进行反向查询</div>
          <div class="text-border-cot">
            <div class="text-border">
              <p>建立了密文对应查询数据库，很多复杂密文只有本站才可查询，支持多种算法，实时查询记录超过24万亿条，共占用160T硬盘，成功率95%以上。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 白色背景区域 -->
    <div class="white-container">
      <div>
        <div class="md5-contain article-box">
          <div class="article-item">
            <div class="title">
              MD5
              <h2>实时查询解密范围</h2>
            </div>
            <div class="data-centet">
              <div class="range-con">
                <p>山东大学王小云教授发现了md5的碰撞原理震惊了世界，本站就是专门针对全球通用的md5等算法进行反向解密，实时查询拥有全世界最大的数据库，实测解密成功率在95%以上，有的用户已经超过了98%。目前世界上同类网站越来越多，但是我们一直保持着遥遥领先的优势。即使千万条以上的数据，我们一个小时就能解密到90%以上的水平，其余绝大部分时间都是为了提高哪怕一个百分点的成功率。本站帮您最大限度的恢复数据价值。</p>
                <p>本站还支持dz、vb、mssql等实时查询和实时批量解密，此类带salt的加密类型，由于算法的特殊性，一般方法无法建库，本站采用独特技术，在保证最高成功率的前提下，实现了实时批量解密，这在全球都是绝无仅有的，2010年正式上线以来，至今没有其它任何一家真正实现了此功能。</p>
                <p>密文破解早已经变成了资金密集型和技术密集型的行业，本站在全球的领先优势持续扩大，在美国，在俄罗斯，在中国，行业内无人不识cmd5。有些密文只有本站可以破解，除此以外别无他法，如果本站不能破出，你就没有任何办法。</p>
                <p>国内从来不缺小偷和小人，本站被大量抄袭，大家可任意测试，任意对比，本站的解密速度、解密成功率是无与伦比的。此类网站完全用技术说话，完全用数据说话，但小白太多，新用户不知道我们的实力。</p>
              </div>
            </div>
          </div>
          <div class="article-item">
            <div class="title">
              MD5
              <h2>MD5</h2>
            </div>
            <div class="data-centet">
              <div class="table-shadow">
                <comdTable :columns="fields_md5" :data="items_md5"></comdTable>
              </div>
            </div>
          </div>
          <div class="article-item">
            <div class="title">
              MD5
              <h2>本站支持的密文类型及格式说明</h2>
            </div>
            <div class="data-centet">
              <div class="table-shadow">
                <comdTable :columns="fields_type" :data="items_type"></comdTable>
                <p class="attention">
                  注：
                  <br />1）如果你不不知道密文类型，在首页查询时，密文类型选择"自动"即可，将尝试所有的密文类型，多试几条，解密成功提示真正的密文类型，除此以外则无法从一个32位等字符串判断密文类型。
                  <br />2）除了按以上直观方法判断密文类型，再无其它办法，请不要用任何方式咨询我们关于密文类型的问题。
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div class="article-item">
            <div class="title">
              MD5
              <h2>解密举例</h2>
            </div>
            <div class="data-centet">
              <!-- <el-image style="width:100%;" src="../assets/img/demo.png" fit="fit"></el-image> -->
              <img src="@/assets/img/demo.png" style="width:100%;" alt="举例" />
            </div>
          </div>
          <div class="article-item">
            <div class="title">
              MD5
              <h2>关于新的密文类型/加密算法</h2>
            </div>
            <div class="data-centet">
              你们不是很牛吗,为什么我这个不能解密？不是骗子吧！"
              <br />本站只支持常用的加密算法，对于新的算法加密的密文，是无法解密的。更不可能根据无法查询的密文推断加密算法。
              <br />如果你知道新的加密算法，请根据网站底部的email和我们联系。需详细描述算法内容，最好是附上源码。除此以外，请举例说明，例如123456加密后的密文是什么。
              <br />只要加密算法明确，一般就可以解密。
              <br />本站可以在一天内增加新的类型。
              <br />
            </div>
          </div>
          <div class="article-item">
            <div class="title">
              MD5
              <h2>独有数据说明</h2>
            </div>
            <div class="data-centet">
              <p>本站的数据量比世界上任何其它网站都超过100倍以上，举个很简单的例子，任何网站很难收录到8位小写字母，而本站多个类型都已经收录8位以上小写字母+数字。根据排列组合原理，每增加一位，总数据量就是几何级别的增加。也就是说，本站数据95%以上都是全球独有的。</p>
              <p>除此以外，本站筹建了独立服务器，收录了一批极为冷僻的数据，这些数据命中的几率非常低，因此相对成本要高出很多。但仍然按普通的计费。</p>
            </div>
          </div>
          <div class="article-item">
            <div class="title">
              MD5
              <h2>后台分布式解密</h2>
            </div>
            <div class="data-centet">
              无论是在首页查询，或者通过在线网页或软件进行批量解密，只要是非salt类型查询失败的，将立即自动进入后台分布式云解密。用户只需过一段时间再次查询或再次进行批量解密即可。如果是登录后查询，则破解成功将自动发送邮件通知。
              <br />由于本站实时解密成功率已经相当高，实际上后台分布式解密的成功可能性是相当低的。
              <br />
            </div>
          </div>
        </div>
      </div>
      <md5-footer />
    </div>
  </div>
</template>

<script>
import md5Header from "./../components/Header";
import md5Footer from "./../components/Footer";
import comdTable from "./../components/Range/range-table.vue";

export default {
  name: "md5-desc",
  components: { md5Header, comdTable, md5Footer },
  data() {
    return {
      fields_md5: [
        { key: 'con', label: '收录内容' },
        { key: 'msg', label: '说明' },
        { key: 'num', label: '数量' }
      ],
      items_md5: [
        { con: '1-6位大小写字母+数字+特殊字符', msg: '收录100%', num: '大于1400亿' },
        { con: '7位小写字母+数字', msg: '收录100%', num: '大于783亿' },
        { con: '8位小写字母+数字', msg: '收录100%', num: '大于28211亿' },
        { con: '9位小写字母', msg: '已收录30%，正在添加', num: '大于14000亿' },
        { con: '8-11位数字', msg: '收录100%	', num: '大于1000亿' },
        { con: '1-15位其它数据', msg: '部分收录', num: '大于28000亿' },
        { con: '1-20位', msg: '900G独家超大字典', num: '大于910亿' }
      ],
      fields_type: [
        { key: 'type', label: '密文类型' },
        { key: 'format', label: '格式举例' },
        { key: 'msg', label: '说明' }
      ],
      items_type: [
        { type: 'md5', format: 'e10adc3949ba59abbe56e057f20f883e49ba59abbe56e057', msg: '标准md5，32位或16位' },
        { type: 'md5(md5($pass))', format: 'b80c9c5f86de74f0090fc1a88b27ef34', msg: '第一次加密后，结果转换成小写，对结果再加密一次' },
        { type: 'md5(md5(md5($pass)))	', format: 'e57941ff9000aedb44eb2fa13f6e3e3c', msg: '第一次加密后，结果转换成小写，对结果再加密' },
        { type: 'MD5(MD5($pass))', format: 'bb7ff6177ee612ef9dc6acd3a9ea7ea9', msg: '第一次加密后，结果转换成大写，' },
        { type: 'MD5(MD5(MD5($pass)))', format: '36d627bd562e83ab995fb1fdf59c95d9', msg: '第一次加密后，结果转换成大写，对结果再加密一次' },
        { type: 'sha1', format: 'f03e8a370aa8dc80f63a6d67401a692ae72fa530', msg: '密文长度必须为40位' },
        { type: 'md4', format: 'c0a27f801162b8b862cd5f5a1a66e85a', msg: '32位' },
        { type: 'mysql', format: '29596332026fd206', msg: '老MYSQL数据库用的，16位，且第1位和第' },
        { type: 'mysql5', format: 'b34c662f720236babfc1b3f75203a80e1009844a', msg: '新版本MySql数据库用的' },
        { type: 'md5($pass.$salt)', format: '9393dc56f0c683b7bba9b3751d0f6a46:OTD6v4c8I3Zid2AL', msg: '在密码后附加一个字符串再加密。' },
        { type: 'md5($salt.$pass)', format: '5610604c157ef1d0fb33911542e5b06f:zg', msg: '在密码前附加一个字符串再加密。' },
        { type: 'md5(md5($pass).$salt);VB;DZ', format: '30e23a848506770eca92faed1bd9f3ec:gM5 cd1a0b2de38cc1d7d796b1d2ba6a954f:dc2bce ad5f538296c0e05c26b85451fef9ea95:To!@35B%QS@)]U.DTy%fDm;SLwW58w', msg: '用于dz,vB等论坛程序…' },
        { type: 'md5(md5($salt).md5($pass))IPB', format: 'ac8dfc54ba110487b86ad6514328fd49:m@kZ}', msg: 'salt长度5位' },
        { type: 'sha1($salt.$pass)', format: '9cea8c041ce88e0b2066343d819113005b80421c:2391 9cea8c041ce88e0b2066343d819113005b80421c 2391', msg: '用于SMF' },
        { type: 'Md5(Phpbb3)', format: '$H$912345678Mw/BjmincvnSS94/STawW/	', msg: 'Linux' },
        { type: 'Md5(Wordpress)', format: '$P$B12345678/c7bOMfLdQB9B/ypks8iB/	', msg: 'Linux' },
        { type: 'Md5(Unix)', format: '$1$12345678$kbapHduhihjieYIUP66Xt/	', msg: 'Linux' },
        { type: 'Des(Unix)', format: 'af.kPXROLU9uY	', msg: 'Linux' },
        { type: 'ntlm', format: '71dd0709187df68befd20973fc23f973	', msg: 'Windows' },
        { type: 'Domain Cached Credentials', format: '1aefd85a507965a6f1719e951b81d0f7	', msg: 'Windows' },
        { type: 'sha256', format: '8c6976e5b5410415bde908bd4dee15dfb167a9c873fc4bb8a81f6f2ab448a918', msg: '' },
        { type: 'sha256($pass.$salt)', format: '1ec82d9b57403e53fafcf0ad8a86db196d135ef7513443a985385d7c20bdbfbd:abcdabcd', msg: '' },
        { type: 'sha256($salt.$pass)', format: 'a6a4ccd14c6b21c63b8a0d38cfb7ead3e5032c58fdea7cd8a4da901db9462088:abcdabcd $sha256$abcdabcd$a6a4ccd14c6b21c63b8a0d38cfb7ead3e5032c58fdea7cd8a4da901db9462088	', msg: '' }
      ]
    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<style lang="scss">
.md5-desc {
  .banner-bg {
    background-image: url(../assets/img/range-banner.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 358px;
    // background-attachment: fixed;
    // background-repeat: no-repeat, no-repeat;
    // background-position: 0 0, left bottom;
  }

  .desc-page {
    padding-top: 40px;
    //padding-bottom:35px;
    .range-tit {
      margin-top: 30px;
    }
    .range-con {
      margin-top: 30px;
      .range-table {
        background-color: #fff;
        word-break: break-all;
        table-layout: fixed;
      }
      p {
        margin-bottom: 15px;
      }
    }

    .top-description-content {
      .description-flex {
        display: flex;
        .description-title {
          width: 157px;

          font-size: 34px;
          font-weight: bold;
          color: #333333;
        }
        .sprit {
          width: 13px;
          font-size: 28px;
          font-weight: 400;
          color: #333333;
        }
        .second-title {
          font-size: 28px;
          font-weight: 400;
          color: #333333;
          margin-left: 5px;
          position: relative;
          width: 115px;
          p {
            position: absolute;
            z-index: 2;
          }
          .blue-bgcolor {
            width: 100%;
            position: absolute;
            background: #44a9fe;
            height: 10px;
            bottom: 15px;
          }
        }
      }
      .depict-text {
        // width: 455px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      .text-border-cot {
        margin-top: 23px;
        width: 427px;
        height: 110px;
        background: #e1e1e1;

        margin-left: 20px;
        position: relative;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 40px;
        .text-border {
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          border: 1px solid #44a9fe;
        }
        p {
          position: absolute;
          z-index: 6;
          width: 420px;
          height: 63px;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          background: #e1e1e1;
        }
      }
    }
  }

  /** 白色背景区域**/
  .white-container {
    width: 100%;
    height: auto;
    background: #ffffff;
    .article-box {
      padding: 40px 0;
      .article-item {
        margin-bottom: 50px;
        .title {
          text-align: center;
          // width: 184px;
          font-size: 80px;
          font-weight: bold;
          color: #f5f5f5;
          margin: auto;
          position: relative;
          line-height: 66px;
          margin-bottom: 30px;
          h2 {
            // width: 191px;
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            position: absolute;
            z-index: 3;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .data-centet {
          // margin-top: 23px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          .attention {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            padding-top: 30px;
            padding-bottom: 70px;
          }
          .table-shadow {
            width: 100%;
            padding: 22px 19px 20px 21px;
            background: #ffffff;
            box-shadow: 0px 3px 12px 1px rgba(35, 24, 21, 0.05);
            border-radius: 6px;
          }
        }
      }
    }
  }
}
</style>
