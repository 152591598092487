import request from '@/utils/request'

export function login(query) {
  return request({
    url: '/goLogin',
    method: 'post',
    data: query
  })
}
export function captcha(query) {
  return request({
    url: '/captcha',
    method: 'get',
    params: query
  })
}

export function verifyCaptcha(query) {
  return request({
    url: '/verifyCaptcha',
    method: 'post',
    data: query
  })
}
//微信登录获取 二维码
export function getWxQrcode(query) {
  return request({
    url: '/wx_login',
    method: 'get',
    params: query
  })
}
//查询微信二维码 扫描登录结果 轮询请求
export function getQueryWXLoginResult(query) {
  return request({
    url: '/is_wx_scan',
    method: 'get',
    params: query
  })
}


