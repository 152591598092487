var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgotpwd-dialog-box"},[_c('div',{staticClass:"login-form"},[(!_vm.verifyMailStatus)?_c('div',{staticClass:"input-mailbox"},[_c('div',{staticClass:"form-item",class:[_vm.errTipObj.username.status ? 'err-input-border' : '']},[_c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("../../assets/img/login-icon/zhanghao.png"),"alt":_vm.$t('lang.forgotPwd.email')}}),_c('span',[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.email')))])]),_c('div',{staticClass:"right-input"},[_c('el-input',{attrs:{"placeholder":_vm.$t('lang.forgotPwd.emailPlaceholder')},on:{"focus":() => {
									_vm.errTipObj.username.status = false;
								}},model:{value:(_vm.postObj.username),callback:function ($$v) {_vm.$set(_vm.postObj, "username", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"postObj.username"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errTipObj.username.status),expression:"errTipObj.username.status"}],staticClass:"err-tip"},[_vm._v(" "+_vm._s(_vm.errTipObj.username.errTiptext)+" ")])]),_c('div',{staticClass:"form-item",class:[_vm.errTipObj.password.status ? 'err-input-border' : '']},[_c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("../../assets/img/login-icon/mima1.png"),"alt":_vm.$t('lang.forgotPwd.password')}}),_c('span',[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.password')))])]),_c('div',{staticClass:"right-input"},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.$t('lang.forgotPwd.msg4'),"show-password":""},on:{"focus":() => {
									_vm.errTipObj.password.status = false;
								}},model:{value:(_vm.postObj.password),callback:function ($$v) {_vm.$set(_vm.postObj, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"postObj.password"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errTipObj.password.status),expression:"errTipObj.password.status"}],staticClass:"err-tip"},[_vm._v(" "+_vm._s(_vm.errTipObj.password.errTiptext)+" ")])]),_c('div',{staticClass:"form-item",class:[_vm.errTipObj.againPassword.status ? 'err-input-border' : '']},[_c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("../../assets/img/login-icon/mima1.png"),"alt":_vm.$t('lang.forgotPwd.password')}}),_c('span',[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.againPassword')))])]),_c('div',{staticClass:"right-input"},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.$t('lang.forgotPwd.msg5'),"show-password":""},on:{"focus":() => {
									_vm.errTipObj.againPassword.status = false;
								}},model:{value:(_vm.checkPassword),callback:function ($$v) {_vm.checkPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"checkPassword"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errTipObj.againPassword.status),expression:"errTipObj.againPassword.status"}],staticClass:"err-tip"},[_vm._v(" "+_vm._s(_vm.errTipObj.againPassword.errTiptext)+" ")])]),_c('div',{staticClass:"verifycode-item"},[_c('div',{staticClass:"code-input",class:[_vm.errTipObj.verifyCode.status ? 'err-input-border' : '']},[_c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("../../assets/img/login-icon/yanzhengma.png"),"alt":_vm.$t('lang.forgotPwd.captcha')}}),_c('span',[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.captcha')))])]),_c('div',{staticClass:"right-input"},[_c('el-input',{attrs:{"placeholder":_vm.$t('lang.forgotPwd.captchaPlaceholder')},on:{"focus":() => {
										_vm.errTipObj.verifyCode.status = false;
									}},model:{value:(_vm.postObj.verifyCode),callback:function ($$v) {_vm.$set(_vm.postObj, "verifyCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"postObj.verifyCode"}})],1)]),_c('div',{staticClass:"code-img"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pastShow),expression:"!pastShow"}],staticClass:"get-text",on:{"click":_vm.sendCode}},[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.getCaptcha')))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.pastShow),expression:"pastShow"}],staticClass:"send-text"},[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.sent'))+"("+_vm._s(_vm.timerCount)+"s)")])])]),_c('div',{staticClass:"login-btn"},[_c('el-button',{staticClass:"cmd5-loginbtn",attrs:{"type":"primary","loading":_vm.nextStepLoading},on:{"click":_vm.nextStep}},[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.nextStep')))])],1),_c('div',{staticClass:"tip-register"},[_c('el-link',{staticClass:"register-link",attrs:{"underline":false},on:{"click":_vm.goLogin}},[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.goLogin')))])],1)]):_c('div',{staticClass:"pass-mailbox"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.title')))]),_c('p',{staticClass:"sent-text"},[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.sentText')))]),_c('div',{staticClass:"emails-tip"}),_c('div',{staticClass:"login-btn"},[_c('el-button',{staticClass:"cmd5-loginbtn",attrs:{"type":"primary"},on:{"click":_vm.goLogin}},[_vm._v(_vm._s(_vm.$t('lang.forgotPwd.goLogin')))])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }