
<template>
  <!-- 扫一扫二维码金额 -->
  <div class="QRcode-dialog">
    <el-dialog :visible="codedialogVisible" :show-close="false">
      <!-- 关闭图标 -->
      <div class="close-icon">
        <i class="iconfont icon-guanbi" @click="cancel"></i>
        <div class="black-line"></div>
      </div>

      <!-- 充值二维码 -->
      <div class="QRcode-box" v-if="paytype == 1">
        <h3>扫码充值</h3>
        <div class="QRcode-img">
          <div class="qrcode" ref="qrCodeUrl"></div>
        </div>
      </div>
      <!-- 充值失败的提示 -->
      <div class="chargeError-box" v-else-if="paytype == 2">
        <div class="tiptext-font">
          <i class="iconfont icon-guanbi"></i>
          <span>充值失败</span>
        </div>
      </div>
      <!-- 充值成功提示 -->
      <div class="chargeError-success" v-else>
        <div class="tiptext-font">
          <i class="iconfont icon-zhifu-zhifuchenggong"></i>
          <span>充值成功</span>
        </div>
        <div style="
    margin-top: 30px;
    font-size: 16px;
">
          <span>倒计时 {{countDown}} 秒后关闭</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * paytype 说明:
 *  
 *  paytype == 1 充值二维码
 *  paytype == 2 充值失败
 *  paytype == 3 充值成功
 */
import QRPayCode from 'qrcodejs2'
export default {
  name: 'QRcode',
  props: ['paytype', 'codedialogVisible', 'paycodeImg', 'countDown'],
  data() {
    return {
      QRcodedialogVisible: false,
    }
  },
  methods: {
    creatQrCode(_paycodeImg) {
      //创建之前要初始化.
      this.$refs.qrCodeUrl.innerHTML = '';
      new QRPayCode(this.$refs.qrCodeUrl, {
        text: _paycodeImg, //二维码图片路径
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRPayCode.CorrectLevel.H
      })
    },
    cancel() {
      this.$emit('cancelClose')
    },
  },
  mounted() {
    //this.creatQrCode();
    this.$nextTick(() => {
      //this.creatQrCode()
    });
  }
}
</script>

<style lang="scss">
.QRcode-dialog {
  .el-dialog {
    border-radius: 6px 6px 0px 0px;
    max-width: 868px;
    min-height: 528px;
    width: 95%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0 !important;
    //关闭图标
    .close-icon {
      position: absolute;
      top: -58px;
      right: 30px;
      .black-line {
        width: 2px;
        height: 28px;
        background: #ffffff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      i {
        font-size: 30px;
        color: #ffffff;
        cursor: pointer;
        &:hover {
          color: #44a9fe;
        }
      }
    }
    .QRcode-box {
      width: 210px;
      height: 250px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      h3 {
        font-size: 26px;
        font-weight: 400;
        color: #333333;
        line-height: 1;
        margin-bottom: 20px;
      }
      .QRcode-img {
        width: 192px;
        height: 192px;
        border: 1px solid #44a9fe;
        margin: 0 auto;
        padding: 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    // 充值失败提示
    .chargeError-box {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .tiptext-font {
        font-size: 60px;
        font-weight: 400;
        color: #ff0000;
        display: flex;
        align-items: center;
        i {
          font-size: 80px;
          color: #ff0000;
          margin-right: 21px;
        }
        span {
          line-height: 1;
        }
      }
    }
    // 充值成功
    .chargeError-success {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .tiptext-font {
        font-size: 60px;
        font-weight: 400;
        color: #007713;
        display: flex;
        align-items: center;
        i {
          font-size: 80px;
          color: #007713;
          margin-right: 21px;
        }
        span {
          line-height: 1;
        }
      }
    }
  }
}
</style>