<!--首页-->
<template>
	<div class="md5-index">
		<md5-header />
		<div class="index-page">
			<div class="text-center">
				<!-- <el-input id="_token" v-model="tokenData" type="hidden" value="{{$_token}}"></el-input> -->
				<h1>Magic Data 5</h1>
				<!-- <p>一站加密文加解密平台</p>-->
			</div>
			<!-- 搜索区域 -->
			<div class="md5-search" v-show="!userInfo">
				<div class="select-box">
					<el-select
						v-model="currentTypeVal"
						:placeholder="$t('lang.homePage.selectBox')"
					>
						<el-option
							v-for="(item, index) in selectOptions"
							:key="index"
							:label="item.text"
							:value="item.value"
						></el-option>
					</el-select>
				</div>
				<!-- 竖线分割 -->
				<div class="ccc-line"></div>
				<div class="input-box">
					<el-input
						:placeholder="$t('lang.homePage.placeholder')"
						v-model="ciphertext"
						@keyup.enter.native="md5Query()"
						clearable
					></el-input>
				</div>
				<div class="search-box">
					<el-button
						type="primary"
						icon="el-icon-search"
						class="mybtn-search"
						:loading="loading"
						@click="md5Query()"
						>{{ $t('lang.homePage.query') }}</el-button
					>
				</div>
			</div>
			<!-- 登录后的搜索框 -->
			<div class="haslogin-md5-search" v-show="userInfo">
				<div class="left-content">
					<!-- 搜索区域 -->
					<div class="select-box">
						<el-select
							v-model="currentTypeVal"
							:placeholder="$t('lang.homePage.selectBox')"
						>
							<el-option
								v-for="(item, index) in selectOptions"
								:key="index"
								:label="item.text"
								:value="item.value"
							></el-option>
						</el-select>
					</div>
					<!-- 竖线分割 -->
					<div class="ccc-line"></div>
					<div class="input-box">
						<el-input
							:placeholder="$t('lang.homePage.placeholder')"
							v-model="ciphertext"
							@keyup.enter.native="md5Query()"
							clearable
						></el-input>
					</div>
				</div>

				<!-- 右侧 验证码和搜索-->
				<div class="right-code-search">
					<div class="verifyCode-box">
						<img
							:src="src"
							:alt="$t('lang.homePage.captcha')"
							ref="captcha"
							@click="srcRandom = Math.random()"
						/>
						<el-input
							:placeholder="$t('lang.homePage.captchaPlaceholder')"
							v-model.trim="verifyCode"
							class="verifyCode-input"
							@keyup.enter.native="md5Query()"
						></el-input>
					</div>
					<el-button
						type="primary"
						icon="el-icon-search"
						class="mybtn-search"
						:loading="loading"
						@click="md5Query()"
						>{{ $t('lang.homePage.query') }}</el-button
					>
				</div>
			</div>
			<!-- 搜索结果 -->
			<div class="query-result" v-loading="loading">
				<div
					class="copy-icon"
					v-show="searchResult && !needLoginObj.Flag && resStatusCode == 200"
					@click="
						() => {
							copyData(searchResult);
						}
					"
				>
					<img
						src="../assets/img/login-icon/fuzhi.png"
						:alt="$t('lang.homePage.joinQQAlt')"
						class="iconfont icon-fuzhi"
					/>
					{{ $t('lang.homePage.copy') }}
				</div>

				<div class="result-tit">
					{{ $t('lang.homePage.result') }}
					<div class="copy-icon" @click="copyData(md5encryptionCpt_32)">
						<img
							src="../assets/img/login-icon/fuzhi.png"
							:alt="$t('lang.homePage.joinQQAlt')"
							class="iconfont icon-fuzhi"
						/>
						{{ $t('lang.homePage.copy') }}
					</div>
				</div>
				<div class="result-con">
					<div
						class="need-login"
						v-if="needLoginObj.Flag && resStatusCode == 200"
					>
						<!-- <span>{{searchResult}}</span> -->
						<span style="margin-left: 5px">{{ needLoginObj.message }}</span>
						<span class="needlogin-btn" @click="$router.push('/login')">{{
							$t('lang.homePage.login')
						}}</span>
					</div>
					<div class="result" v-else>
						<span v-show="resStatusCode == 200">{{ searchResult }}</span>
						<div v-show="resStatusCode == 400">
							<span class="red"
								>{{ $t('lang.homePage.decryptionFailed') }}：</span
							>

							<p class="result-fail">{{ searchResult }}</p>
						</div>
					</div>
				</div>
			</div>
			<transition name="encryption-result">
				<div
					class="encryption-result"
					v-show="ciphertext.trim() && isShowEncryption"
				>
					<div>
						<el-descriptions
							:title="$t('lang.homePage.encryptionType')"
							direction="horizontal"
							:column="1"
							border
							size="small"
						>
							<el-descriptions-item
								:label="$t('lang.homePage.encryptionLabel')"
							>
								<span>{{ ciphertext }}</span>
								<!-- 复制 -->
								<div class="copy-icon" @click="copyData(ciphertext)">
									<img
										src="../assets/img/login-icon/fuzhi.png"
										:alt="$t('lang.homePage.copyClick')"
										class="iconfont icon-fuzhi"
									/>
									{{ $t('lang.homePage.copy') }}
								</div>
							</el-descriptions-item>
							<el-descriptions-item :label="$t('lang.homePage.lowerCase16')">
								<span>{{ md5encryptionCpt_16 }}</span>
								<!-- 复制 -->
								<div class="copy-icon" @click="copyData(md5encryptionCpt_16)">
									<img
										src="../assets/img/login-icon/fuzhi.png"
										:alt="$t('lang.homePage.copyClick')"
										class="iconfont icon-fuzhi"
									/>
									{{ $t('lang.homePage.copy') }}
								</div>
							</el-descriptions-item>
							<el-descriptions-item :label="$t('lang.homePage.upperCase16')">
								<span>{{ md5encryptionCpt_16_toUpperCase }}</span>
								<!-- 复制 -->
								<div
									class="copy-icon"
									@click="copyData(md5encryptionCpt_16_toUpperCase)"
								>
									<img
										src="../assets/img/login-icon/fuzhi.png"
										:alt="$t('lang.homePage.copyClick')"
										class="iconfont icon-fuzhi"
									/>
									{{ $t('lang.homePage.copy') }}
								</div>
							</el-descriptions-item>
							<el-descriptions-item :label="$t('lang.homePage.lowerCase32')">
								<span>{{ md5encryptionCpt_32 }}</span>
								<!-- 复制 -->
								<div class="copy-icon" @click="copyData(md5encryptionCpt_32)">
									<img
										src="../assets/img/login-icon/fuzhi.png"
										:alt="$t('lang.homePage.copyClick')"
										class="iconfont icon-fuzhi"
									/>
									{{ $t('lang.homePage.copy') }}
								</div>
							</el-descriptions-item>
							<el-descriptions-item :label="$t('lang.homePage.upperCase32')">
								<span>{{ md5encryptionCpt_32_toUpperCase }}</span>
								<!-- 复制 -->
								<div
									class="copy-icon"
									@click="copyData(md5encryptionCpt_32_toUpperCase)"
								>
									<img
										src="../assets/img/login-icon/fuzhi.png"
										:alt="$t('lang.homePage.copyClick')"
										class="iconfont icon-fuzhi"
									/>
									{{ $t('lang.homePage.copy') }}
								</div>
							</el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
			</transition>

			<div class="md5ad">
				<div class="md5ad-item">
					<a
						href="https://www.xduoyu.com?qd=ad_md5"
						target="_blank"
						title="多御浏览器"
					>
						<img src="../assets/img/01.gif" alt="多御浏览器" />
						<div class="item-type">广告</div>
					</a>
				</div>
				<div class="md5ad-item">
					<a
						href="https://www.xduoyu.com?qd=ad_md5"
						target="_blank"
						title="多御浏览器"
					>
						<img src="../assets/img/02.gif" alt="多御浏览器" />
						<div class="item-type">广告</div>
					</a>
				</div>
				<div class="md5ad-item">
					<a
						href="https://www.xduoyu.com?qd=ad_md5"
						target="_blank"
						title="多御浏览器"
					>
						<img src="../assets/img/03.gif" alt="多御浏览器" />
						<div class="item-type">广告</div>
					</a>
				</div>
			</div>

			<!-- 公共栏区域 -->
			<div class="md5-notice-box">
				<el-alert
					:title="$t('lang.homePage.noticeText')"
					type="info"
					height="172"
				>
					<div class="notice-description-content">
						<span class="text">
							{{ $t('lang.homePage.noticeContent') }}
							<!-- 本站支持多种在线解密算法，快速，准确，稳定
							，免费；数据库不断更新，欢迎查询！更多问题欢迎咨询：官方QQ交流群1：857548361，官方QQ交流群2：280409281 -->
						</span>
					</div>
					<div class="join-weixin-group">
						<el-button class="join-weixin-btn">
							<img
								src="../assets/img/login-icon/qq-logo.png"
								:alt="$t('lang.homePage.joinQQAlt')"
								class="qq-wx-ico"
							/>
							<a
								target="_blank"
								href="https://qm.qq.com/cgi-bin/qm/qr?k=6QXHqMa7lZ4MRF1zUwIeSIe56V9-kEdW&jump_from=webapi"
								>{{ $t('lang.homePage.joinQQText') }}</a
							></el-button
						>

						<el-popover
							placement="bottom"
							width="200"
							trigger="hover"
							popper-class="join-weixinGroup-popover"
						>
							<el-button class="join-weixin-btn" slot="reference">
								<img
									src="../assets/img/login-icon/wx-logo.png"
									:alt="$t('lang.homePage.joinWeChatAlt')"
									class="qq-wx-ico"
								/>

								<a href="#">{{
									$t('lang.homePage.joinWeChatText')
								}}</a></el-button
							>
							<!-- 加入微信群二维码图片 -->
							<div class="join-weixinGroup-img">
								<img
									:src="
										'https://md5.cn/storage/images/joinweixinGroup.png?' +
										new Date().getTime()
									"
									:alt="$t('lang.homePage.joinWeChatText')"
								/>
							</div>
						</el-popover>
					</div>
				</el-alert>
			</div>
		</div>
		<md5-footer />
		<qq-login-dialog />
	</div>
</template>

<script>
import md5Header from './../components/Header';
import md5Footer from './../components/Footer';
import fetchMd5 from './../api/md5';
import qqLoginDialog from '@/components/first-qqlogin-dialog'; //QQ首次登录需要绑定邮箱
import { mapState, mapActions } from 'vuex';
export default {
	name: 'index',
	components: {
		md5Header,
		md5Footer,
		qqLoginDialog,
	},
	data() {
		return {
			isShowEncryption: true,
			resStatusCode: 200,
			srcRandom: Math.random(),
			verifyCode: '', //验证码
			needLoginObj: {
				Flag: false,
				message: '',
			},
			currentTypeVal: this.$t('lang.homePage.currentTypeVal'),
			ciphertext: '',
			cookieData: '',
			tokenData: '',
			searchResult: '', //搜索结果
			hasLogin: false,
			loading: false,
			selectOptions: [
				{
					text: this.$t('lang.automatic'),
					value: this.$t('lang.automatic'),
				},
				{
					text: 'md5_16',
					value: '10',
				},
				{
					text: 'md5_32',
					value: '20',
				},
				{
					text: 'md5(md5($pass))',
					value: '30',
				},
				{
					text: 'md5(md5(md5($pass))) ',
					value: '40',
				},
				{
					text: 'md5(unicode) ',
					value: '50',
				},
				{
					text: 'md5(base64) ',
					value: '60',
				},
				{
					text: 'mysql',
					value: '70',
				},
				{
					text: 'mysql5',
					value: '80',
				},
				{
					text: 'ntlm',
					value: '90',
				},
				{
					text: 'sha1',
					value: '100',
				},
				{
					text: 'sha1(sha1($psss))',
					value: '110',
				},
				{
					text: 'sha1(md5($psss))',
					value: '120',
				},
				{
					text: 'md5(sha1($psss))',
					value: '130',
				},
				{
					text: 'sha256',
					value: '140',
				},
				{
					text: 'sha256(md5($pass))',
					value: '150',
				},
				{
					text: 'sha384',
					value: '160',
				},
				{
					text: 'sha512',
					value: '170',
				},
			],
		};
	},
	watch: {
		//监听输入解密值
		ciphertext() {
			if (this.ciphertext.trim()) {
				this.isShowEncryption = true;
			}
		},
	},
	computed: {
		src() {
			return `/api/v1/captcha?type=decryption&'${this.srcRandom}`;
		},
		md5encryptionCpt_16() {
			return this.ciphertext.MD5();
		},
		md5encryptionCpt_16_toUpperCase() {
			return this.ciphertext.MD5().toUpperCase();
		},
		md5encryptionCpt_32() {
			return this.ciphertext.MD5(32);
		},
		md5encryptionCpt_32_toUpperCase() {
			return this.ciphertext.MD5(32).toUpperCase();
		},
		...mapState(['userInfo']),
	},
	methods: {
		//md5 解密操作
		md5Query() {
			let regAccount = /[\u4e00-\u9fa5]+/g;
			if (regAccount.test(this.ciphertext)) {
				this.$message({
					title: this.$t('lang.prompt') + ':',
					message: this.$t('lang.homePage.errText'),
					type: 'error',
				});

				this.resStatusCode = 400;
				this.searchResult = this.$t('lang.homePage.errText');
				return false;
			}
			this.loading = true;
			this.isShowEncryption = false;
			//数据请求
			fetchMd5({
				hash: this.ciphertext,
				verifyCode: this.verifyCode,
			})
				.then((res) => {
					this.loading = false;
					this.searchResult = '';
					//如果是400 报错提示
					if (res && res.code == 400) {
						this.resStatusCode = res.code;
						this.searchResult = res.message;
						this.$refs.captcha.click();
						return;
					}

					if (res) {
						this.resStatusCode = 200;
						this.searchResult = res.data; // 结果
						this.currentTypeVal = res.type; // 类型
						this.needLoginObj.Flag = res.need_login || 0;

						this.needLoginObj.message = res.message;

						//判断是否需要登录的问题 res.need_login == 1  需要登录
						if (res.need_login) this.clearLoginInfo();
					} else {
						this.searchResult = this.$t('lang.homePage.errDecode'); // 结果
						this.currentTypeVal = this.$t('lang.automatic');
					}
					this.$refs.captcha.click();
				})
				.catch(() => {
					this.loading = false;
					this.$refs.captcha.click();
				});
		},
		...mapActions([
			//清除数据
			'clearLoginInfo', // 将 `this.clearLoginInfo()` 映射为 `this.$store.dispatch('clearLoginInfo')`
		]),
		//判断QQ登录处理逻辑
		QQLoginAction() {
			let qq_state = this.$cookies.get('qq_state');
			if (qq_state) {
				//QQ首次登录,弹出绑定邮箱对话框
				this.$EventBus.$emit('toQQLogin', true);
				this.$store.commit('setqqState', qq_state);
				this.$cookies.remove('qq_state');
			} else {
				let userObj = this.$cookies.get('userInfo');
				if (userObj && userObj.userInfo && userObj.token) {
					this.$store.commit('setToken', userObj.token);
					this.$store.commit('setUserInfo', userObj.userInfo);
					this.$cookies.remove('userInfo');
					this.$message.success(this.$t('lang.homePage.successLogin'));
					setTimeout(() => {
						location.reload(); //刷新页面
					}, 1000);
				}
			}
		},
	},
	mounted() {
		this.QQLoginAction();
	},
	created() {},
};
</script>

<style lang="scss">
.md5-index {
	background-attachment: fixed;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: space-between;
}

.index-page {
	flex: 1;
	width: 1208px;
	padding-top: 170px;
	margin: 0 auto;
	transition: all 0.5s;
	.text-center {
		text-align: center !important;
		margin-bottom: 41px;
		h1 {
			font-size: 50px;
			font-weight: bold;
			color: #333333;
			margin-bottom: 15px;
			line-height: 1;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
	}

	// 搜索

	.md5-search {
		height: 46px;
		background: #ffffff;
		box-shadow: 0px 0px 8px 0px rgba(102, 183, 251, 0.15);
		border-radius: 6px;
		display: flex;
		align-items: center;
		.select-box {
			width: 140px;
			.el-input__inner {
				border: 0;
			}
			.el-input__suffix {
				right: -9px;
			}
			.el-select__caret {
				font-size: 18px;
			}
		}

		.ccc-line {
			width: 2px;
			height: 20px;
			background: #cccccc;
		}
		.input-box {
			flex: 1;
			.el-input__inner {
				height: 46px;
				line-height: 46px;
				border: 0 !important;
			}
		}

		.search-box {
			width: 137px;

			height: 46px;

			background: #44a9fe;

			border-radius: 0px 6px 6px 0px;

			.mybtn-search {
				width: 100%;

				height: 100%;

				font-weight: 400;

				font-size: 14px;

				display: flex;

				align-items: center;

				justify-content: center;

				.el-icon-search {
					font-size: 18px;
				}
			}
		}
	}
	// 整个搜索框
	.haslogin-md5-search {
		height: 56px;
		border-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.left-content {
			width: 860px;
			background: #ffffff;

			display: flex;

			align-items: center;

			border-radius: 6px 6px 6px 6px;

			margin-right: 10px;

			box-shadow: 0px 0px 8px 0px rgba(102, 183, 251, 0.15);

			.select-box {
				width: 140px;

				.el-input__inner {
					border: 0;
				}
				// 向下箭头
				.el-input__suffix {
					right: -9px;
				}
			}

			.ccc-line {
				width: 2px;

				height: 20px;

				background: #cccccc;
			}

			.input-box {
				flex: 1;

				.el-input__inner {
					height: 56px;

					line-height: 46px;

					border: 0 !important;
				}
			}
		}
		// 右侧验证码和搜索框
		.right-code-search {
			display: flex;
			align-items: center;
			height: 100%;
			// 验证码框
			.verifyCode-box {
				display: flex;
				height: 100%;

				width: 270px;
				margin-right: 10px;
				background: #fff;
				box-shadow: 0px 0px 8px 0px rgba(102, 183, 251, 0.15);
				border-radius: 6px;
				img {
					width: 110.5px;
					height: 34px;
					margin: auto 16px;
				}
				// 请输入验证码
				.verifyCode-input .el-input__inner {
					width: 85px;
					height: 56px;
					line-height: 46px;
					border: 0 !important;
					padding: 0;
				}
			}
			// 解密搜索框
			.mybtn-search {
				height: 100%;
				font-weight: 400;
				font-size: 14px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 6px !important;
				width: 130px;
				box-shadow: 0px 1px 8px 0px rgba(35, 24, 21, 0.1);
				.el-icon-search {
					font-size: 18px;
				}
				span {
					margin-top: 4px;
				}
			}
		}
	}

	//动画过渡效果
	.encryption-result-enter-active,
	.encryption-result-leave-active {
		animation-duration: 0.5s;
		animation-fill-mode: both;
	}
	.encryption-result-enter-active {
		animation-name: fadeIn;
	}
	.encryption-result-leave-active {
		animation-name: fadeOut;
	}
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
	@keyframes fadeOut {
		0% {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}

	//加密结果
	.encryption-result {
		margin-top: 10px;
		padding: 20px;
		background: #ffffff;
		box-shadow: 0px 3px 14px 0px rgba(102, 183, 251, 0.2);
		border-radius: 6px;
		overflow: hidden;
		position: relative;

		.el-descriptions {
			.el-descriptions__header {
				margin-bottom: 10px;
			}
			.el-descriptions__title {
				color: #666;
				font-size: 14px;
				font-weight: normal;
			}
			.el-descriptions-row {
				.el-descriptions-item__label {
					text-align: left;
					font-size: 12px;
					width: 143px;
					padding-left: 28px;
				}
				.el-descriptions-item__content {
					position: relative;
					font-size: 14px;
					padding-left: 30px;
					&:hover {
						background-color: #f5f7fa;
						.copy-icon {
							display: block;
							color: rgba(56, 56, 56, 1);
						}
					}

					span {
						font-weight: 500;
						color: rgba(56, 56, 56, 1);
					}
				}
			}
			.copy-icon {
				top: 7px;
				display: none;
				color: rgba(56, 56, 56, 1);
				.icon-fuzhi {
					margin-right: 6px;
				}
			}
		}
	}
	.icon-fuzhi {
		margin-right: 10px;
	}
	// 搜索结果
	.query-result {
		height: 175px;
		margin-top: 10px;
		padding: 20px;
		background: #ffffff;
		box-shadow: 0px 3px 14px 0px rgba(102, 183, 251, 0.2);
		border-radius: 6px;
		overflow: hidden;
		position: relative;
		.result-tit {
			color: #666;
			font-size: 14px;
		}
		.result-con {
			font-size: 14px;
			padding-top: 12px;
			padding-bottom: 30px;
			font-weight: bold;
			color: #333333;
			word-break: break-all;
		}

		.need-login {
			.needlogin-btn {
				width: 54px;
				height: 24px;
				background: #ffffff;
				border: 1px solid #44a9fe;
				border-radius: 6px;
				display: inline-block;
				line-height: 24px;
				text-align: center;
				font-size: small;
				color: #44a9fe;
				cursor: pointer;
				margin-left: 10px;
				user-select: none;
				&:hover {
					background-color: #44a9fe;
					color: #ffffff;
				}
			}
		}
		// 检测失败的结果
		.result-fail {
			margin-top: 20px;
			font-size: 14px;
			color: rgba(166, 166, 166, 1);
			font-weight: 400;
		}
		.copy-icon {
			font-size: 14px;
			position: absolute;
			right: 10px;
			top: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			color: rgba(56, 56, 56, 1);
			margin-top: 10px;
			margin-right: 20px;
		}
	}

	//公告区域
	.md5-notice-box {
		margin-top: 8px;
		position: relative;
		.el-alert {
			padding: 0;
			background: rgba(255, 255, 255, 0.6);
		}

		.el-alert__content {
			padding-top: 16px;
			padding-left: 20px;
			.el-alert__title {
				font-size: 14px;
			}
			.el-alert__description {
				font-size: 14px;
			}
		}
		.notice-description-content {
			display: flex;
			align-items: center;
			span {
				font-size: 12px;
				margin-right: 20px;
			}
		}
	}

	.el-select .el-input {
		width: 120px;
	}
	// 关闭的按钮
	.el-alert__closebtn {
		color: rgba(128, 128, 128, 1) !important;
		font: 800 20px/20px '';
	}
	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}

	.mybtn-search {
		background-color: #44a9fe !important;

		border-radius: 0px 6px 6px 0px !important;

		color: #ffffff !important;

		border-color: #44a9fe !important;

		&:focus,
		&:hover {
			background: #66b1ff !important;
			border-color: #66b1ff !important;
			color: #fff !important;
		}
	}

	//加入微信群 按钮
	.join-weixin-group {
		margin-top: 11px;
		margin-bottom: 12px;
		display: flex;
		justify-content: flex-start;

		.join-weixin-btn {
			border: none;
			width: 112px;
			height: 32px;
			padding: 6px 10px;
			margin-right: 9px;
			color: #000;
			border-radius: 6px;
			background: rgba(255, 255, 255, 1);
			box-shadow: 0px 0px 5px 0px rgba(166, 166, 166, 0.2);
			span {
				display: flex;
				height: 32px;
				padding-bottom: 4px;
			}
			.qq-wx-ico {
				width: 18px;
				height: 18px;
				margin-right: 4px;
			}
			a {
				color: #000;
				margin-top: 4px;
			}
		}
	}
}
.join-weixinGroup-popover {
	.join-weixinGroup-img {
		height: 180px;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
/* 广告 */
.header-adv-s,
.header-adv-l {
	overflow: hidden;
	position: relative;
	border-radius: 6px;
}
.header-adv-s img,
.header-adv-l img {
	display: block;
	width: 100%;
	border-radius: 6px;
}
.header-adv-s {
	display: none;
}
@media screen and (max-width: 768px) {
	.header-adv-s {
		display: block;
	}
	.header-adv-l {
		display: none;
	}
}
.md5ad {
	width: 100%;
	height: auto;
	margin: 20px auto;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.md5ad-item a {
	display: block;
	overflow: hidden;
	max-height: 80px;
	position: relative;
}
.md5ad-item {
	width: 100%;
}
.md5ad .md5ad-item:nth-child(2) {
	margin: 5px 0;
}
.md5ad-item .item-type {
	line-height: 20;
	background: rgba(255, 255, 255, 0.6);
	position: absolute;
	right: 0;
	bottom: 0;
	color: rgba(0, 0, 0, 0.5);
	font-size: 12px;
	z-index: 1;
	display: block;
	height: 16px;
	line-height: 16px;
	width: 40px;
	border-radius: 10px;
	text-align: center;
}
.md5ad-item img {
	width: 100%;
	min-height: auto;
	display: block;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
}

@media (min-width: 992px) {
	.md5ad {
		flex-direction: row;
		// width: 1200px;
	}
	.md5ad .md5ad-item:nth-child(2) {
		margin: 0;
	}
	.md5ad-item {
		width: 32.5%;
	}
}
</style>
