<!--批量解密 API -->
<template>
	<div class="md5-API">
		<div class="cmd-api-box">
			<md5-header />
		</div>
		<div class="white-bgcolor">
			<div class="md5-contain cmd-api">
				<h3 class="title">{{ $t('lang.api.api') }}</h3>
				<div class="text-content">
					<div class="text-item">
						<div class="tip-title">
							<div class="stand-line"></div>
							<div class="h4-title">{{ $t('lang.api.callURL') }}</div>
						</div>
						<div class="wrapper-content">
							<p>
								{{ $t('lang.api.callURL') }}：
								https://www.md5.cn/api/v1/api_decryption
							</p>

							<div class="table-shadow">
								<comdTable
									:columns="fields_type"
									:data="items_type"
								></comdTable>
							</div>

							<!-- <div class="table-shadow">
                <table>
                  <thead>
                    <tr>
                      <th>参数名</th>
                      <th>必选</th>
                      <th>类型</th>
                      <th>说明</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>notify_url</td>
                      <td>是</td>
                      <td>string</td>
                      <td>你的接收结果的url</td>
                    </tr>
                    <tr>
                      <td>key</td>
                      <td>是</td>
                      <td>string</td>
                      <td>登陆后台后显示</td>
                    </tr>
                    <tr>
                      <td>hash</td>
                      <td>是</td>
                      <td>string</td>
                      <td>你的密文, 逗号分开</td>
                    </tr>
                  </tbody>
                </table>
              </div>-->

							<br />
							<p>1. {{ $t('lang.api.wrapperContent1') }}</p>
							<p>2. {{ $t('lang.api.wrapperContent2') }}</p>
							<p>3. {{ $t('lang.api.wrapperContent3') }}</p>
							<p>4. {{ $t('lang.api.wrapperContent4') }}</p>
						</div>
					</div>
					<div class="text-item">
						<div class="tip-title" style="align-items: baseline">
							<div class="stand-line"></div>
							<div class="h4-title">{{ $t('lang.api.tipTitle') }}</div>
						</div>
						<div class="wrapper-content">
							<p>{{ $t('lang.api.errorCodeTitle') }}</p>
							<p>ERROR-CODE: 0 {{ $t('lang.api.errorCode1') }}</p>
							<p>ERROR-CODE:-1 {{ $t('lang.api.errorCode2') }}</p>
							<p>ERROR-CODE:-2 {{ $t('lang.api.errorCode3') }}</p>
							<p>ERROR-CODE:-3 {{ $t('lang.api.errorCode4') }}</p>
							<p>ERROR-CODE:500 {{ $t('lang.api.errorCode5') }}</p>
							<p>ERROR-CODE:-99 {{ $t('lang.api.errorCode6') }}</p>
						</div>
					</div>
					<div class="text-item">
						<div class="tip-title" style="align-items: baseline">
							<div class="stand-line"></div>
							<div class="h4-title">{{ $t('lang.api.important') }}</div>
						</div>
						<div class="wrapper-content">
							<!-- <p>
                1. 本接口被严密监控，不得用于二次销售、网站代查、公开查询等情形，例如有假冒本网站提供查询，实际上是后台用本接口
                <br />偷偷到本网站查询等， 一经发现立即封号不予以退款。
              </p> -->
							<p>1.{{ $t('lang.api.wrapperContent5') }}</p>
							<p>2. {{ $t('lang.api.wrapperContent6') }}</p>
							<p>3. {{ $t('lang.api.wrapperContent7') }}</p>
							<p>4. {{ $t('lang.api.wrapperContent8') }}</p>
						</div>
					</div>
				</div>
			</div>
			<md5-footer />
		</div>
	</div>
</template>

<script>
import md5Header from './../../components/Header';
import md5Footer from './../../components/Footer';
import comdTable from './../../components/Range/range-table.vue';
export default {
	name: 'api',
	components: { md5Header, md5Footer, comdTable },
	data() {
		return {
			fields_type: [
				{ key: '1', label: this.$t('lang.api.type_label1') },
				{ key: '2', label: this.$t('lang.api.type_label2') },
				{ key: '3', label: this.$t('lang.api.type_label3') },
				{ key: '4', label: this.$t('lang.api.type_label4') },
			],
			items_type: [
				{
					params: 'notify_url',
					isRequire: this.$t('lang.api.isRequire'),
					type: 'string',
					desc: this.$t('lang.api.desc1'),
				},
				{
					params: 'key',
					isRequire: this.$t('lang.api.isRequire'),
					type: 'string',
					desc: this.$t('lang.api.desc2'),
				},
				{
					params: 'hash',
					isRequire: this.$t('lang.api.isRequire'),
					type: 'string',
					desc: this.$t('lang.api.desc3'),
				},
			],
		};
	},
};
</script>

<style lang="scss">
.body {
	background: #ffffff;
}
.md5-API {
	position: relative;
	.poistion-bottom {
		position: relative;
		height: 50px;
	}
	.white-bgcolor {
		width: 100%;
		height: 100%;
		background: #ffffff url(../../assets/img/apibg.png) no-repeat;
		background-size: 100% 668px;
		background-position-y: 27px;
	}
	.table-shadow {
		width: 100%;
		padding: 22px 19px 20px 21px;
		background: #ffffff;
		box-shadow: 0px 3px 12px 1px rgba(35, 24, 21, 0.05);
		border-radius: 6px;
	}

	.cmd-api-box {
		width: 100%;
		height: 668px;
	}
	.cmd-api {
		/*.api-ol{
      margin-top: 30px;
      list-style-type: auto;
      padding-left: 50px;
    }
    .api-ul{
      margin-top: 30px;
      padding-left: 30px;
    }*/
		padding-top: 120px;
		// position: relative;
		// top: -630px;
		margin-top: -630px;
		.title {
			width: 100%;
			height: 43px;
			font-size: 44px;
			font-weight: bold;
			color: #333333;
			line-height: 36px;
			margin-bottom: 147px;
			text-align: center;
		}
		.text-content {
			width: 100%;
			height: auto;
			background: #ffffff;
			box-shadow: 0px 3px 29px 0px rgba(68, 169, 254, 0.15);
			padding-top: 60px;
			padding-bottom: 50px;
			margin-bottom: 30px;
			.tip-title {
				padding-left: 25px;
				display: flex;
				align-items: center;
				.stand-line {
					width: 6px;
					height: 15px;
					background: #44a9fe;
					margin-right: 15px;
				}
				.h4-title {
					font-size: 16px;
					font-weight: bold;
					color: #333333;
					line-height: 36px;
				}
			}
			.wrapper-content {
				padding-left: 45px;
				padding-top: 10px;
				padding-bottom: 20px;
				padding-right: 80px;

				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 28px;
			}
		}
	}
}
</style>
