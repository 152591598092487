<!--个人中心-->
<template>
	<div class="md5-mycenter">
		<div class="banner-bg">
			<md5-header :hasLogin="hasLogin" @loginOut="logout" />
			<div class="md5-contain mycenter-page">
				<div class="title">{{ $t('lang.myCenter.title') }}</div>
			</div>
		</div>
		<div class="white-container">
			<div class="md5-contain mycenter-content">
				<div class="appkey">
					<p>
						<span class="key">API key：</span>
						<span class="blue" ref="apikey">{{ user.api_key }}</span>
					</p>
					<el-button
						class="copy-btn"
						ref="copyButton"
						@click="
							() => {
								copyData(user.api_key);
							}
						"
					>
						<i class="iconfont icon-fuzhi"></i>
						{{ $t('lang.copy') }}
					</el-button>
				</div>
				<!-- 我的积分 -->
				<div class="my-integral">
					<div class="left-item">
						<div class="total-content">
							<div class="icon">
								<img src="../assets/img/con_1.png" alt="" />
							</div>
							<!-- 我的积分 -->
							<div class="tip-text">
								<h2>{{ $t('lang.myCenter.myTotal') }}</h2>
								<div class="my-total">{{ user.points }}</div>
							</div>
						</div>
					</div>
					<div class="right-item">
						<div class="integral-item" style="margin-bottom: 10px">
							<!-- 左侧 -->
							<div class="left-textconent">
								<div class="icon">
									<img src="../assets/img/con_2.png" alt="" />
								</div>
								<div class="text">
									<h2>{{ $t('lang.myCenter.shareGetPoints') }}</h2>
									<p class="explain">{{ $t('lang.myCenter.shareExplain') }}</p>
								</div>
							</div>
							<div class="right-btn">
								<el-button class="copy-btn" @click="md5Share">
									<i class="el-icon-share"></i>
									{{ $t('lang.myCenter.share') }}
								</el-button>
							</div>
						</div>
						<div class="integral-item">
							<div class="left-textconent">
								<div class="icon">
									<img src="../assets/img/con_3.png" alt="" />
								</div>
								<div class="text">
									<h2>{{ $t('lang.myCenter.rechargeGetShare') }}</h2>
									<p class="explain">
										{{ $t('lang.myCenter.rechargeExplain') }}
									</p>
								</div>
							</div>
							<!-- <div class="right-btn">
								<el-button
									class="copy-btn"
									@click="wechatpayupVisible = !wechatpayupVisible"
								>
									<i class="iconfont icon-chongzhi2"></i>
									{{ $t('lang.myCenter.recharge') }}
								</el-button>
							</div> -->
						</div>
					</div>
				</div>
				<!-- 微信充值 dialog 组件 -->
				<wechat-pay-up
					:wechatpayupDialogVisible="wechatpayupVisible"
					:userinfo="user"
					@cancelClose="wechatpayupVisible = false"
					@confirmWechatPay="confirmWechatPay"
				/>
				<!-- 微信二维码 -->
				<qr-code
					ref="QRCode"
					:paytype="CodePaytype"
					:codedialogVisible="PaycodedialogVisible"
					:countDown="CodePaycountDown"
					@confirmWechatPay="confirmWechatPay"
					@cancelClose="PaycodedialogVisible = false"
				/>
				<!-- 积分记录 -->
				<div class="integral-record">
					<h2>{{ $t('lang.myCenter.integralRecord') }}</h2>
					<div class="list-content">
						<el-tabs
							v-model="activeName"
							@tab-click="tabhandleClick"
							class="integralRecord-tabs"
						>
							<el-tab-pane :label="$t('lang.myCenter.getRecords')" name="first">
								<div class="records-box" v-loading="getRecordsLoading">
									<ul
										class="get-records records-ul"
										v-show="getRecordsDadaArr.length > 0"
										:style="{ overflow: getRecordsLoading ? 'hidden' : '' }"
									>
										<li v-for="(item, index) in getRecordsDadaArr" :key="index">
											<div class="left-text">
												<h3>{{ item.operation }}</h3>
												<p class="date">{{ item.dateline }}</p>
											</div>
											<div class="right-text">
												<span class="blue">+{{ item.extcredits }}</span>
											</div>
										</li>
									</ul>
									<div
										class="no-data-show"
										v-show="getRecordsDadaArr.length == 0 && !getRecordsLoading"
									>
										<img
											src="@/assets/img/no-data.png"
											:alt="$t('lang.myCenter.noData')"
										/>
										<p>{{ $t('lang.myCenter.noData') }}</p>
									</div>
								</div>
								<!-- 获取记录的分页 -->
								<div class="page-item">
									<el-pagination
										background
										layout="prev, pager, next"
										:total="getRecordsPageObj.total"
										:current-page="getRecordsPageObj.current_page"
										:page-size="getRecordsPageObj.pageSize"
										@current-change="getrecordCurrentPage"
										:page-sizes="[10, 20, 30, 50, 100]"
									></el-pagination>
								</div>
							</el-tab-pane>
							<el-tab-pane
								:label="$t('lang.myCenter.usageRecord')"
								name="second"
							>
								<div class="records-box" v-loading="useRecordsLoading">
									<ul
										class="use-records records-ul"
										:style="{ overflow: useRecordsLoading ? 'hidden' : '' }"
										v-show="useRecordsDataArr.length > 0"
									>
										<li v-for="(item, index) in useRecordsDataArr" :key="index">
											<div class="left-text">
												<h3>{{ item.operation }}</h3>
												<p class="date">{{ item.dateline }}</p>
											</div>
											<div class="right-text">
												<span class="red">{{ item.extcredits }}</span>
											</div>
										</li>
									</ul>
									<div
										class="no-data-show"
										v-show="useRecordsDataArr.length == 0 && !useRecordsLoading"
									>
										<img
											src="@/assets/img/no-data.png"
											:alt="$t('lang.myCenter.noData')"
										/>
										<p>{{ $t('lang.myCenter.noData') }}</p>
									</div>
								</div>
								<!-- 使用分页 -->
								<div class="page-item">
									<el-pagination
										background
										layout="prev, pager, next"
										:total="useRecordsPageObj.total"
										:current-page="useRecordsPageObj.current_page"
										:page-size="useRecordsPageObj.pageSize"
										@current-change="useRecordsCurrentChange"
										:page-sizes="[10, 20, 30, 50, 100]"
									></el-pagination>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import md5Header from './../components/Header';
import wechatPayUp from './../components/wechatPayup'; //微信充值
import QrCode from './../components/wechatPayup/QRcode.vue'; //微信二维码
import { mapState } from 'vuex';
import { getrecord, md5PayOrderquery } from '@/api/mycenter';

export default {
	name: 'md5-mycenter',
	components: {
		md5Header,
		wechatPayUp,
		QrCode,
	},
	data() {
		return {
			isInterval: false, //是否在循环中
			CodePaycountDown: 3,
			CodePaytype: 3, // 显示类型
			PaycodedialogVisible: false, // 支付二维码的弹框
			wechatpayupVisible: false, //微信充值
			activeName: 'first',
			user: {},
			record: [],
			addTotal: 0,
			redTotal: 0,
			addCurrent: 1,
			redCurrent: 1,
			size: 10,
			hasLogin: false,
			getRecordsDadaArr: [], //获取记录
			getRecordsLoading: false,
			useRecordsDataArr: [], //使用记录,
			useRecordsLoading: false,
			getRecordsPageObj: {
				total: 0,
				current_page: 1,
				pageSize: 15,
			},
			useRecordsPageObj: {
				total: 0,
				current_page: 1,
				pageSize: 15,
			},
			chatPayInterval: null,
		};
	},
	watch: {
		PaycodedialogVisible(val) {
			console.log(val, '支付二维码');
			if (!val) {
				//弹框关闭，记得关闭---关闭计时器
				clearInterval(this.chatPayInterval);
			}
		},
	},
	computed: {
		...mapState(['userInfo']),
	},
	methods: {
		/**
		 * @description 微信支付点击确定提交
		 * @param {object} _data  点击充值确定的交互回调
		 */
		confirmWechatPay(_data) {
			console.log(_data.outTradeNo, '后端结果');

			if (_data.code_url) {
				//生成二维码
				this.wechatpayupVisible = false; //关闭积分充值弹框
				this.CodePaytype = 1; //显示二维码界面
				this.PaycodedialogVisible = true; //显示二维码界面弹框
				this.$nextTick(() => {
					this.$refs.QRCode.creatQrCode(_data.code_url); //创建二维码图片
				});

				clearInterval(this.chatPayInterval); //清除计时器

				/** 扫描二维码的请求    this.CodePaytype
				 *  扫描二维码,是否完成支付。
				 *
				 * paytype 说明:
				 *
				 *  paytype == 1 充值二维码
				 *  paytype == 2 充值失败
				 *  paytype == 3 充值成功
				 */
				this.chatPayInterval = setInterval(() => {
					//out_trade_no 支付id
					md5PayOrderquery({
						outTradeNo: _data.outTradeNo,
					})
						.then((res) => {
							console.log(res, '支付结果');
							if (res) {
								let isSuccessflag = false; //是否成功支付 flag
								switch (res.status) {
									case '-1': //充值失败
										this.CodePaytype = 2;
										break;
									case '0': // 未支付
										break;
									case '1': //充值成功
										this.CodePaytype = 3;
										this.getrecordCurrentPage();
										this.user.points = res.points;
										this.$store.commit('setUserInfo', this.user);
										isSuccessflag = true;
										break;
								}

								//---------成功支付之后，3秒钟后关闭弹框----------
								if (isSuccessflag && !this.isInterval) {
									this.isInterval = true;
									let count = 3;
									this.CodePaycountDown = count;
									let mytimer = setInterval(() => {
										count--;
										if (count >= 0) {
											this.CodePaycountDown = count;
										} else {
											clearInterval(mytimer); //清除计时器
											this.isInterval = false;
											this.PaycodedialogVisible = false;
											mytimer = null;
										}
									}, 1000);
								} //------------3秒后关闭弹框----------------------
							}
						})
						.catch(() => {});
				}, 3000);
			}

			// this.$refs.QRCode.creatQrCode();
		},
		//md5分享功能
		md5Share() {
			if (this.userInfo) {
				this.copyToClipboard(
					window.location.host + '/register?shareId=' + this.userInfo?.id
				);
			}
		},
		//复制
		/**
		 * @param {string} content
		 */
		copyToClipboard(content) {
			if (window.clipboardData) {
				window.clipboardData.setData('text', content);
			} else {
				(function (content) {
					document.oncopy = function (e) {
						e.clipboardData.setData('text', content);
						e.preventDefault();
						document.oncopy = null;
					};
				})(content);
				document.execCommand('Copy');
			}
			this.$message.success(this.$t('lang.copyDataMsg'));
		},
		tabhandleClick(tab, event) {
			console.log(this.activeName, '断点', tab, event);
		},
		// 获取记录 分页请求
		getrecordCurrentPage(_currentPage) {
			this.HttpGetRecordsDadaArr(_currentPage);
		},
		// 使用记录 分页请求
		useRecordsCurrentChange(_currentPage) {
			this.HttpUseRecordsDataArr(_currentPage);
		},
		// 首次获取记录
		async getAllrecord() {
			this.HttpGetRecordsDadaArr(); // 获取记录
			this.HttpUseRecordsDataArr(); // 使用记录
		},
		//获取积分记录
		async HttpGetRecordsDadaArr(_page = 1) {
			/**
			 * page	是	string	无
			 * type	是	string	类型 1: 增加 2:减少
			 */
			let postObj = {
				page: _page,
				type: 1,
			};
			this.getRecordsLoading = true;
			let res_getRecords = await getrecord(postObj);
			if (res_getRecords) {
				this.getRecordsDadaArr = res_getRecords.data;
				this.getRecordsPageObj.total = res_getRecords.total;
				this.getRecordsPageObj.current_page = res_getRecords.current_page;
				this.getRecordsPageObj.pageSize = res_getRecords.per_page;
			}
			this.getRecordsLoading = false;
		},
		//获取 使用记录
		async HttpUseRecordsDataArr(_page = 1) {
			let UsePostObj = {
				page: _page,
				type: 2,
			};
			this.useRecordsLoading = true;
			let res_useRecords = await getrecord(UsePostObj);
			if (res_useRecords) {
				this.useRecordsDataArr = res_useRecords.data;
				this.useRecordsPageObj.total = res_useRecords.total;
				this.useRecordsPageObj.current_page = res_useRecords.current_page;
				this.useRecordsPageObj.pageSize = res_useRecords.per_page;
			}
			this.useRecordsLoading = false;
		},
		// 复制功能
		copyData(data) {
			// 存储传递过来的数据
			let OrderNumber = data;
			// 创建一个input 元素
			// createElement() 方法通过指定名称创建一个元素
			let newInput = document.createElement('input');
			// 讲存储的数据赋值给input的value值
			newInput.value = OrderNumber;
			// appendChild() 方法向节点添加最后一个子节点。
			document.body.appendChild(newInput);
			// 选中input元素中的文本
			// select() 方法用于选择该元素中的文本。
			newInput.select();
			// 执行浏览器复制命令
			//  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
			document.execCommand('Copy');
			// 清空输入框
			newInput.remove();
			// 下面是element的弹窗 不需要的自行删除就好
			this.$message({
				message: this.$t('lang.copy_succeed'),
				type: 'success',
			});
		},
		logout() {},
	},
	mounted() {},
	async created() {
		// 更新个人中心数据
		await this.updateUserInfo();
		if (this.userInfo) {
			this.user = this.userInfo;
		}
		this.getAllrecord(); //请求获取 充值积分 和 使用积分
	},
};
</script>

<style lang="scss">
.md5-mycenter {
	.banner-bg {
		background: rgba(229, 240, 253, 1) url(../assets/img/range-banner.png);
		background-repeat: no-repeat;
		background-position: 80% 100%;
		background-size: contain;
		width: 100%;
		height: 358px;
	}

	.mycenter-page {
		padding-top: 113px;

		.title {
			font-size: 34px;
			font-weight: 700;
			color: #333333;
		}
	}

	.white-container {
		background: #ffffff;

		.mycenter-content {
			position: relative;

			.appkey {
				background: #ffffff;
				box-shadow: 0px 0px 6px 0px rgba(68, 169, 254, 0.15);
				border-radius: 6px;
				position: relative;
				top: -30px;
				padding: 27px 0 25px 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				font-size: 16px;
				font-weight: bold;
				color: #333333;

				.blue {
					color: rgba(0, 186, 173, 1);
				}

				.copy-btn {
					margin-right: 20px;
					// width: 80px;
					background: #ffffff;
					border: 1px solid #44a9fe;
					color: #44a9fe;
					border-radius: 6px;
					padding-bottom: 8px;
					padding-top: 8px;

					&:hover {
						color: #409eff;
						border-color: #c6e2ff;
						background-color: #ecf5ff;
					}
				}
			}

			// 我的积分
			.my-integral {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.left-item {
					width: 341px;
					height: 170px;
					background: #ffffff;
					box-shadow: 0px 0px 6px 0px rgba(68, 169, 254, 0.15);
					border-radius: 6px;
					position: relative;

					.total-content {
						padding: 27px 0 22px 22px;
						display: flex;

						.tip-text {
							h2 {
								font-size: 14px;
								font-weight: 400;
								color: #333333;
								margin-bottom: 16px;
							}

							.my-total {
								font-size: 30px;
								font-weight: bold;
								color: rgba(250, 167, 85, 1);
								margin-bottom: 40px;
							}

							.warning-text {
								width: 141px;
								height: 13px;
								font-size: 12px;
								font-weight: 400;
								color: #989898;
							}
						}

						.icon {
							margin-right: 22px;

							i {
								color: #45a9fd;
								font-size: 35px;
							}
						}
					}
				}

				.right-item {
					position: relative;
					width: 849px;

					.integral-item {
						height: 80px;
						background: #ffffff;
						box-shadow: 0px 0px 6px 0px rgba(68, 169, 254, 0.15);
						border-radius: 6px;
						padding: 24px 21px 22px 20px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.left-textconent {
							display: flex;

							.icon {
								margin-right: 22px;

								i {
									color: #45a9fd;
									font-size: 35px;
								}
							}

							h2 {
								font-size: 14px;
								font-weight: 400;
								color: #333333;
								margin-bottom: 5px;
							}

							.explain {
								font-size: 12px;
								font-weight: 400;
								color: #989898;
							}
						}

						.copy-btn {
							margin-left: 25px;
							// width: 80px;
							background: #ffffff;
							border: 1px solid #44a9fe;
							color: #44a9fe;
							border-radius: 6px;
							padding-bottom: 8px;
							padding-top: 8px;

							&:hover {
								color: #409eff;
								border-color: #c6e2ff;
								background-color: #ecf5ff;
							}
						}
					}
				}
			}

			// 积分记录
			.integral-record {
				margin-top: 30px;

				h2 {
					font-size: 18px;
					font-weight: bold;
					color: #333333;
					margin-bottom: 20px;
				}

				.list-content {
					background: #ffffff;
					box-shadow: 0px 0px 6px 0px rgba(68, 169, 254, 0.15);
					border-radius: 6px;
					padding: 19px 23px;

					.records-box {
						position: relative;
						height: 705px;
						overflow-y: auto;

						// 滚动条样式
						&::-webkit-scrollbar {
							/*高宽分别对应横竖滚动条的尺寸*/
							width: 5px;
						}

						&::-webkit-scrollbar-thumb {
							/*滚动条里面小方块*/
							background: #dddddd;
							border-radius: 2px;
						}

						.records-ul {
							height: 705px;
						}

						//无数据显示图片
						.no-data-show {
							width: 370px;
							height: 220px;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);

							img {
								width: 100%;
								height: 200px;
								object-fit: cover;
							}
							p {
								text-align: center;
								font-weight: 500;
								color: rgba(128, 128, 128, 1);
							}
						}

						.get-records,
						.use-records {
							li {
								padding-top: 20px;
								padding-bottom: 10px;
								height: 70px;
								border-bottom: 1px solid #dddddd;
								display: flex;
								justify-content: space-between;
								padding-right: 20px;

								.left-text {
									h3 {
										font-size: 14px;
										font-weight: 400;
										color: #333333;
										margin-bottom: 6px;
									}

									p.date {
										font-size: 12px;
										font-weight: 400;
										color: #989898;
									}
								}

								.right-text {
									span {
										font-size: 16px;
										font-weight: bold;
									}
									.blue {
										font-size: 16px;
										font-weight: 700;
										color: rgba(0, 186, 173, 1);
									}
									.red {
										color: #ff0000;
									}
								}
							}
						}
					}

					.page-item {
						text-align: center;
						margin: 25px 0;
					}
				}
			}
		}
	}

	// 选项卡的样式
	.integralRecord-tabs {
		.el-tabs__item.is-active {
			font-weight: bold;
			color: #66b9ff;
		}
	}
}
</style>
