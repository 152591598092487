<!--登录页-->
<template>
	<div class="cmd5-login">
		<!--登录页内容区-->
		<div class="login-content">
			<div class="desc-content">
				<div class="logo-img">
					<img
						src="../assets/img/logo.png"
						alt
						style="width: 322px; height: 57px"
					/>
				</div>
			</div>
			<div class="back-box">
				<el-link
					@click="$router.push('/')"
					class="right-backindex"
					:underline="false"
				>
					<img src="../assets/img/login-icon/jiantou.png" alt="" />
					{{ $t('lang.login.gobackBack') }}</el-link
				>
				<div class="login-tiptext">
					<div class="left-welcome">{{ $t('lang.login.title') }}</div>
				</div>
				<!--表单区域-->
				<div class="login-form">
					<!--账号-->
					<div
						:class="[
							'form-item',
							errTipObj.username.status ? 'err-input-border' : '',
						]"
					>
						<div class="left-icon">
							<img
								src="../assets/img/login-icon/zhanghao.png"
								:alt="$t('lang.forgotPwd.email')"
							/>
							<span>{{ $t('lang.login.email') }}</span>
						</div>
						<div class="right-input">
							<el-input
								:placeholder="$t('lang.login.emailPlaceholder')"
								v-model.trim="postObj.username"
								@keyup.enter="GoLogin"
								@focus="
									() => {
										errTipObj.username.status = false;
									}
								"
							></el-input>
						</div>
						<div class="err-tip" v-show="errTipObj.username.status">
							{{ errTipObj.username.errTiptext }}
						</div>
					</div>
					<!--密码-->
					<div
						:class="[
							'form-item',
							errTipObj.password.status ? 'err-input-border' : '',
						]"
					>
						<div class="left-icon">
							<img
								src="../assets/img/login-icon/mima1.png"
								:alt="$t('lang.forgotPwd.password')"
							/>
							<span>{{ $t('lang.login.password') }}</span>
						</div>
						<div class="right-input">
							<el-input
								type="password"
								:placeholder="$t('lang.login.pwdPlaceholder')"
								v-model.trim="postObj.password"
								@keyup.enter="GoLogin"
								show-password
								@focus="
									() => {
										errTipObj.password.status = false;
									}
								"
							></el-input>
						</div>
						<div class="err-tip" v-show="errTipObj.password.status">
							{{ errTipObj.password.errTiptext }}
						</div>
					</div>
					<!--验证码-->
					<div class="verifycode-item">
						<div class="code-input">
							<div class="left-icon">
								<img
									src="../assets/img/login-icon/yanzhengma.png"
									:alt="$t('lang.forgotPwd.captcha')"
								/>
								<span>{{ $t('lang.login.captcha') }}</span>
							</div>
							<div class="right-input">
								<el-input
									:placeholder="$t('lang.login.captchaPlaceholder')"
									v-model.trim="postObj.verifyCode"
									@keyup.enter="GoLogin"
								></el-input>
							</div>
						</div>
						<div class="code-img">
							<img
								ref="captcha"
								:src="src"
								:alt="$t('lang.login.captcha')"
								@click="srcRandom = Math.random()"
							/>
						</div>
					</div>
					<!--是否同意协议 -->
					<!--是否同意协议 -->
					<div class="isTerms-service">
						<el-checkbox v-model="isConsentProtocol">{{
							$t('lang.login.consentProtocol')
						}}</el-checkbox>
						<el-link class="protocol-link" @click="openDialog(1)"
							>《{{ $t('lang.login.protocol') }}》</el-link
						>{{ $t('lang.login.and') }}

						<el-link class="protocol-link" @click="openDialog(2)"
							>《{{ $t('lang.privacyPolicy.privacyPolicy') }}》</el-link
						>
					</div>

					<!--登录按钮-->
					<div class="login-btn">
						<el-button
							type="primary"
							class="cmd5-loginbtn"
							:loading="loginLoading"
							@click="GoLogin"
							>{{ $t('lang.login.login') }}</el-button
						>
					</div>
					<!--提示去注册-->
					<div class="tip-register">
						<span class="to-register">
							<span> {{ $t('lang.login.register') }}</span>
							<el-link
								@click="$router.push('/register')"
								class="register-link"
								:underline="false"
							>
								{{ $t('lang.login.goregister') }}</el-link
							></span
						>
						<el-link
							@click="$router.push('/forgotpwd')"
							class="register-link"
							:underline="false"
							>{{ $t('lang.login.goForgotpwd') }}</el-link
						>
					</div>
					<!-- 协议弹框 dialog -->
					<div class="agreement-dialog">
						<el-dialog
							:visible="agreementDialogVisible"
							append-to-body
							class="login-inner-dialog"
							@close="confirmAgreement"
						>
							<div slot="title" class="title action-title">
								<span>{{ agreementData.title }}</span>
							</div>
							<!-- 主体部分 -->
							<div>
								<div
									style="line-height: 24px; word-break: break-all"
									v-html="agreementData.content"
								></div>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-button
									class="confirm-btn"
									@click="confirmAgreement"
									type="primary"
									>{{ $t('lang.agreementData.confirm') }}</el-button
								>
							</div>
						</el-dialog>
					</div>
				</div>
			</div>
		</div>
		<!-- 页脚组件 -->
		<Footer />
	</div>
</template>

<script>
import Footer from '../components/Footer';
import { login } from './../api/login';
import { mycenter } from '@/api/header';

const Base64 = require('js-base64').Base64;
export default {
	name: 'cmd5-login',
	layout: 'page',
	components: { Footer },
	computed: {
		src() {
			return `/api/v1/captcha?type=login&'${this.srcRandom}`;
		},
	},
	data() {
		return {
			agreementVisible: '', // 协议弹框
			agreementDialogVisible: false,
			agreementData: {},
			srcRandom: Math.random(),
			isConsentProtocol: false, //是否同意协议
			loginLoading: false,
			errTipStatus: false,
			postObj: {
				username: '',
				password: '',
				verifyCode: '', //验证码
				rememberPWD: false,
			},
			// 错误状态的提示
			errTipObj: {
				username: {
					status: false,
					errTiptext: '',
				},
				password: {
					status: false,
					errTiptext: '',
				},
			},
		};
	},
	methods: {
		// 用户协议的弹窗
		openDialog(_id) {
			this.agreementVisible = _id;
			if (_id == 1) {
				this.agreementDialogVisible = true;
				this.agreementData.title = this.$t('lang.login.protocol');
				this.agreementData.content = this.$t('lang.agreementData.content');
			} else if (_id == 2) {
				this.agreementDialogVisible = true;
				this.agreementData.title = this.$t('lang.privacyPolicy.title');
				this.agreementData.content = this.$t('lang.privacyPolicy.content');
			}
		},
		//确定
		confirmAgreement() {
			this.agreementDialogVisible = false;
		},
		//点击登录
		async GoLogin() {
			const IsSubmit = this.checkForm();
			if (!IsSubmit) {
				//条件不成立不能提交
				return;
			}
			this.loginLoading = true;
			const emailreg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
			if (!emailreg.test(this.postObj.username)) {
				this.errTipObj.username.status = true;
				this.$message.error(this.$t('lang.forgotPwd.emailPlaceholder'));
				return false;
			}
			try {
				const res = await login(this.postObj);

				//如果报错就退出了。
				if (res.code == 400) {
					this.$refs.captcha.click(); //更换验证码
					this.loginLoading = false;
					return;
				}

				// 登录成功!
				if (res) {
					// Vuex保存 token
					this.$store.commit('setToken', res.token);
					//登录成功 请求获取一下用户的信息,赋值到页头显示登录
					const userInfo = await mycenter();
					this.loginLoading = false;
					if (userInfo) {
						this.userInfoObj = userInfo;
						this.userInfoObj.photo = require('../assets/img/user.png');

						this.$store.commit('setUserInfo', userInfo);

						this.$message({
							message: this.$t('lang.login.message'),
							type: 'success',
							duration: 1200,
							onClose: () => {
								//this.md5hasLogin = true; //成功登录了
								this.$router.push({
									path: `/`,
								});
							},
						});
					}
				}
			} catch (err) {
				console.log(err, '报错了');
				this.loginLoading = false;
			}
			this.$refs.captcha.click(); //更换验证码

			/*login(this.postObj).then(async res => {
        if (res) {

        }
      }).catch((err) => {
        console.log(err, '报错了');
        this.loginLoading = false;
      })
      

      /*login(this.postObj).then(async res => {
        console.log(res)
        if (res) {
          const userInfoData = await mycenter();
          console.log(userInfoData);
        }
        this.$refs.captcha.click(); //更换验证码
      }).catch(err => {
        console.log(err, '报错了');
        this.loginLoading = false;
        this.$refs.captcha.click(); //更换验证码
      })
      */
			//const res = await login(this.postObj);

			//this.$refs.captcha.click(); //更换验证码
		},
		// 储存表单信息
		setUserInfo: function () {
			// 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
			// 如果没有勾选，储存的信息为空
			if (this.postObj.rememberPWD) {
				this.setCookie('username', this.postObj.username);
				// base64加密密码
				let passWord = Base64.encode(this.postObj.password);
				this.setCookie('password', passWord);
			} else {
				this.setCookie('username', '');
				this.setCookie('password', '');
			}
		},
		// 获取cookie
		getCookie: function (key) {
			if (document.cookie.length > 0) {
				var start = document.cookie.indexOf(key + '=');
				if (start !== -1) {
					start = start + key.length + 1;
					var end = document.cookie.indexOf(';', start);
					if (end === -1) end = document.cookie.length;
					return unescape(document.cookie.substring(start, end));
				}
			}
			return '';
		},
		// 保存cookie
		setCookie: function (cName, value, expiredays) {
			var exdate = new Date();
			exdate.setDate(exdate.getDate() + expiredays);
			document.cookie =
				cName +
				'=' +
				decodeURIComponent(value) +
				(expiredays == null ? '' : ';expires=' + exdate.toGMTString());
		},

		// 检查提交数据，限制提交。
		checkForm() {
			if (!this.postObj.username) {
				this.errTipObj.username.status = true;
				this.errTipObj.username.errTiptext = this.$t('lang.login.errTiptext1');
				return false;
			}
			if (!this.postObj.password) {
				//this.$message.error('请输入密码!');
				this.errTipObj.password.status = true;
				this.errTipObj.password.errTiptext = this.$t(
					'lang.login.pwdPlaceholder'
				);
				return false;
			}

			if (!this.postObj.verifyCode) {
				this.$notify.error({
					title: this.$t('lang.prompt'),
					message: this.$t('lang.login.errTiptext3'),
				});
				return false;
			}
			if (!this.isConsentProtocol) {
				this.$notify.error({
					title: this.$t('lang.prompt'),
					message: this.$t('lang.login.errTiptext4'),
				});
				return false;
			}
			return true;
		},
	},
	created() {
		// 在页面加载时从cookie获取登录信息
		let username = this.getCookie('username');
		let password = Base64.decode(this.getCookie('password'));
		// 如果存在赋值给表单，并且将记住密码勾选
		if (username) {
			this.postObj.username = username;
			this.postObj.password = password;
			this.postObj.rememberPWD = true;
		}
	},
};
</script>

<style lang="scss">
.cmd5-login {
	background-image: url(../assets/img/cmd-index-bg.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-attachment: fixed;
	position: absolute;
	height: 100vh;
	width: 100%;
	.err-tip {
		font-size: 12px;
		font-weight: 400;
		color: #ff0000;
		position: absolute;
		left: 0;
		top: 55px;
		transform: translateY(-50%);
		width: 100%;
	}
	.err-input-border {
		border: 1px solid #ff0000;
	}
	// 是否同意协议
	.isTerms-service {
		width: 360px;
		margin: 0 auto 40px;
		display: flex;
		align-items: center;
		.el-checkbox__label {
			font-size: 12px;
		}
		.protocol-link {
			font-size: 12px;
			color: #44a9fe;
		}
	}
	.back-box {
		background: #ffffff;
		padding-bottom: 44px;
		border-radius: 6px;
	}
	.login-content {
		width: 450px;
		margin: 0 auto;
		padding-top: 128px;
		min-height: calc(100vh - 105px);
		.logo-img {
			text-align: center;
			margin-bottom: 47px;
			a {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.title {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.right-backindex {
			color: #44a9fe;

			position: relative;
			top: 27px;
			left: 331px;
			.el-link--inner {
				display: flex;
				align-items: center;
				img {
					width: 18px;
					height: 12px;
					object-fit: cover;
					margin-right: 4px;
				}
			}
		}
		.login-tiptext {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 0;
			margin-bottom: 40px;
			border-bottom: 2px solid #eee;
			height: 45px;

			.left-welcome {
				font-size: 24px;
				font-weight: bold;
				color: #333333;
				width: 100%;
				text-align: center;
				margin-bottom: 12px;
			}
		}

		.login-form {
			margin-top: 50px;
			.form-item {
				width: 360px;
				height: 46px;
				background: #ffffff;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: 6px;
				margin: 0 auto 25px;
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.left-icon {
					width: 144px;
					height: 100%;
					display: flex;
					// justify-content: space-between;
					align-items: center;
					padding: 0px 0 0 16px;

					span {
						font-size: 14px;
						font-weight: bold;
						color: #333333;
						line-height: 46px;
						display: inline-block;
						margin-left: 10px;
					}
					img {
						width: 18px;
						height: auto;
						object-fit: cover;
					}
				}
				.right-input {
					width: calc(100% - 84px);
					input {
						border: 0;
						outline: 0;
					}
					input:focus {
						box-shadow: none;
					}
				}
			}
		}
		.verifycode-item {
			margin: 0 auto 25px;
			width: 360px;
			height: 46px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			.code-input {
				width: 238px;
				height: 46px;
				background: #ffffff;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: 6px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.left-icon {
					width: 144px;
					height: 100%;
					display: flex;
					// justify-content: space-between;
					align-items: center;
					padding: 0px 0 0 16px;

					span {
						font-size: 14px;
						font-weight: bold;
						color: #333333;
						line-height: 46px;
						display: inline-block;
						margin-left: 10px;
					}
					img {
						width: 18px;
						height: auto;
						object-fit: cover;
					}
				}
				input {
					border: 0;
					outline: 0;
					padding: 0.375rem 0.75rem 0.375rem 0;
				}
				input:focus {
					box-shadow: none;
				}
			}
			.code-img {
				width: 113px;
				height: 46px;
				background: #ffffff;
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
				border-radius: 6px;
				overflow: hidden;
				padding: 5px;
				img {
					width: 103px;
					height: 35px;
					cursor: pointer;
				}
			}
		}
		.login-btn {
			width: 360px;
			height: 46px;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
			border-radius: 6px;
			margin: auto;
			.cmd5-loginbtn {
				height: 100%;
				font-size: 18px;
				width: 100%;
				border-radius: 6px;
			}
		}
		.tip-register {
			margin: 20px auto 20px;
			width: 360px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.register-link {
				font-size: 14px;
				font-weight: 400;
				color: #333333;
			}
			.register-link:hover {
				color: #44a9fe;
				text-decoration: none;
			}
			.to-register {
				display: flex;
			}
			.checkbox {
				-moz-user-select: none; /* Firefox私有属性 */
				-webkit-user-select: none; /* WebKit内核私有属性 */
				-ms-user-select: none; /* IE私有属性(IE10及以后) */
				-khtml-user-select: none; /* KHTML内核私有属性 */
				-o-user-select: none; /* Opera私有属性 */
				user-select: none; /* CSS3属性 */
				cursor: pointer;
			}
		}
	}
}
</style>
