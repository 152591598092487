import request from '@/utils/request'

export default function fetchMd5(query) {
  //export default function fetchMd5(query,header) {
  return request({
    url: '/single_decryption',
    method: 'post',
    data: query,
    //headers:header
  })
}
export function massQuery(query) {
  return request({
    url: '/batch_decryption',
    method: 'post',
    data: query,
    //headers:header
  })
}
//获取批量结果
export function md5DecryptionResult(query) {
  return request({
    url: '/decryption_result',
    method: 'GET',
    params: query,
    //headers:header
  })

}
// IP地址反查
export function ipQuery(query) {
  return request({
    url: '/online_tools/ip_query',
    method: 'GET',
    params: query,
  })
}

//明文上传
export function uploadCleartext(query) {
  return request({
    url: '/upload_cleartext',
    method: 'POST',
    data: query,
  })
}




