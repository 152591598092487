/**
 * @description 俄语切换 语言
 * @date 2022-12-09 星期五 阴
 */
export const lang = {
    footerInfo: {
      warnText: `Все функции и данные этой станции могут использоваться только для криптографических исследований и оценки информационной безопасности, данные получены случайным образом и строго запрещены для любого незаконного использования, если это является нарушением данного пункта и не несет никакой ответственности!`,
      contactUs:`Свяжись с нами.`,
      rightsAll:`Авторское право`,
      aq:` гуип - 19009338 - 6`
    },
    homePage:{
      selectBox:`Выберите`,
      query:`запрос`,
      placeholder:`Введите текст, который необходимо расшифровать`,
      result:`результат расшифровки`,
      decrypt:`расшифрова`,
      captcha:`Код подтверждения.`,
      captchaPlaceholder:`Введите код веридан`,
      // 搜索结果
      encryptionType:`Тип шифрования.`,
      encryptionLabel:`Оригинальные строки`,
      copy:`скопирова`,
      copyClick:`щелчк скопирова`,
      originalString:`Оригинальные строки`,
      lowerCase16:`16-битное написание`,
      upperCase16:`16 с большой буквы`,
      lowerCase32:`32 - битная строчка`,
      upperCase32:`С большой буквы 32`,
      login:`высад`,
      decryptionFailed:`Расшифровка завершена.`,
      // 公共栏区域
      noticeText:`объявлен`,
      noticeContent:`Станция поддерживает несколько онлайн-алгоритмов расшифровки, быстрых, точных, стабильных и бесплатных; База данных постоянно обновляется. Другие вопросы приветствуют консультацию: официальная группа обмена QQ 1:857548361, официальная группа QQ: 2:280409281`,
      joinQQAlt:`Нажмите, чтобы присоединиться к группе QQ`,
      joinQQText:`QQ`,
      joinWeChatAlt:`Нажмите, чтобы присоединиться к группе микроверующих`,
      joinWeChatText:`wechat`,
    },
    // 在线工具tool
    tool:{
      moreTools:`Еще инструменты.`
    },
    // 在线批量解密
    decryption:{
      selectEncryptionType:`Выбрать тип шифрования`,
      currentTypeVal:`Нажмите на дешифратор`,
      captcha:`Код подтверждения.`,
      captchaPlaceholder:`Введите код веридан`,
      submitDecryption:`представление`,
      tiptextBox:`Примечание: требуется 10 кредитов за массовое дешифрование каждого фрагмента。`,
      sourcetextHead:`Оригинальный текст (не более 200 строк)`,
      decryptionResult:`Расшифровка.`,
      copy:`скопирова`,
      noData:`Множество доказательств.`
    },
    // 明文提交页面
    plaintextUpload:{
      plaintextUpload:`Подать на рассмотрение`,
      placeholder:`Введите, пожалуйста, код`,
      plaintextSubmit:`представление`,
      warningText:`Введите известные вам пароли (например, admin, 123456,147258869 и т. Пожалуйста, не повторяйте.`,
      note:`Примечание: пользователи успешно представили его, что может увеличить очки`,
    },
    // 批量解密 API
    api:{
      api:`API интерфейс`,
      callURL:`URL вызова`,
      wrapperContent1:`Поддерживается до 200 зашифрованных текстов с запятой`,
      wrapperContent2:`Если код изменен, параметр key изменится`,
      wrapperContent3:`Способ просьбы:POST`,
      wrapperContent4:`После успешного дешифрования, результаты дешифровки отправляются в соответствующий URL, который посылает запрос в три раза, с просьбой каждые пять секунд, и если ответ "ок" больше не запрашивается, то после трех запросов не уведомляется`,
    
      tipTitle:`Код ошибки.`,
      errorCodeTitle:`Для расшифровки результатов используется таб. В других случаях строка "ERROR-CODE:"+ цифровой код. Перечислять следующим образом`,
      
      errorCode1:`Расшифровка завершена.Ошибка расшифровки`, 
      errorCode2:`Нет, ки.`,
      errorCode3:`Недостаточный интеграл.`,   
      errorCode4:`Их более 200`, 
      errorCode5:`Сбой сервера дешифровки`,
      errorCode6:`Другие ошибки.`,
      important:`Важное напоминание`,
      wrapperContent5:`Этот интерфейс находится под пристальным наблюдением и не может использоваться в таких случаях, как вторичные продажи, проверки на веб-сайтах, публичные запросы, например, когда существует фальшивый сайт, на самом деле за кулисами, которые используют этот интерфейс, чтобы просочиться на этот сайт и т.п., когда обнаруживается, что немедленный код не возмещается.`,
      wrapperContent6:`В каждом запросе этого интерфейса вычитается интеграл, независимо от успеха дешифровки`,
      wrapperContent7:`В случае повторения каждого запроса в этом интерфейсе будет повторение с вычетом очков, и обратите внимание на проверку повторного кода`,
      wrapperContent8:`Бен интерфейс запрос нескольк зашифрова использ по вид "автоматическ" запрос, но запрос Md5, Sha1, Sha256, Sha512, mysql, mysql5 подожда тип`,
    }
  };