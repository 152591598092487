<!--
    自定义table组件
    用于解密范围页面
-->
<template>
  <div class="range-table-component">
    <table class="cmd5-table">
      <thead>
        <tr>
          <th
            :style="{width:'calc(100% /'+columns.length+')'}"
            v-for="(item,index) in columns"
            :key="index"
          >{{item.label}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in data" :key="index">
          <td
            v-for="(childItem,cindex) in item"
            :key="index+'-'+'childItem'+'-'+cindex"
          >{{childItem}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "range-table",
  props: {
    columns: {
      type: Array
    },
    data: {
      type: Array
    }
  }

}
</script>

<style lang="scss" scoped>
.cmd5-table {
  width: 100%;
  border: 1px solid #dddddd;
  word-wrap: break-word;
  thead {
    background: #eeeeee;
    height: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    border-bottom: 1px solid #dddddd;
    th {
      text-align: center;
      //width: 33%;
      height: 43px;
    }
  }
  tbody {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    tr {
      border-bottom: 1px solid #ebeef5;
    }
    td {
      line-height: 42px;
      border-right: 1px solid #dddddd;
      padding-left: 10px;
      word-break: break-all; /*字母数字换行*/
    }
  }
}
</style>