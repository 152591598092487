<template>
  <!-- class 切换判断 是否要定位，如果页面 routeActive == 1 在解密范围就不用定位。-->
  <footer :class="[ routeActive !== 0 ?  'not-position-cmd-footer'  : 'cmd-footer']">
    <p>{{ $t('lang.footerInfo.warnText') }}</p>
    <p>{{ $t('lang.footerInfo.contactUs') }}:support@md5.com</p>
    <p>
     
      Copyright &copy; md5.cn {{$t('lang.footerInfo.rightsAll')}}
      <a
        href="https://beian.miit.gov.cn"
        target="_blank"
        rel="noopener noreferrer"
        class="icp-num">
        {{$t('lang.footerInfo.aq')}}</a>
    </p>
    <!-- 返回顶部 -->
    <el-backtop :bottom="100" :right="120">
      <div class="iconfont icon-fanhuidingbu" style="font-size:35px"></div>
    </el-backtop>
  </footer>
</template>

<script>
export default {
  name: "cmd-footer",
  props: ['isPosition'],
  data() {
    return {
      routeActive: 0,
    }
  },
  watch: {
    //监听路由的跳转
    $route: {
      handler: function (val) {
        let path = val.path;
        this.SetrouteActive(path);
      }
    }
  },
  mounted() {
    this.SetrouteActive(this.$route.path);
  },
  methods: {
    /**
     * @description 切换路由的方法。
     * @param {string} _path 路由路径
     */
    SetrouteActive(_path) {
      switch (_path) {
        case '/':
          this.routeActive = 3;//首页
          break;
        case '/desc': //解密范围
          this.routeActive = 1;
          break;
        case '/decryption/batch': //批量解密
          this.routeActive = 2;
          break;
        case '/decryption/api': //API接口提示
          this.routeActive = 3;
          break;
        case '/myCenter':   //个人中心
          this.routeActive = 4;
          break;
        default:
          this.routeActive = 5;
      }

    }
  }
}
</script>

<style lang="scss">
.cmd-footer {
  position: absolute;
  bottom: 3%;
  text-align: center;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding: 20px 0;
}
/**不需要定位的情况 样式**/
.not-position-cmd-footer {
  text-align: center;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding: 20px 0;
  @media screen and (max-width: 550px) {
    display: none;
  }
}

.icp-num {
  color: #666666;
  transition: all 0.3s;
  &:hover {
    color: #409eff;
  }
}
</style>
